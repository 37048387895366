import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { AppLink, routeNaming } from 'routes';
import { constants } from 'config/constants';
import { Timeframe, TIMEFRAME_TYPES } from 'models/timeframe';

import { SegmentedControl } from 'common/segmentedControl';
import { TimeframePicker } from 'common/timeframePicker';

import { ReactComponent as EditIcon } from 'assets/images/icons-edit.svg';

import { FacemaskCard } from './facemaskCard';
import { SocialDistancingCard } from './socialDistancingCard';
import { OccupancyCard } from './occupancyCard';
import { CameraView } from './cameraViewCard';
import { CameraCount } from './cameraCountCard';
import { CameraList } from './cameraListCard';

import styles from './metrics.module.scss';
import { InOutCard } from './inOutCard/inOutCard';

const { METRICS } = constants;

const Metrics = ({
  processorMetrics, showAreas, ids, areas, cameras,
}) => {
  const [timeframes, setTimeframes] = useState({
    selected: TIMEFRAME_TYPES.HOURLY,
    [TIMEFRAME_TYPES.HOURLY]: new Timeframe({ type: TIMEFRAME_TYPES.HOURLY }),
    [TIMEFRAME_TYPES.DAILY]: new Timeframe({ type: TIMEFRAME_TYPES.DAILY }),
    [TIMEFRAME_TYPES.WEEKLY]: new Timeframe({ type: TIMEFRAME_TYPES.WEEKLY }),
  });
  const [showHeader, setShowHeader] = useState(false);
  const timeframe = timeframes[timeframes.selected];
  const setSingleTimeframe = (newTimeframe) => {
    const newTimeframes = { ...timeframes };
    newTimeframes[newTimeframe.type] = newTimeframe;
    setTimeframes(newTimeframes);
  };

  useEffect(() => {
    const savedTimeframes = window.localStorage.getItem('timeframes');
    if (savedTimeframes) setTimeframes(JSON.parse(savedTimeframes));
  }, []);

  // Return done to save before unmount
  useEffect(() => () => {
    window.localStorage.setItem('timeframes', JSON.stringify(timeframes));
  }, [timeframes]);

  const segmentedControl = (
    <div className={styles.timeSegmentedControlContainer}>
      <SegmentedControl
        elements={[
          {
            title: 'Hourly',
            onClick: () => setTimeframes({ ...timeframes, selected: TIMEFRAME_TYPES.HOURLY }),
            selected: timeframes.selected === TIMEFRAME_TYPES.HOURLY,
          },
          {
            title: 'Daily',
            onClick: () => setTimeframes({ ...timeframes, selected: TIMEFRAME_TYPES.DAILY }),
            selected: timeframes.selected === TIMEFRAME_TYPES.DAILY,
          },
          {
            title: 'Weekly',
            onClick: () => setTimeframes({ ...timeframes, selected: TIMEFRAME_TYPES.WEEKLY }),
            selected: timeframes.selected === TIMEFRAME_TYPES.WEEKLY,
          },
        ]}
        className={styles.segmentedControl}
      />
    </div>
  );

  const timePicker = (
    <div className={styles.timePicker}>
      {segmentedControl}
      <TimeframePicker timeframe={timeframe} setTimeframe={setSingleTimeframe} />
    </div>
  );

  useEffect(() => setShowHeader(ids.length === 1), [ids]);

  const area = showAreas ? areas.find((a) => a.id === ids[0]) : null;
  const camera = !showAreas ? cameras.find((c) => c.id === ids[0]) : null;
  const headerTitle = showAreas ? area.areaName : camera.cameraName;
  const header = (
    <div className={styles.header}>
      <div className={styles.title}>
        {headerTitle}
        <AppLink
          className={styles.editButton}
          routeName={showAreas ? routeNaming.EDIT_AREA : routeNaming.EDIT_CAMERA}
          pathParams={{ id: ids[0] }}
        >
          <EditIcon height="33px" width="33px" />
        </AppLink>
      </div>
      {
        !showAreas
        && (
          <div className={styles.indicators}>
            <div positive={camera.calibrated ? '' : null} important="">
              {camera.calibrated ? 'Calibrated' : 'Uncalibrated'}
            </div>
            <div positive={camera.hasAreaOfInterest ? '' : null}>
              Area&nbsp;of&nbsp;interest
            </div>
            <div positive={camera.hasInOutBoundary ? '' : null}>
              In/Out&nbsp;boundary
            </div>
          </div>
        )
      }
    </div>
  );
  if (ids.length < 1) {
    return (
      <div className={styles.content}>
        {timePicker}
        <span className={styles.noIds}>
          Select a
          {showAreas ? 'n area ' : ' camera '}
          to show metrics.
        </span>
      </div>
    );
  }

  const selectedAreas = areas.filter((a) => ids.some((id) => id === a.id));
  const areasCamerasIds = selectedAreas.map((a) => a.cameras).join(',').split(',');
  const camerasFromSelectedAreas = cameras.filter((c) => areasCamerasIds.some((id) => id === c.id));

  const areaCards = (
    <>
      <div className={styles.areaCardsContainer}>
        <CameraView camerasFromSelectedAreas={camerasFromSelectedAreas} />
        <CameraCount ids={ids} camerasFromSelectedAreas={camerasFromSelectedAreas} />
        <CameraList camerasFromSelectedAreas={camerasFromSelectedAreas} />
      </div>
    </>
  );

  const occupancyCard = processorMetrics[METRICS.OCCUPANCY] && showAreas
    ? <OccupancyCard timeframe={timeframe} ids={ids} />
    : null;
  const inOutCard = processorMetrics[METRICS.IN_OUT]
    ? <InOutCard timeframe={timeframe} showAreas={showAreas} ids={ids} />
    : null;
  const facemaskCard = processorMetrics[METRICS.FACEMASK]
    ? <FacemaskCard timeframe={timeframe} showAreas={showAreas} ids={ids} />
    : null;
  const socialDistancingCard = processorMetrics[METRICS.SOCIAL_DST]
    ? <SocialDistancingCard timeframe={timeframe} showAreas={showAreas} ids={ids} />
    : null;

  return (
    <div className={styles.content}>
      {showHeader ? header : null}
      {showAreas ? areaCards : null}
      {timePicker}
      {occupancyCard}
      {inOutCard}
      {facemaskCard}
      {socialDistancingCard}
    </div>
  );
};

Metrics.propTypes = {
  showAreas: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  areas: PropTypes.array.isRequired,
  cameras: PropTypes.array.isRequired,
  processorMetrics: PropTypes.shape({
    [METRICS.OCCUPANCY]: PropTypes.bool.isRequired,
    [METRICS.IN_OUT]: PropTypes.bool.isRequired,
    [METRICS.FACEMASK]: PropTypes.bool.isRequired,
    [METRICS.SOCIAL_DST]: PropTypes.bool.isRequired,
  }).isRequired,
};

export { Metrics };
