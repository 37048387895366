import React from 'react';
import { PropTypes } from 'prop-types';

import { SettingsController } from 'networking/controllers/settings-controller';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { CameraController } from 'networking/controllers/camera-controller';
import { AreaController } from 'networking/controllers/area-controller';
import { goToPage, routeNaming } from 'routes';
import { errorMessage, ERRORS } from 'helpers/error-helper';
import { loadProcessorToken } from 'helpers/cookie-helper';
import { MODAL_ERRORS, ModalErrors } from 'common/modalErrors';

import { Loader } from 'common/loader';

import { LOADING_CONFIG, LOADING_MESSAGES } from './constants';

import styles from './with-config.module.scss';

class ConfigProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      encryptedURL: null,
      encryptedUsername: null,
      encryptedPassword: null,
      processorInfo: {},
      cameras: [],
      areas: [],
      loading: LOADING_CONFIG.BLANK,
      modal: MODAL_ERRORS.FALSE,
    };
  }

  async componentDidMount() {
    if (!loadProcessorToken()) ProcessorController.updateProcessorToken('');
    return this.loadCloudConfig();
  }

  loadCloudConfig = async () => {
    const { fullLoad } = this.props;
    this.setState({ loading: LOADING_CONFIG.ACCOUNT });

    // Attempt to load configuration from cookies
    const { errorCode: localConfigError } = await ProcessorController.getProcessorInfo();
    if (!localConfigError) {
      return fullLoad
        ? this.loadProcessorConfig()
        : this.setState({ loading: LOADING_CONFIG.FALSE, modal: MODAL_ERRORS.FALSE });
    }

    if (localConfigError === ERRORS.PROCESSOR.CANT_REACH) {
      return this.goToModal(MODAL_ERRORS.CANT_REACH_PROCESSOR);
    }
    if (localConfigError === ERRORS.PROCESSOR.UNAUTHORIZED) {
      return this.goToModal(MODAL_ERRORS.BAD_PROCESSOR_CREDENTIALS);
    }

    // If the local configuration doesn't work request it
    const {
      data: { encryptedURL, encryptedUsername, encryptedPassword } = {}, errorCode: apiError,
    } = await SettingsController.requestProcessorData();
    if (apiError) {
      if (apiError === ERRORS.API.NO_CONFIG) return goToPage(routeNaming.SETUP);
      return this.goToModal(MODAL_ERRORS.UNEXPECTED);
    }

    // Decrypt requested configuration
    return this.setState({
      modal: MODAL_ERRORS.DECRYPT,
      encryptedURL,
      encryptedUsername,
      encryptedPassword,
    });
  }

  loadProcessorConfig = async () => {
    this.setState({ loading: LOADING_CONFIG.PROCESSOR, modal: MODAL_ERRORS.FALSE });
    const {
      processorInfo, errorCode: processorInfoError,
    } = await ProcessorController.getProcessorInfo();
    const { cameras, errorCode: cameraError } = await CameraController.getCameras();
    const { areas, errorCode: areaError } = await AreaController.getAreas();
    if (cameraError || areaError || processorInfoError) {
      if (cameraError === ERRORS.LOCAL.BAD_DATA || areaError === ERRORS.LOCAL.BAD_DATA) {
        return this.goToModal(MODAL_ERRORS.BAD_DATA);
      }
      return this.goToModal(MODAL_ERRORS.CANT_REACH_PROCESSOR);
    }
    return this.setState(
      { cameras, areas, processorInfo }, () => this.setState({ loading: LOADING_CONFIG.FALSE }),
    );
  }

  decryptProcessorData = async (password) => {
    const { encryptedURL, encryptedUsername, encryptedPassword } = this.state;
    const { errorCode: error } = await SettingsController.decryptAndSave(
      password, encryptedURL, encryptedUsername, encryptedPassword,
    );
    if (error) return { errorMessage: errorMessage(error) };
    const { fullLoad } = this.props;
    if (fullLoad) this.loadProcessorConfig();
    return {};
  }

  goToModal = (modal) => this.setState({ modal });

  dismissModal = () => this.goToModal(MODAL_ERRORS.FALSE);

  render() {
    const {
      processorInfo, cameras, areas, loading, modal, inSetup,
    } = this.state;
    const { render } = this.props;

    const config = { processorInfo, cameras, areas };

    const loadingScreen = inSetup
      ? null
      : (
        <div className={styles.background}>
          <Loader className={styles.loader} />
          {LOADING_MESSAGES[loading]}
        </div>
      );

    return (
      <>
        <ModalErrors
          dismiss={this.dismissModal}
          modal={modal}
          decrypt={this.decryptProcessorData}
          accept={() => this.goToModal(MODAL_ERRORS.FALSE)}
          retry={this.loadProcessorConfig}
          encrypt={() => {}}
          goToSettings={() => goToPage(routeNaming.SETUP)}
          goToDashboard={() => goToPage(routeNaming.DASHBOARD)}
        />
        {
          loading
            ? loadingScreen
            : render(config)
        }
      </>
    );
  }
}

ConfigProvider.propTypes = {
  render: PropTypes.func.isRequired,
  fullLoad: PropTypes.bool.isRequired,
};

export { ConfigProvider };
