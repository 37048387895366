import { constants } from 'config/constants';

const { METRICS } = constants;
const metricURLComponent = (metric) => {
  switch (metric) {
    case METRICS.SOCIAL_DST: return 'social-distancing';
    case METRICS.FACEMASK: return 'face-mask-detections';
    case METRICS.OCCUPANCY: return 'occupancy';
    case METRICS.IN_OUT: return 'in-out';
    default: return null;
  }
};

const METRIC_TIMEFRAMES = {
  LIVE: 'live',
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

const METRIC_TARGETS = {
  AREAS: 'areas',
  CAMERAS: 'cameras',
};

const PROCESSOR_ROUTES = {
  CONFIG: '/config',
  INFO: '/config/info',
  GLOBAL_REPORTS: '/config/global_report',
  PEAK_HOUR_VIOLATIONS: (camId) => `/reports/${camId}/peak_hour_violations`,
  FACEMASK_UTILIZATION: (camId) => `/reports/${camId}/face_mask_stats`,
  HOURLY_REPORT: (camId) => `/reports/${camId}/hourly`,
  DAILY_REPORT: (camId) => `/reports/${camId}/daily`,
  WEEKLY_REPORT: (camId) => `/reports/${camId}/weekly`,
  CAMERA_WITH_MOST_VIOLATIONS: '/reports/camera_with_most_violations',
  CAMERAS: '/cameras',
  CAMERAS_CAMID: (camId) => `/cameras/${camId}`,
  CALIBRATION_IMAGE: (camId) => `/cameras/${camId}/calibration_image`,
  CAMERA_IMAGE: (camId) => `/cameras/${camId}/image`,
  CALIBRATION_MATRIX: (camId) => `/cameras/${camId}/homography_matrix`,
  CAMERA_AREA: (camId) => `/cameras/${camId}/roi_contour`,
  IN_OUT_BOUNDARY: (camId) => `/cameras/${camId}/in_out_boundaries`,
  ENABLE_VIDEO_FEED: (camId) => `/cameras/${camId}/enable_video_live_feed`,
  VIDEO_FEED_ENABLED: (camId) => `/cameras/${camId}/video_live_feed_enabled`,
  HEATMAP_DATA: (camId) => `/metrics/cameras/${camId}/heatmap`,
  AREAS: '/areas',
  AREAS_AREAID: (areaId) => `/areas/${areaId}`,
  SLACK_ENABLED: '/slack/is-enabled',
  SLACK_REVOKE: '/slack/revoke',
  SLACK_CHANNEL: '/slack/add-channel',
  SLACK_ACTIVATE: '/slack/enable',
  DOWNLOAD_REPORTS: '/export',
  METRIC: (metric, target, timeframe) => `/metrics/${target}/${metricURLComponent(metric)}/${timeframe}`,
  REGISTER_USER: '/auth/create_api_user',
  GET_TOKEN: '/auth/access_token',
};

const UNAUTHORIZED_ROUTES = [PROCESSOR_ROUTES.REGISTER_USER, PROCESSOR_ROUTES.GET_TOKEN];

const SLACK_ROUTES = {
  authorize: 'https://slack.com/oauth/v2/authorize',
};

export {
  PROCESSOR_ROUTES, UNAUTHORIZED_ROUTES, SLACK_ROUTES,
  METRICS, METRIC_TARGETS, METRIC_TIMEFRAMES,
};
