import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './naturalInput.module.scss';

const mod = (x, y) => ((x % y) + y) % y;

const roundToStep = (steps, value) => steps.reduce((closest, latest) => (
  (Math.abs(closest - value) > Math.abs(latest - value)) ? latest : closest
));

const nextStep = (steps, value) => steps[mod(steps.indexOf(value) + 1, steps.length)];
const previousStep = (steps, value) => steps[mod(steps.indexOf(value) - 1, steps.length)];

// IMPORTANT: Component can set value to NaN
const NaturalInput = ({
  value, setValue, digits, steps, error, label, className, containerClassName, ...native
}) => {
  const [manuallyEditing, setManuallyEditing] = useState(false);

  const increase = Array.isArray(steps)
    ? () => setValue(nextStep(steps, roundToStep(steps, value)))
    : () => setValue(value + 1);
  const decrease = Array.isArray(steps)
    ? () => setValue(previousStep(steps, roundToStep(steps, value)))
    : () => setValue(Math.max(0, value - 1));
  const onChange = ({ target: { value: input } }) => {
    if (!/^[0-9]*$/.test(input) || input.length > 15) return;
    setManuallyEditing(true);
    if (!digits) {
      setValue(parseInt(input, 10));
      return;
    }
    if (input.length >= 3) {
      setValue(parseInt(input.slice(input.length - 2), 10));
    } else setValue(parseInt(input, 10));
  };
  const onBlur = () => {
    setManuallyEditing(false);
    if (steps) setValue(roundToStep(steps, !Number.isFinite(value) ? 0 : value));
    else if (!Number.isFinite(value)) setValue(0);
  };
  return (
    <div className={[styles.container, containerClassName].join(' ')}>
      <input
        type="text"
        value={!Number.isFinite(value) ? '' : `${value}`.padStart(manuallyEditing ? 0 : digits, '0')}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        className={[error ? styles.textInputError : styles.textInput, className].join(' ')}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...native}
      />
      <div className={styles.controls}>
        <button type="button" className={styles.buttonIncrease} onClick={increase} label={`${label}-inc`} />
        <button type="button" className={styles.buttonDecrease} onClick={decrease} label={`${label}-dec`} />
      </div>
    </div>
  );
};

NaturalInput.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  digits: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.number),
  error: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
};

NaturalInput.defaultProps = {
  label: '',
  digits: 0,
  steps: null,
  error: '',
  className: '',
  containerClassName: '',
};

export { NaturalInput };
