import React from 'react';
import PropTypes from 'prop-types';
import chevronUp from 'assets/images/icons-chevron-up.svg';

import styles from './accordion.module.scss';

const Accordion = (props) => {
  const {
    children, subAccordion, title, selectedIcon, unselectedIcon, showContent, setShowContent,
  } = props;

  return (
    <div className={styles.accordion}>
      <button
        className={showContent ? styles.selectedHeader : styles.unSelectedHeader}
        type="button"
        onClick={() => setShowContent(!showContent)}
      >
        {(selectedIcon || unselectedIcon) && (
          <img
            src={showContent ? selectedIcon : unselectedIcon}
            className={styles.icon}
            alt={title}
          />
        )}
        {subAccordion ? (
          <h3 className={styles.subAccordion}>
            {title}
          </h3>
        ) : (
          <h3 className={showContent ? styles.selectedTitle : styles.unSelectedTitle}>
            {title}
          </h3>
        )}
        <img src={chevronUp} alt="" className={showContent ? styles.chevron : [styles.chevron, styles.rotate].join(' ')} />
      </button>
      <div className={styles.content}>
        {showContent && (
          children
        )}
      </div>
    </div>
  );
};

export { Accordion };

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  selectedIcon: PropTypes.string,
  unselectedIcon: PropTypes.string,
  showContent: PropTypes.bool.isRequired,
  setShowContent: PropTypes.func.isRequired,
  subAccordion: PropTypes.bool,
};
Accordion.defaultProps = {
  selectedIcon: '',
  unselectedIcon: '',
  subAccordion: false,
};
