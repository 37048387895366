import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'video-react/dist/video-react.css';
import { Player, ControlBar } from 'video-react';
import HLSSource from 'components/HLSSource';

import { CameraController } from 'networking/controllers/camera-controller';
import { loadProcessorURL } from 'helpers/cookie-helper';

import genericStyles from 'assets/stylesheets/generic-styles.module.scss';
import { Loader } from 'common/loader';

import styles from './live-video.module.scss';

const LiveVideo = ({ selectedCamera }) => {
  const [videoLoading, setVideoLoading] = useState(false);
  const [refetchVideo, setRefetchVideo] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const isVideoFeedEnabled = async () => {
      const { errorCode, enabled } = await CameraController.isVideoFeedEnabled(selectedCamera.id);
      if (errorCode) {
        setError('Can\'t retrieve the live video now');
        return errorCode;
      }
      return enabled;
    };
    const enableVideoFeed = async () => {
      const { errorCode } = await CameraController.enableVideoFeed(selectedCamera.id);
      if (errorCode) setError('Can\'t retrieve the live video now');
    };
    const enabled = isVideoFeedEnabled();
    if (!enabled) {
      enableVideoFeed();
    }
  }, [selectedCamera]);

  const selectedSource = `${loadProcessorURL()}/static/gstreamer/${selectedCamera.id}/playlist.m3u8`;

  return (
    <div className={styles.outerDiv}>
      <div className={styles.cameraDiv}>
        <div className={
          [genericStyles.WorkSansRegularMedium, styles.cameraTitle].join(' ')
          }
        >
          {selectedCamera.cameraName}
        </div>
        <div className={styles.videoDiv}>
          <div className={styles.videoSnapshotDiv}>
            <div className={styles.videoStreaming}>
              <Player key={refetchVideo} muted autoPlay>
                <ControlBar disableCompletely />
                <HLSSource
                  isVideoChild
                  src={selectedSource}
                  setLoading={setVideoLoading}
                  refetchVideo={refetchVideo}
                  setRefetchVideo={setRefetchVideo}
                />
              </Player>
              {error && (
                <div className={styles.processorRebootDiv}>
                  <p className={styles.processorRebootText}>
                    {error}
                  </p>
                </div>
              )}
              {!error && videoLoading
              && (
                <div className={styles.processorRebootDiv}>
                  <Loader className={styles.loader} />
                  <p className={styles.processorRebootText}>
                    Video load is taking longer than expected
                    <br />
                    This may take a few seconds
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LiveVideo.propTypes = {
  selectedCamera: PropTypes.object.isRequired,
};

export {
  LiveVideo,
};
