const loadCookie = (name) => {
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i += 1) {
    const patt = new RegExp(`^${name}=(.*)$`);
    const match = patt.exec(cookies[i]);
    if (match) return match[1];
  }
  return null;
};

const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

const storeCookie = (name, cookie) => {
  deleteCookie(name);
  document.cookie = `${name}=${cookie}; path=/`;
};

const loadToken = () => loadCookie('token');
const storeToken = (token) => storeCookie('token', token);
const deleteToken = () => deleteCookie('token');

const loadRefreshToken = () => loadCookie('refreshToken');
const storeRefreshToken = (refreshToken) => storeCookie('refreshToken', refreshToken);
const deleteRefreshToken = () => deleteCookie('refreshToken');

const loadProcessorURL = () => loadCookie('processorURL');
const storeProcessorURL = (processorURL) => storeCookie('processorURL', processorURL);
const deleteProcessorURL = () => deleteCookie('processorURL');

const loadProcessorToken = () => loadCookie('processorToken');
const storeProcessorToken = (processorToken) => storeCookie('processorToken', processorToken);
const deleteProcessorToken = () => deleteCookie('processorToken');

export {
  storeToken, loadToken, deleteToken,
  loadRefreshToken, storeRefreshToken, deleteRefreshToken,
  storeProcessorURL, loadProcessorURL, deleteProcessorURL,
  storeProcessorToken, loadProcessorToken, deleteProcessorToken,
};
