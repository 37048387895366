class ApiErrorSerializer {
  static deSerialize(data) {
    return {
      code: data.code || null,
      detail: data.detail || null,
      body: data.body || null,
      message: data.detail[0].msg || null,
    };
  }
}

export { ApiErrorSerializer };
