import { PROCESSOR_ROUTES } from 'networking/processor-routes';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { AreaSerializer } from 'networking/serializers/area-serializer';
import { ProcessorService } from 'networking/processor-service';
import { Area } from 'models/area';

class AreaController {
  static async getAreas() {
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.AREAS);
      const areas = response.data.areas.map((area) => new Area(AreaSerializer.deSerialize(area)));
      return { areas, errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async saveArea(area, rebootProcessor) {
    const serializedArea = AreaSerializer.serialize(area);
    try {
      const response = await ProcessorService
        .post(`${PROCESSOR_ROUTES.AREAS}?rebootProcessor=${rebootProcessor}`, serializedArea);
      const deSerializedArea = AreaSerializer.deSerialize(response.data);
      return { obj: new Area(deSerializedArea), errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async editArea(area, rebootProcessor) {
    const serializedArea = AreaSerializer.serialize(area);
    try {
      const response = await ProcessorService
        .put(`${PROCESSOR_ROUTES.AREAS_AREAID(serializedArea.id)}?rebootProcessor=${rebootProcessor}`, serializedArea);
      const deSerializedArea = AreaSerializer.deSerialize(response.data);
      return { obj: new Area(deSerializedArea), errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async deleteArea(areaId, rebootProcessor) {
    try {
      return await ProcessorService
        .delete(`${PROCESSOR_ROUTES.AREAS_AREAID(areaId)}?rebootProcessor=${rebootProcessor}`);
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }
}

export { AreaController };
