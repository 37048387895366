import React, { useEffect, useState } from 'react';
import { SLACK_ROUTES } from 'networking/api-routes';
import { constants } from 'config/constants';
import { withAuthentication } from 'hocs/with-authentication';
import { LAYOUT_TYPES, withLayout } from 'hocs/with-layout';
import { withCloudConfig } from 'hocs/with-config';
import { goToPage, routeNaming } from 'routes';
import { ProcessorController } from 'networking/controllers/processor-controller';

import { TextInput } from 'common/textInput';
import { BUTTON_COLORS, MainButton } from 'common/buttons';

import styles from './slackSetup.module.scss';

function SlackSetup() {
  const [channel, setChannel] = useState('lanthorn-notifications');
  const [finish, setFinish] = useState(false);
  const [isConfigured, setConfigured] = useState(false);
  const [error, setError] = useState(new URLSearchParams(window.location.search).get('error'));

  useEffect(() => {
    const sendTokenToProcessor = async (userToken, channelParam) => {
      await ProcessorController.sendUserTokenToProcessor(userToken, channelParam);
      setFinish(true);
      window.history.replaceState(null, null, window.location.pathname);
    };

    const userToken = new URLSearchParams(window.location.search).get('access_token');
    const channelParam = new URLSearchParams(window.location.search).get('channel');
    if (userToken) sendTokenToProcessor(userToken, channelParam);
  }, []);

  useEffect(() => {
    const isSlackConfigured = async () => {
      const { enabled } = await ProcessorController.isSlackConfigured();
      setConfigured(enabled);
    };

    isSlackConfigured();
  }, []);

  const handleClick = () => {
    window.location.href = `${SLACK_ROUTES.authorize}`
      + `?client_id=${constants.slackClientId}`
      + `&scope=${constants.slackScopes}`
      + `&redirect_uri=${constants.redirectUrl}?channel=${channel}`;
  };

  const handleChangeChannel = async () => {
    const { errorCode } = await ProcessorController.changeSlackChannel(channel);
    if (!errorCode) return goToPage(routeNaming.DASHBOARD);
    return setError('An error ocurred when trying to save the cannel, please try again later.');
  };

  /* Slack channels don't support spaces */
  const handleOnChange = (e) => {
    setChannel(e.target.value.replace(' ', '-'));
  };

  return (
    <div className={styles.background}>
      <div className={styles.content}>
        <h1 className={styles.sectionTitle}>
          Setup Slack Notifications
        </h1>
        <div className={styles.slackContainer}>
          {finish
          && (
            <div className={styles.finish}>
              <a href="/dashboard">
                You&apos;ve successfully activated slack! Click here to go to the
                dashboard
              </a>
            </div>
          )}
          {!isConfigured ? (
            <>
              <p className={styles.channelName}>Channel name</p>
              <TextInput
                placeholder="Slack channel"
                value={channel}
                onChange={handleOnChange}
              />
              <p className={styles.hint}>Hint: You need this channel created in your slack</p>
              <MainButton
                onClick={handleClick}
                text="Enable slack"
                className={styles.button}
                color={BUTTON_COLORS.HALLOWEEN_BLACK}
              />
              {error && (
              <p className={styles.error}>
                There was a problem when trying to add our app to your Workspace, please try again
                later.
              </p>
              )}
            </>
          )
            : (
              <>
                <div className={styles.hint}>
                  <p>We detected that Slack is already configured for your workspace.</p>
                  <p>
                    You can now change the notification channel or you can reauthorize Slack
                    <button type="button" className={styles.link} onClick={() => setConfigured(false)}>
                      {' '}
                      by clicking
                      here.
                    </button>
                  </p>
                </div>
                <p className={styles.channelName}>Channel name</p>
                <TextInput
                  placeholder="Slack channel"
                  value={channel}
                  onChange={handleOnChange}
                />
                <div>
                  <MainButton
                    onClick={() => goToPage(routeNaming.DASHBOARD)}
                    text="Keep channel"
                    className={styles.button}
                    color={BUTTON_COLORS.GREY}
                  />
                  <MainButton
                    onClick={handleChangeChannel}
                    text="Change channel"
                    className={styles.button}
                    color={BUTTON_COLORS.HALLOWEEN_BLACK}
                  />
                </div>
                {error && <p className={styles.error}>{error}</p>}
              </>
            )}
        </div>
      </div>
    </div>
  );
}

const WrappedSlackSetup = withAuthentication(withLayout(
  LAYOUT_TYPES.MAIN, withCloudConfig(SlackSetup),
));

export { WrappedSlackSetup as SlackSetup };
