import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { Loader } from 'common/loader';
import { Callout, CALLOUT_TYPES } from 'common/callout';
import { Checkbox } from 'common/checkbox';
import { TextInput } from 'common/textInput';
import { AreaController } from 'networking/controllers/area-controller';
import { Toggle } from 'common/toggle';
import { goToPage, routeNaming } from 'routes';
import { errorMessage } from 'helpers/error-helper';
import { OccupancyRule, checkRuleGroupForErrors } from 'models/occupancyRule';
import { CameraController } from 'networking/controllers/camera-controller';
import { SETUP_STEPS } from '../setupSteps';
import { AddButton, EditButton } from '../setupButtons';
import { OccupancyRuleCard } from './occupancyRuleCard';

import styles from './setupAreas.module.scss';

const SetupAreas = ({ setSetupStep, isEditing, id }) => {
  // Data
  const [areas, setAreas] = useState([]);
  const [cameras, setCameras] = useState([]);

  // Fields
  const [areaName, setAreaName] = useState('');
  const [areaCapacity, setAreaCapacity] = useState('');
  const [emails, setEmails] = useState('');
  const [slackChannel, setSlackChannel] = useState('');
  const [error, setError] = useState('');
  const [occupancyRules, setOccupancyRules] = useState([{ ...new OccupancyRule() }]);
  const [selectedCameras, setSelectedCameras] = useState([]);

  // Toggles
  const [alerts, setAlerts] = useState(false);
  const [showOccupancyRules, setShowOccupancyRules] = useState(false);

  // Control
  const [selectedArea, setSelectedArea] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCameras, setLoadingCameras] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const setRule = (i) => (rule) => {
    setOccupancyRules([...occupancyRules.slice(0, i), rule, ...occupancyRules.slice(i + 1)]);
  };

  const addRule = () => {
    setOccupancyRules([...occupancyRules, new OccupancyRule()]);
  };

  const deleteRule = (i) => () => {
    setOccupancyRules([...occupancyRules.slice(0, i), ...occupancyRules.slice(i + 1)]);
  };

  const addArea = (e) => {
    e.preventDefault();
    // Check validity of occupancy rules
    if (showOccupancyRules && occupancyRules.length > 0) {
      const errors = checkRuleGroupForErrors(occupancyRules);
      if (errors) {
        setOccupancyRules(occupancyRules.map((rule, i) => ({ ...rule, error: errors[i] })));
        return;
      }
      setOccupancyRules(occupancyRules.map((rule) => ({ ...rule, error: null })));
    }

    // Add/Edit area
    const array = [...areas];
    const index = array.findIndex((area) => area.areaName === areaName);
    if (index === -1) {
      array.push({
        areaName,
        areaCapacity,
        cameras: selectedCameras,
        emails,
        newArea: true,
        id: array.length.toString(),
        occupancyRules: showOccupancyRules ? occupancyRules : [],
      });
    } else {
      array[index].areaName = areaName;
      array[index].areaCapacity = areaCapacity;
      array[index].cameras = selectedCameras;
      array[index].emails = emails;
      array[index].occupancyRules = showOccupancyRules ? occupancyRules : [];
    }
    setAreas(array);
  };

  useEffect(() => {
    if (selectedArea && selectedArea !== '') {
      setAreaName(selectedArea.areaName);
      setAreaCapacity(selectedArea.areaCapacity);
      setShowOccupancyRules(selectedArea.occupancyRules.length > 0);
      setOccupancyRules(
        selectedArea.occupancyRules.length > 0
          ? selectedArea.occupancyRules
          : [{ ...new OccupancyRule() }],
      );
      setSelectedCameras(selectedArea.cameras);
      setEmails(selectedArea.emails);
      setAlerts(selectedArea.emails
             || selectedArea.slackNotifications
             || selectedArea.occupancyRules.length > 0);
    }
  }, [selectedArea]);

  const loadAreas = useCallback(async () => {
    setLoading(true);
    const { areas: processorAreas, errorCode } = await AreaController.getAreas();
    if (!errorCode) {
      setAreas(processorAreas);
      processorAreas.forEach((area) => {
        if (area.id === id) {
          setSelectedArea(area);
        }
      });
    } else {
      setError(errorMessage(errorCode));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    setLoadingCameras(true);
    setLoading(true);
    const loadCamerasAndAreas = async () => {
      const {
        cameras: processorCameras, errorCode: camerasErrorCode,
      } = await CameraController.getCameras();
      const { areas: processorAreas, errorCode: areasErrorCode } = await AreaController.getAreas();
      if (!areasErrorCode) {
        setAreas(processorAreas);
        processorAreas.forEach((area) => {
          if (area.id === id) {
            setSelectedArea(area);
          }
        });
      } else {
        setError(errorMessage(areasErrorCode));
      }
      setLoading(false);
      if (!camerasErrorCode) {
        const simplifiedCameras = processorCameras.map((simplifiedCamera) => ({
          id: simplifiedCamera.id,
          cameraName: simplifiedCamera.cameraName,
          isChecked: false,
        }));
        setCameras(simplifiedCameras);
      }
      setLoadingCameras(false);
    };
    loadCamerasAndAreas();
  }, [id]);

  useEffect(() => {
    const deleteArea = async () => {
      await AreaController.deleteArea(removeId, false);
    };
    if (removeId) {
      deleteArea();
      loadAreas();
    }
  }, [removeId, loadAreas]);

  useEffect(() => {
    if (!alerts) setShowOccupancyRules(false);
  }, [alerts]);

  const selectAreas = (area) => {
    setError('');
    cameras.forEach((camera, index) => {
      const cameraMatch = area.cameras.find((cameraFromArea) => cameraFromArea === camera.id);
      const array = [...cameras];
      if (cameraMatch) array[cameraMatch].isChecked = true;
      else array[index].isChecked = false;
      setCameras(array);
    });
    setSelectedArea(area);
  };

  const submitAreas = async () => {
    setLoading(true);
    const returnedAreas = [];
    let areaHasError = false;
    const rebootProcessor = false;
    await Promise.all(areas.map(async (area) => {
      if (area.newArea) {
        const {
          obj: returnedArea,
          errorCode,
        } = await AreaController.saveArea(area, rebootProcessor);
        if (!errorCode) {
          return returnedAreas.push(returnedArea);
        }
        setSelectedArea(returnedArea);
        setError(errorMessage(errorCode));
        areaHasError = true;
      } else {
        const {
          obj: returnedArea,
          errorCode,
        } = await AreaController.editArea(area, rebootProcessor);
        if (!errorCode) {
          return returnedAreas.push(returnedArea);
        }
        setSelectedArea(returnedArea);
        setError(errorMessage(errorCode));
        areaHasError = true;
      }
      return {};
    }));
    setLoading(false);
    if (areaHasError) return {};
    if (isEditing) {
      goToPage(routeNaming.DASHBOARD);
    } else {
      setSetupStep(SETUP_STEPS.REPORTS);
    }
    return {};
  };

  // ---------------------- Visual ---------------------- //

  const chooseAreaSection = (
    <>
      <div className={styles.addAreaButton}>
        {
          selectedArea
            ? <AddButton type="submit" noIcon> EDIT AREA </AddButton>
            : <AddButton type="submit"> ADD AREA </AddButton>
        }
      </div>
      <div className={styles.areaListContainer}>
        <h2 className={styles.areaList}>
          Area List
        </h2>
        <div className={styles.areaListButtonContainer}>
          {loading && (<Loader className={styles.loader} />)}
          {!loading && (areas.map((area) => (
            <EditButton
              text={area.areaName}
              onClick={() => { selectAreas(area); }}
              id={area.id}
              setRemove={setRemoveId}
              key={area.areaName}
              isSelected={selectedArea === area}
            />
          )))}
        </div>
      </div>
    </>
  );

  const cameraCheckboxList = (
    <div className={styles.camerasListDiv}>
      {loadingCameras && (<Loader className={styles.loader} />)}
      <div className={styles.checkbox}>
        {cameras.map((camera) => {
          let isChecked = selectedCameras.some((cameraFromArea) => cameraFromArea === camera.id);
          return (
            <Checkbox
              id={camera.id}
              text={camera.cameraName}
              value={camera.id}
              checked={isChecked}
              onClick={(e) => {
                if (isChecked) {
                  const filteredCameras = [...selectedCameras].filter((c) => c !== camera.id);
                  setSelectedCameras(filteredCameras);
                } else if (selectedCameras.length > 0) {
                  const camerasFromArea = [...selectedCameras];
                  if (camerasFromArea[camera.id]) camerasFromArea.splice(camera.id, 0, camera.id);
                  else camerasFromArea.push(camera.id);
                  setSelectedCameras(camerasFromArea);
                } else {
                  setSelectedCameras([camera.id]);
                }
                isChecked = e.target.value;
              }}
              key={camera.id}
              label="after"
              className={styles.checkbox}
            />
          );
        })}
      </div>
    </div>
  );

  const notificationFields = (
    <div className={styles.alertsContainer}>
      <div className={styles.notificationsContainer}>
        <h3 className={styles.notificationsSubtitle}>Email</h3>
        <TextInput
          name="email"
          placeholder="hr@lanthorn.ai , alerts@lanthorn.ai"
          onChange={(e) => setEmails(e.target.value)}
          value={emails}
        />
      </div>
      <div className={styles.notificationsContainer}>
        <h3 className={styles.notificationsSubtitle}>Slack</h3>
        <div className={styles.slackInputDiv}>
          <TextInput
            name="slackChannel"
            placeholder="#lanthorn-notifications-channel"
            onChange={(e) => setSlackChannel(e.target.value)}
            value={slackChannel}
            className={styles.slackInput}
          />
          <MainButton
            className={styles.authorizeSlackButton}
            type="button"
            text="AUTHORIZE SLACK"
            onClick={(e) => {
              setSlackChannel(e.target.value);
              goToPage(routeNaming.SLACK_SETUP);
            }}
            label="AUTHORIZE SLACK"
          >
            AUTHORIZE&nbsp;SLACK
          </MainButton>
        </div>
      </div>
    </div>
  );

  const alertFields = alerts
    && (
      <div className={styles.alertsContainer}>
        <h3 className={styles.alertCapacitySubtitle}>
          Advanced&nbsp;Occupancy&nbsp;Rules&nbsp;&nbsp;&nbsp;
          <Toggle value={showOccupancyRules} setValue={setShowOccupancyRules} />
        </h3>
        {
          showOccupancyRules && (
            <div className={styles.occupancyRulesContainer}>
              <Callout
                text="Receive customized alerts for hours when you expect limited occupancy (i.e. 11 pm-5 am). You can set different occupancy thresholds to account for security personnel or other staff."
                type={CALLOUT_TYPES.WARNING}
                className={styles.occupancyRulesCallout}
              />
              <div className={styles.ruleContainer}>
                {
                  occupancyRules.map((rule, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <OccupancyRuleCard rule={rule} ruleIndex={i} setRule={setRule(i)} deleteRule={deleteRule(i)} key={`rule${i}`} />
                  ))
                }
                <div className={styles.addRuleContainer}>
                  <button type="button" className={styles.addRuleButton} onClick={addRule}>+</button>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>
          Create Areas
        </h1>
        <Callout text="Areas reflect spaces where there are several cameras" type={CALLOUT_TYPES.WARNING} />
        <form className={styles.formContainer} onSubmit={addArea}>
          <div className={styles.areasContainer}>
            <div>
              <h2 className={styles.subtitle}>
                Area Name
              </h2>
              <TextInput
                name="areaName"
                containerClassName={styles.inputContainer}
                placeholder="Type a name for your area"
                onChange={(e) => {
                  setAreaName(e.target.value);
                  e.target.validity.valid ? setError("") : setError("Valid characters: a-z, 0-9, -, _ and '");
                }}
                value={areaName}
                required
                error={error}
                pattern="[A-z 0-9_.'-]+"
              />
            </div>
            <div>
              <h2 className={styles.addCamerasSubtitle}>
                Area Capacity
              </h2>
              <div className={styles.occupantsDiv}>
                <TextInput
                  type="text"
                  pattern="[0-9]*"
                  name="areaCapacity"
                  placeholder="200"
                  containerClassName={styles.inputContainer}
                  className={styles.maxCapacityInput}
                  onChange={(e) => setAreaCapacity(
                    (e.target.validity.valid) ? e.target.value : areaCapacity,
                  )}
                  value={areaCapacity}
                  required
                />
                <p className={styles.description}>Occupants</p>
              </div>
            </div>
          </div>
          <h2 className={styles.addCamerasSubtitle}>
            Add cameras to this area
          </h2>
          {cameraCheckboxList}
          <h2 className={styles.addCamerasSubtitle}>
            Notifications
          </h2>
          {notificationFields}
          <h2 className={styles.addCamerasSubtitle}>
            Alert&nbsp;when&nbsp;at&nbsp;Max.&nbsp;capacity&nbsp;&nbsp;&nbsp;
            <Toggle value={alerts} setValue={setAlerts} />
          </h2>
          {
            !emails && !slackChannel && alerts
              ? <Callout text="You must set at least one email or slack channel to receive alerts" />
              : null
          }
          {alertFields}
          {chooseAreaSection}
        </form>
      </div>
      <div className={styles.stepButtonsDiv}>
        {isEditing ? (
          <>
            <MainButton
              className={styles.stepButton}
              type="button"
              onClick={() => { goToPage(routeNaming.DASHBOARD); }}
              color={BUTTON_COLORS.WHITE}
              text="CANCEL"
            />
            <MainButton
              className={styles.stepButton}
              type="submit"
              color={BUTTON_COLORS.SKY}
              text="FINISH"
              onClick={submitAreas}
            />
          </>
        ) : (
          <>
            <MainButton
              className={styles.stepButton}
              type="button"
              onClick={() => { setSetupStep(SETUP_STEPS.CAMERAS); }}
              color={BUTTON_COLORS.WHITE}
              text="PREV"
            />
            <MainButton
              className={styles.stepButton}
              type="button"
              color={BUTTON_COLORS.SKY}
              onClick={submitAreas}
              text="NEXT"
            />
          </>
        )}
      </div>
    </>
  );
};

SetupAreas.propTypes = {
  setSetupStep: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

SetupAreas.defaultProps = {
  id: '',
};

export { SetupAreas };
