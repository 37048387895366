import React from 'react';
import PropTypes from 'prop-types';

import { Accordion } from 'common/accordion/accordion';

import cameraIcon from 'assets/images/setup-icons/icons-cameras-disabled.png';

import styles from './areaCard.module.scss';

const AreaCard = (props) => {
  const {
    area, cameras, selected, onClick, selectCamera, setShowAreas,
  } = props;
  const areaCameras = area.cameras.map((id) => cameras.find((c) => c.id === id)).filter(Boolean);
  return (
    <Accordion
      title={area.areaName}
      subAccordion
      showContent={selected}
      setShowContent={onClick(area.id)}
    >
      {areaCameras.map((c) => (
        <div className={styles.cameraArea} key={`sidebar-area-${area.id}-camera-${c.id}`}>
          <button type="button" onClick={() => { selectCamera([c.id]); setShowAreas(false); }}>
            <img src={cameraIcon} alt="camera" />
            {c.cameraName}
          </button>
        </div>
      ))}
    </Accordion>
  );
};

AreaCard.propTypes = {
  area: PropTypes.object.isRequired,
  cameras: PropTypes.array.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selectCamera: PropTypes.func.isRequired,
  setShowAreas: PropTypes.func.isRequired,
};

export { AreaCard };
