import React from 'react';
import PropTypes from 'prop-types';

import styles from './checkbox.module.scss';

const Checkbox = (props) => {
  const {
    value, id, checked, onClick,
    label, text, className, disabled, checkboxContainerClassName, labelClassName,
  } = props;
  const labelElement = (
    <label className={[styles.label, labelClassName].join(' ')} dark={disabled.toString()} htmlFor={id}>
      {label === 'after' && <>&nbsp;&nbsp;</>}
      {text}
      {label === 'before' && <>&nbsp;&nbsp;</>}
    </label>
  );
  return (
    <div className={[styles.container, className].join(' ')} vertical-label={(label === 'top' || label === 'bottom').toString()}>
      {(label === 'before' || label === 'top') && labelElement}
      <div className={`${styles.checkboxContainer} ${checkboxContainerClassName}`} dark={disabled.toString()} label={label} on={checked ? 'true' : 'false'}>
        <input type="checkbox" className={styles.phantomCheckbox} value={value} id={id} checked={checked} onClick={onClick} disabled={disabled} readOnly />
      </div>
      {(label === 'after' || label === 'bottom') && labelElement}
    </div>
  );
};

Checkbox.propTypes = {
  value: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  checkboxContainerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  text: '',
  className: '',
  checkboxContainerClassName: '',
  labelClassName: '',
  disabled: false,
};

export { Checkbox };
