import { ApiService } from 'networking/api-service';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { API_ROUTES } from 'networking/api-routes';
import {
  loadProcessorURL, deleteProcessorURL,
  loadProcessorToken, deleteProcessorToken,
  loadToken,
} from 'helpers/cookie-helper';
import { validURL } from 'helpers/uri-helper';
import { logger } from 'helpers/logger';
import { decrypt, encrypt } from 'helpers/aes-helper';
import { SessionController } from 'networking/controllers/session-controller';
import { ERRORS } from 'helpers/error-helper';
import { EncryptedConfigurationSerializer } from 'networking/serializers/encrypted-configuration-serializer';

class SettingsController {
  static async getLocalProcessorData() {
    try {
      const processorURL = loadProcessorURL();
      if (!validURL(processorURL)) throw new Error('Invalid local configuration.');
      const processorToken = loadProcessorToken() || undefined;
      return { data: { processorURL, processorToken }, errorCode: false };
    } catch (error) {
      logger.log(error);
      return { errorCode: ERRORS.LOCAL.NO_CONFIG };
    }
  }

  // Request encrypted processor URL from backend, if it has one,
  // this will call a function to decrypt it and store it on a cookie (decryptAndSave)
  static async requestProcessorData(password) {
    let encryptedURL;
    let encryptedUsername;
    let encryptedPassword;
    try {
      const { data } = await ApiService.get(
        API_ROUTES.CONFIG, {}, { headers: { Authorization: `Bearer ${loadToken()}` } },
      );
      ({
        encryptedURL, encryptedUsername, encryptedPassword,
      } = EncryptedConfigurationSerializer.deSerialize(data));
    } catch (error) {
      return SessionController.handleAPIError(
        error, (err) => (err.status === 404 ? { errorCode: ERRORS.API.NO_CONFIG } : {}),
      );
    }
    if (!password) return { data: { encryptedURL, encryptedUsername, encryptedPassword } };
    return this.decryptAndSave(password, encryptedURL, encryptedUsername, encryptedPassword);
  }

  static async decryptData(password, encryptedURL, encryptedUsername, encryptedPassword) {
    try {
      const data = {};
      if (encryptedURL) data.processorURL = await decrypt(password, encryptedURL);
      if (encryptedUsername) data.processorUsername = await decrypt(password, encryptedUsername);
      if (encryptedPassword) data.processorPassword = await decrypt(password, encryptedPassword);
      return { data, errorCode: false };
    } catch (error) { return { errorCode: ERRORS.LOCAL.DECRYPTION }; }
  }

  static async saveData(processorURL, processorUsername, processorPassword) {
    if (!validURL(processorURL)) return { errorCode: ERRORS.LOCAL.INVALID_URL };
    ProcessorController.updateProcessorURL(processorURL);
    if (!processorUsername || !processorPassword) {
      ProcessorController.updateProcessorToken('');
      return { errorCode: false };
    }

    const { token, errorCode } = await ProcessorController.getToken(
      processorUsername, processorPassword,
    );
    if (errorCode) {
      deleteProcessorURL();
      deleteProcessorToken();
      return { errorCode };
    }
    ProcessorController.updateProcessorToken(token);
    return { errorCode: false };
  }

  static async decryptAndSave(password, encryptedURL, encryptedUsername, encryptedPassword) {
    const {
      data: { processorURL, processorUsername, processorPassword } = {}, errorCode: decryptionError,
    } = await this.decryptData(password, encryptedURL, encryptedUsername, encryptedPassword);
    if (decryptionError) return { errorCode: decryptionError };

    const { errorCode } = await this.saveData(processorURL, processorUsername, processorPassword);
    if (errorCode) return { errorCode };
    return { errorCode: false };
  }

  static async encryptData(password, processorURL, processorUsername, processorPassword) {
    const encryptedData = {};
    try {
      encryptedData.processorURL = await encrypt(password, processorURL);
      if (processorUsername && processorPassword) {
        encryptedData.processorUsername = await encrypt(password, processorUsername);
        encryptedData.processorPassword = await encrypt(password, processorPassword);
      }
      return { data: encryptedData };
    } catch (error) {
      return { errorCode: ERRORS.LOCAL.ENCRYPTION };
    }
  }

  static async sendEncryptedData(encryptedData) {
    try {
      const serializedData = EncryptedConfigurationSerializer.serialize(encryptedData);
      const parameters = { headers: { Authorization: `Bearer ${loadToken()}` } };
      await ApiService.post(API_ROUTES.CONFIG, serializedData, parameters);
    } catch (error) {
      return SessionController.handleAPIError(error);
    }
    return { errorCode: false };
  }

  static async encryptAndSend(password, processorURL, processorUsername, processorPassword) {
    const { data: encryptedData, errorCode: encryptionError } = await this.encryptData(
      password, processorURL, processorUsername, processorPassword,
    );
    if (encryptionError) return encryptionError;

    const { errorCode: saveConfigError } = await this.sendEncryptedData(encryptedData);
    if (saveConfigError) return saveConfigError;

    return { errorCode: false };
  }
}

export { SettingsController };
