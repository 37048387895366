import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { constants } from 'config/constants';

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(constants.googleAnalyticsId, { redactEmail: false });
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

export { RouteTracker };
