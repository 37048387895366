import React from 'react';
import { SessionController } from 'networking/controllers/session-controller';

import { LoginCard } from 'common/loginCard';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { TextInput } from 'common/textInput';
import { Callout, CALLOUT_TYPES } from 'common/callout';
import { goToPage, routeNaming } from 'routes';

import styles from './forgotPassword.module.scss';

const isValidEmail = (email) => /^\s*\w+([+.-]?\w*)*@\w+([.-]?\w+)*(\.\w\w+)+\s*$/.test(email);

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validEmail: false,
      error: false,
      message: '',
      loading: false,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
    this.emailChange = this.emailChange.bind(this);
  }

  async forgotPassword(event) {
    event.preventDefault();
    const { email } = this.state;
    if (!isValidEmail(email)) return;
    this.setState({ loading: true });
    const { errorCode: error, message } = await SessionController.forgotPassword(email.trim());
    this.setState({ loading: false });
    if (error) {
      this.setState({
        error,
        message,
      });
    } else {
      goToPage(routeNaming.PASSWORD_RECOVERY_SENT);
    }
  }

  emailChange(event) {
    this.setState({
      email: event.target.value,
      validEmail: isValidEmail(event.target.value),
      error: false,
    });
  }

  render() {
    const {
      email, validEmail, error, message, loading,
    } = this.state;
    return (
      <LoginCard>
        <h1 className={styles.cardTitle}>Password recovery</h1>
        <Callout
          text="If you reset your password, you won't be able to access your encrypted processor URL"
          type={CALLOUT_TYPES.WARNING}
        />
        <div className={styles.subTitle}>
          Enter the email you&apos;re using for your account.
        </div>
        <form className={styles.form} onSubmit={this.forgotPassword}>
          <TextInput
            id="email"
            placeholder="Email"
            onChange={this.emailChange}
            value={email}
            fat
          />
          {error ? (<p className={styles.errorMessage}>{message}</p>) : null}
          <MainButton
            text="Continue"
            className={styles.button}
            type="submit"
            disabled={!validEmail}
            loading={loading}
            color={BUTTON_COLORS.SKY}
            wide
          />
        </form>
      </LoginCard>
    );
  }
}

export { ForgotPassword };
