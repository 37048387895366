import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  lineWidth,
  canvasMargin,
  drawPoint,
  drawSegment,
  drawInArrow,
} from 'helpers/canvas-helper';

import cursor from 'assets/images/cursor.svg';
import demoBackgroundImage from 'assets/images/inOutDemoBackground.png';

import styles from './inOutDemoCanvas.module.scss';

const { sin, PI } = Math;

// Demo animation parameters
const demoStartPoint = { x: 299, y: 343 };
const demoEndPoint = { x: 225, y: 385 };
const demoMovementMilliseconds = 2000;
const demoWaitMilliseconds = 2000;
const demoFadeMilliseconds = 1000;
const demoWaitPostFadeMilliseconds = 2000;
const millisecondsPerTick = 10;
const contentSize = { x: 640, y: 480 };

// Demo animation calculations
const demoDistance = { x: demoEndPoint.x - demoStartPoint.x, y: demoEndPoint.y - demoStartPoint.y };
const demoMovementTicks = demoMovementMilliseconds / millisecondsPerTick;
const demoWaitTicks = demoWaitMilliseconds / millisecondsPerTick;
const demoFadeTicks = demoFadeMilliseconds / millisecondsPerTick;
const demoWaitPostFadeTicks = demoWaitPostFadeMilliseconds / millisecondsPerTick;

// Non-linear curve ([0,1] -> [0,1]) for "natural" mouse movement
const positionCurve = (x) => (sin(((x - 0.5) * PI)) + 1) / 2;

const InOutDemoCanvas = ({ config }) => {
  const { color } = config;
  const canvasSize = { x: contentSize.x + 2 * canvasMargin, y: contentSize.y + 2 * canvasMargin };

  const canvas = useRef(null);
  const points = useRef([]);
  const demoCounter = useRef(0);
  const demoCursor = useRef();
  const demoBackground = useRef();

  const drawCanvas = useCallback(() => {
    if (!canvas || !canvas.current) return;
    const context = canvas.current.getContext('2d');
    context.fillStyle = color;
    context.lineWidth = lineWidth;
    context.strokeStyle = color;
    context.clearRect(0, 0, canvasSize.x, canvasSize.y);

    if (points.current[0]) {
      if (points.current[1]) {
        drawSegment(context, points.current[0], points.current[1]);
        drawInArrow(context, points.current[0], points.current[1]);
        drawPoint(context, points.current[1]);
      }
      drawPoint(context, points.current[0]);
    }
  }, [canvasSize, color]);

  const setPoints = useCallback((newPoints) => {
    points.current = newPoints;
    drawCanvas();
  }, [drawCanvas]);

  const updateDemo = useCallback(() => {
    const tick = demoCounter.current;
    if (demoCounter.current < demoMovementTicks) {
      // Moving cursor
      if (tick === 0) {
        setPoints([{ ...demoStartPoint }, { ...demoStartPoint }]);
        if (canvas.current) canvas.current.style.opacity = 1;
      }
      const relativeDistanceMoved = positionCurve(demoCounter.current / demoMovementTicks);
      points.current[1].x = demoStartPoint.x + demoDistance.x * relativeDistanceMoved;
      points.current[1].y = demoStartPoint.y + demoDistance.y * relativeDistanceMoved;
      drawCanvas();
      if (demoCursor.current) {
        demoCursor.current.style.opacity = 1;
        demoCursor.current.style.left = `${points.current[1].x - canvasMargin}px`;
        demoCursor.current.style.top = `${points.current[1].y - canvasMargin}px`;
      }
    } else if (tick < demoMovementTicks + demoWaitTicks) {
      // Waiting to fade
    } else if (tick < demoMovementTicks + demoWaitTicks + demoFadeTicks) {
      // Fading
      if (canvas.current && demoCursor.current) {
        const relativeFade = (tick - demoMovementTicks - demoWaitTicks + 1) / demoFadeTicks;
        canvas.current.style.opacity = 1 - relativeFade;
        demoCursor.current.style.opacity = 1 - relativeFade;
      }
    } else if (tick < demoMovementTicks + demoWaitTicks + demoFadeTicks + demoWaitPostFadeTicks) {
      if (points.current[0]) setPoints([]);
    }
    demoCounter.current = tick < demoMovementTicks
                               + demoWaitTicks
                               + demoFadeTicks
                               + demoWaitPostFadeTicks
      ? tick + 1
      : 0;
    setTimeout(updateDemo, millisecondsPerTick);
  }, [drawCanvas, setPoints]);

  useEffect(() => { updateDemo(); }, [updateDemo]);

  return (
    <div className={styles.container} style={{ width: canvasSize.x, height: canvasSize.y }}>
      <div
        className={styles.innerContainer}
        style={{ width: contentSize.x, height: contentSize.y }}
      >
        <img className={styles.cursor} ref={demoCursor} src={cursor} alt="cursor" />
        <img className={styles.demoBackground} ref={demoBackground} src={demoBackgroundImage} alt="demoBackground" />
      </div>
      <canvas
        ref={canvas}
        width={canvasSize.x}
        height={canvasSize.y}
        style={{ width: canvasSize.x, height: canvasSize.y }}
      />
    </div>
  );
};

InOutDemoCanvas.propTypes = {
  config: PropTypes.shape({
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export { InOutDemoCanvas };
