import React from 'react';
import PropTypes from 'prop-types';

import addIcon from 'assets/images/setup-icons/icons-add.png';
import styles from './addButton.module.scss';

const AddButton = (props) => {
  const {
    className, type, children, noIcon, ...native
  } = props;
  return (
    <button
      className={[styles.button, className].join(' ')}
      // eslint-disable-next-line react/button-has-type
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...native}
    >
      {children}
      {noIcon ? '' : <img className={styles.icon} alt="" src={addIcon} />}
    </button>
  );
};

AddButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  noIcon: PropTypes.bool,
  type: PropTypes.string,
};

AddButton.defaultProps = {
  className: '',
  noIcon: false,
  type: 'button',
};

export { AddButton };
