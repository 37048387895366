import React, { useEffect, useState } from 'react';
import { UserController } from 'networking/controllers/user-controller';
import { AppLink, routeNaming } from 'routes';
import { constants } from 'config/constants';
import { LoginCard } from 'common/loginCard';
import { Loader } from 'common/loader';

import styles from './emailActivated.module.scss';

const InvalidTokenMessage = () => (
  <p>
    Verification of account blocked.
    <br />
    <br />
    The email token that you have tried to use is expired or invalid.
    <br />
    <br />
    <AppLink
      className={styles.link}
      routeName={routeNaming.LOGIN}
    >
      Please click here to log in again.
    </AppLink>
  </p>
);

const SuccessMessage = () => (
  <div>
    Congratulations, you have successfully activated your
    account.
    <br />
    <br />
    <AppLink
      className={styles.link}
      routeName={routeNaming.LOGIN}
    >
      Click here to access your account.
    </AppLink>
    <br />
    <br />
    Any questions? Email us at
    {' '}
    <a href={`mailto:${constants.lanthornEmail}`}>{constants.lanthornEmail}</a>
  </div>
);

function EmailActivated() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const activateEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      const { errorCode: activationError } = await UserController.activateUser(token);
      if (activationError) setError(activationError);
      setLoading(false);
    };

    activateEmail();
  }, []);

  return (
    <LoginCard skipLoginCheck>
      <div className={styles.content}>
        {(loading || null) && <Loader className={styles.loader} />}
        {!(loading || null) && (error ? InvalidTokenMessage() : SuccessMessage())}
      </div>
    </LoginCard>
  );
}

export { EmailActivated };
