import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import styles from './modalDates.module.scss';

function ModalDates({ dates, applyFn, single }) {
  const [from, setFrom] = useState(dates.from || undefined);
  const [to, setTo] = useState(dates.to || undefined);
  const [showError, setShowError] = useState(false);

  const fromSelected = (date) => {
    if (dayjs(date).isAfter(dayjs())) return setShowError(true);
    setShowError(false);
    let newFrom = date;
    let newTo = to;
    if (dayjs(date).isAfter(dayjs(to))) {
      newFrom = to;
      newTo = date;
    }
    setFrom(newFrom);
    return setTo(newTo);
  };

  const toSelected = (date) => {
    if (dayjs(date).isAfter(dayjs())) return setShowError(true);
    setShowError(false);
    let newFrom = from;
    let newTo = date;
    if (dayjs(date).isBefore(dayjs(from))) {
      newFrom = date;
      newTo = from;
    }
    setFrom(newFrom);
    return setTo(newTo);
  };

  const applyButton = (
    <MainButton
      text="Apply"
      type="button"
      onClick={() => applyFn({ from, to })}
      color={BUTTON_COLORS.SKY}
      className={styles.applyButton}
      short
    />
  );

  if (single) {
    return (
      <div className={styles.modal}>
        <h2>Change date</h2>
        <div className={styles.content}>
          <input
            type="date"
            value={from}
            onChange={((e) => { setFrom(e.target.value); setTo(e.target.value); })}
            className={styles.dateInput}
          />
        </div>
        <div className={styles.actions}>{applyButton}</div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <h2>Change dates</h2>
      <div className={styles.content}>
        <label className={styles.dateLabel} htmlFor="from">
          From&nbsp;
          <input
            type="date"
            value={from}
            id="from"
            onChange={((e) => fromSelected(e.target.value))}
            className={styles.dateInput}
          />
        </label>
        <label className={styles.dateLabel} htmlFor="to">
          To&nbsp;
          <input
            type="date"
            value={to}
            id="to"
            onChange={((e) => toSelected(e.target.value))}
            className={styles.dateInput}
          />
        </label>
      </div>
      {showError && <p>Future dates are not allowed.</p>}
      <div className={styles.actions}>{applyButton}</div>
    </div>
  );
}

ModalDates.propTypes = {
  dates: PropTypes.object.isRequired,
  applyFn: PropTypes.func.isRequired,
  single: PropTypes.bool,
};

ModalDates.defaultProps = {
  single: false,
};

export {
  ModalDates,
};
