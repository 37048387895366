import React from 'react';
import PropTypes from 'prop-types';

import { NavBar } from 'common/navBar';
import styles from './layout.module.scss';

const LAYOUT_TYPES = {
  MAIN: 'main',
  DEMO: 'demo',
};

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  layoutType: PropTypes.oneOf(Object.values(LAYOUT_TYPES)).isRequired,
};

const layoutIsValid = (layoutType) => Object.values(LAYOUT_TYPES).includes(layoutType);

const Layout = ({ layoutType, children }) => {
  if (!layoutIsValid(layoutType)) {
    throw new Error(`Invalid value for \`layoutType\`, was "${layoutType}"`);
  }
  return (
    <div>
      <NavBar demoMode={layoutType === LAYOUT_TYPES.DEMO} />
      <div className={styles.container}>
        {
          children
        }
      </div>
    </div>
  );
};

Layout.propTypes = IPropTypes;

export { Layout, LAYOUT_TYPES };
