import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../../common/checkbox';

import styles from './sidebarCard.module.scss';

const SidebarCard = ({
  title, id, children, selected, onClick,
}) => (
  <button type="button" className={selected ? styles.selectedCard : styles.card} onClick={onClick}>
    <div className={styles.main}>
      {title}
      &#8203;
      <div className={styles.children}>
        {children}
      </div>
    </div>
    <Checkbox
      checkboxContainerClassName={styles.checkbox}
      checked={selected}
      onClick={onClick}
      value={id}
      id={id}
    />
  </button>
);

SidebarCard.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SidebarCard.defaultProps = {
  children: null,
};

export { SidebarCard };
