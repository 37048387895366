import React from 'react';
import PropTypes from 'prop-types';

import styles from './callout.module.scss';

const CALLOUT_TYPES = {
  WARNING: 'warning',
};

const Callout = (props) => {
  const {
    text, className, type,
  } = props;
  return (
    <div type={type} className={[styles.calloutDiv, className].join(' ')}>
      <p type={type} className={styles.text}>{text}</p>
    </div>
  );
};

Callout.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(CALLOUT_TYPES)),
};

Callout.defaultProps = {
  className: '',
  type: CALLOUT_TYPES.WARNING,
};

export { Callout, CALLOUT_TYPES };
