class CameraSerializer {
  static deSerialize(camera) {
    let notificationViolationsThreshold = '1';
    let notificationTimeThreshold = '15';
    let useHours = false;
    if (camera.notify_every_minutes && camera.violation_threshold) {
      useHours = camera.notify_every_minutes % 60 === 0;
      notificationViolationsThreshold = `${camera.violation_threshold}`;
      notificationTimeThreshold = useHours ? `${camera.notify_every_minutes / 60}` : `${camera.notify_every_minutes}`;
    }
    return {
      id: camera.id,
      cameraName: camera.name,
      emails: camera.emails || '',
      cameraPath: camera.video_path || '',
      image: camera.image || null,
      notificationPerHour: useHours,
      notificationViolationsThreshold,
      notificationTimeThreshold,
      dailyReport: camera.daily_report || false,
      dailyReportTime: camera.daily_report_time,
      slackNotifications: camera.enable_slack_notifications || false,
      tags: camera.tags,
      isChecked: camera.isChecked || false,
      calibrated: camera.has_been_calibrated || false,
      liveFeedEnabled: camera.live_feed_enabled || false,
      hasAreaOfInterest: camera.has_defined_roi || false,
      hasInOutBoundary: camera.has_in_out_border || false,
    };
  }

  static serialize(camera) {
    return {
      video_path: camera.cameraPath,
      name: camera.cameraName,
      id: camera.id,
      emails: camera.emailNotifications ? camera.emails : undefined,
      notify_every_minutes: 10,
      violation_threshold: camera.notificationViolationsThreshold,
      daily_report: camera.dailyReport,
      isChecked: camera.isChecked || false,
      dist_method: camera.calibrated ? 'CalibratedDistance' : 'CenterPointsDistance',
    };
  }
}

export { CameraSerializer };
