import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './select.module.scss';

const Select = (props) => {
  const {
    fat, children, defaultOption, onChange, value, className, ...native
  } = props;
  const [validValue, changeValidValue] = useState(false);
  useEffect(() => {
    if (value) {
      changeValidValue(true);
    }
  }, [value]);
  return (
    <select
      className={[styles.select, className].join(' ')}
      value={defaultOption && !validValue ? '' : value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...native}
      onChange={(e) => { changeValidValue(true); onChange(e); }}
      fat={fat ? 'true' : 'false'}
      valid-value={validValue ? 'true' : 'false'}
    >
      {defaultOption && <option value="" disabled>{defaultOption}</option>}
      {children}
    </select>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  fat: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

Select.defaultProps = {
  value: null,
  defaultOption: null,
  onChange: () => {},
  fat: false,
  className: '',
};

export { Select };
