import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CountryController } from '../../networking/controllers/country-controller';
import { PasswordHelper } from '../../helpers/password-helper';

import { Modal } from '../modal';
import { BUTTON_COLORS, MainButton } from '../buttons';
import { TextInput } from '../textInput';
import { Select } from '../select';
import { Checkbox } from '../checkbox';

import styles from './registerForm.module.scss';
import closeIcon from '../../assets/images/cross-black.png';
import genericStyles from '../../assets/stylesheets/generic-styles.module.scss';
import spinner from '../../assets/images/loading-blackBG.gif';
import { errorMessage } from '../../helpers/error-helper';
import { Callout, CALLOUT_TYPES } from '../callout';

const TermsModal = (props) => {
  const { dismiss } = props;
  return (
    <div className={[genericStyles.genericContainer, styles.modalContainer].join(' ')}>
      <button type="button" className={styles.closeIcon} onClick={dismiss} alt="Close" src={closeIcon}>
        <img alt="Close" src={closeIcon} />
      </button>
      <h3 className={styles.header}>Privacy Policy</h3>
      <p>
        Your privacy is important to us. It is Lanthorn&apos;s policy to respect
        your
        privacy regarding any information we may collect from you across our
        website, http://www.lanthorn.ai, and other sites we own and operate.
        <br />
        <br />
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.
        <br />
        <br />
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect within
        commercially acceptable means to prevent loss and theft, as well as
        unauthorized access, disclosure, copying, use or modification.
        <br />
        <br />
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.
        <br />
        <br />
        Our website may link to external sites that are not operated by us. Please
        be aware that we have no control over the content and practices of these
        sites, and cannot accept responsibility or liability for their respective
        privacy policies.
        <br />
        <br />
        You are free to refuse our request for your personal information, with the
        understanding that we may be unable to provide you with some of your
        desired services.
        <br />
        <br />
        Your continued use of our website will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.
        <br />
        <br />
        This policy is effective as of 1 September 2020.
      </p>
    </div>
  );
};

const RegisterForm = ({ existingUser, saveUser }) => {
  const [terms, showTerms] = useState(false);
  const [user, setUser] = useState(existingUser || { terms: false });
  const [errors, setErrors] = useState([]);
  const [countries, setCountries] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState(existingUser ? 'Update profile' : 'Sign Up');

  const fetchCountries = async () => {
    const { countries: list, errorCode } = await CountryController.getCountries();
    if (errorCode) return setCountries([]);
    return setCountries(list);
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleOnChange = (e) => {
    const newUser = { ...user };
    const field = e.target.name;
    if (field === 'terms') {
      newUser[field] = e.target.checked;
    } else {
      newUser[field] = e.target.value;
    }
    if (field === 'password') {
      if (!PasswordHelper.nonControlASCIICheck(e.target.value)) return;
      newUser.score = PasswordHelper.passwordScore(e.target.value);
    }
    setUser(newUser);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    const foundErrors = [];
    if (!e.target.checkValidity()) {
      return setErrors([<p key="req">Missing required fields.</p>]);
    }
    if (!existingUser && !user.password) {
      return setErrors([<p key="password">Password is required.</p>]);
    }
    if (user.password !== user.passwordRepeat) {
      return setErrors([<p key="match">Passwords don’t match.</p>]);
    }
    if (user.score < 30) {
      return setErrors([<p key="score">Password score is not high enough.</p>]);
    }
    if (existingUser && user.password && !user.currentPassword) {
      return setErrors([<p key="new-password">You must provide your old password to change to a new one.</p>]);
    }

    setLoading(true);
    const { error, message } = await saveUser(user);
    setLoading(false);

    if (error) {
      foundErrors.push((<p key={message || error}>{message || errorMessage(error)}</p>));
      return setErrors(foundErrors);
    }

    return setButtonText(existingUser ? 'Updated successfully!' : 'Welcome to Lanthorn!');
  };

  const scoreMessage = user.score
    ? (
      <p
        style={{
          color: PasswordHelper.getPasswordScoreAndStyle(user.score).color,
          visibility: user.score > 0 ? '' : 'hidden',
        }}
        className={styles.scoreContainer}
      >
        {PasswordHelper.getPasswordScoreAndStyle(user.score).value}
      </p>
    )
    : null;

  return countries
    ? (
      <form
        onSubmit={handleOnSubmit}
        className={`${styles.formContainer} ${existingUser ? styles.backgroundEditing : ''}`}
      >

        {terms
        && (
          <Modal dismiss={() => showTerms(false)}>
            <TermsModal dismiss={() => showTerms(false)} />
          </Modal>
        )}

        {!existingUser && (
          <>
            <div className={styles.registerText}>Register</div>
            <Callout text="Keep your password safe, as it is needed to encrypt your processor URL later" type={CALLOUT_TYPES.WARNING} />
          </>
        )}
        <div className={styles.namesContainer}>
          <div className={styles.nameContainer} style={{ marginRight: '10px' }}>
            <TextInput
              name="firstName"
              placeholder="First Name*"
              onChange={handleOnChange}
              value={user.firstName || ''}
              required
              fat
            />
          </div>
          <div className={styles.nameContainer}>
            <TextInput
              name="lastName"
              placeholder="Last Name*"
              onChange={handleOnChange}
              value={user.lastName || ''}
              required
              fat
            />
          </div>
        </div>
        <TextInput
          name="email"
          placeholder="Email*"
          value={user.email || ''}
          onChange={handleOnChange}
          disabled={existingUser}
          required
          fat
        />
        <TextInput
          name="company"
          placeholder="Company, University, Hospital"
          onChange={handleOnChange}
          value={user.company || ''}
          fat
        />
        <Select
          name="country"
          placeholder="Country"
          onChange={handleOnChange}
          value={user.country || ''}
          defaultOption="Country"
          fat
        >
          {countries.map((country) => (
            <option
              key={country.name + country.code}
              value={country.name}
            >
              {country.name}
            </option>
          ))}
        </Select>
        {!existingUser && (
          <>
            <TextInput
              name="password"
              placeholder="Password"
              type="password"
              onChange={handleOnChange}
              minLength={8}
              required
              fat
            />
            {scoreMessage}
            <TextInput
              name="passwordRepeat"
              placeholder="Repeat Password"
              type="password"
              onChange={handleOnChange}
              required
              fat
            />
          </>
        )}
        {existingUser && (
          <details className="tab">
            <summary>Change password</summary>
            <TextInput
              name="currentPassword"
              placeholder="Current password"
              type="password"
              onChange={handleOnChange}
              fat
            />
            <TextInput
              name="password"
              placeholder="New password"
              type="password"
              onChange={handleOnChange}
              minLength={8}
              fat
            />
            {scoreMessage}
            <TextInput
              name="passwordRepeat"
              placeholder="Repeat new password"
              type="password"
              onChange={handleOnChange}
              fat
            />
          </details>
        )}
        {!existingUser
        && (
          <div className={styles.termsAndConditionsCheckbox}>
            <Checkbox
              type="checkbox"
              id="termsAndConditionsCheckbox"
              onClick={() => setUser({ ...user, terms: !user.terms })}
              value="TermsAndConditions"
              checked={user.terms}
              text="Accept"
              label="after"
            />
            <button
              onClick={() => showTerms(true)}
              type="button"
              className={styles.termsLink}
            >
              Terms & Conditions
            </button>
          </div>
        )}

        {errors.length > 0
        && (
          <div className={styles.errorsText}>
            {errors}
          </div>
        )}

        <MainButton
          type="submit"
          color={BUTTON_COLORS.SKY}
          text={buttonText}
          loading={loading}
          wide
          disabled={!existingUser && !user.terms}
        />

      </form>
    )
    : (
      <div className={styles.loadingDiv}>
        <img src={spinner} alt="loading" />
      </div>
    );
};

RegisterForm.defaultProps = {
  existingUser: null,
};

RegisterForm.propTypes = {
  existingUser: PropTypes.object,
  saveUser: PropTypes.func.isRequired,
};

TermsModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
};

export { RegisterForm };
