import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { UserController } from 'networking/controllers/user-controller';
import { Loader } from 'common/loader';
import { LoginCard } from 'common/loginCard';
import { goToPage, routeNaming } from 'routes';
import { errorMessage } from 'helpers/error-helper';
import styles from './activateAccount.module.scss';

function ActivateAccount({ location }) {
  const [state, setState] = useState({ loading: false });

  useEffect(() => {
    if (!new URLSearchParams(location.search).get('email')) goToPage(routeNaming.LOGIN);
  }, [location.search]);

  const resendEmail = async () => {
    setState({ ...state, loading: true });
    const { errorCode: error } = await UserController.resendEmail(
      new URLSearchParams(location.search).get('email'),
    );
    if (error) {
      setState({
        ...state,
        error,
        message: errorMessage(error),
      });
    }
    setState({
      ...state,
      loading: false,
      success: true,
      message: 'The confirmation email has been successfully sent.',
    });
  };

  return (
    <LoginCard>
      <div className={styles.content}>
        <h3 className={styles.title}>
          Sign in attempt blocked.
        </h3>
        {
          !state.loading
            ? (
              <>
                <p>
                  To sign in please first verify your email address.
                  <br />
                  If you never got our message to verify your email address,
                  click
                  {' '}
                  <button
                    className={styles.link}
                    type="button"
                    onClick={resendEmail}
                  >
                    here
                  </button>
                  <br />
                </p>
                {state.success && (<p className={styles.success}>{state.message}</p>)}
                {state.error && (<p className={styles.error}>{state.message}</p>)}
              </>
            ) : <Loader className={styles.loader} />
        }
      </div>
    </LoginCard>
  );
}

ActivateAccount.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

ActivateAccount.defaultProps = {
  location: { search: '' },
};

export { ActivateAccount };
