import colors from 'assets/stylesheets/colors.scss';

class PasswordHelper {
  static passwordScore(pass) {
    let score = 0;
    if (!pass) {
      return score;
    }

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    const variationCount = Object.values(variations).reduce(
      (acc, result) => acc + (result ? 1 : 0), 0,
    );
    score += (variationCount - 1) * 10;

    return score;
  }

  static getPasswordScoreAndStyle(score) {
    if (score > 80) {
      return {
        value: 'Strong',
        color: colors.successTextGreen,
      };
    }
    if (score > 60) {
      return {
        value: 'Good',
        color: colors.warningTextYellow,
      };
    }
    if (score >= 30) {
      return {
        value: 'Weak',
        color: colors.errorTextRed,
      };
    }

    return {
      value: 'Too weak',
      color: colors.darkIndigo,
    };
  }

  static nonControlASCIICheck(pass) {
    return /^[\x20-\x7F]*$/.test(pass);
  }
}

export { PasswordHelper };
