import React from 'react';

import { Layout } from './layout';

const withLayout = (layoutType, Component, product) => (
  (props) => (
    <Layout layoutType={layoutType} product={product}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </Layout>
  )
);

export { withLayout };
