/* eslint-disable camelcase */
import { METRICS } from 'networking/processor-routes';
import { processTimeframe } from 'helpers/timeframe-helper';
import { Timeframe, TIMEFRAME_TYPES } from 'models/timeframe';

class ReportQuerySerializer {
  static serialize(
    areas, cameras, metrics, timerange = new Timeframe({ type: TIMEFRAME_TYPES.DAILY }),
  ) {
    const serializedAreas = {};
    if (Array.isArray(areas)) serializedAreas.areas = [...areas];
    else serializedAreas.all_areas = !!areas;

    const serializedCameras = {};
    if (Array.isArray(cameras)) serializedCameras.cameras = [...cameras];
    else serializedCameras.all_cameras = !!cameras;

    const data_types = Array.isArray(metrics)
      ? [
        metrics.includes(METRICS.OCCUPANCY) && 'occupancy',
        metrics.includes(METRICS.FACEMASK) && 'facemask-usage',
        metrics.includes(METRICS.SOCIAL_DST) && 'social-distancing',
        metrics.includes(METRICS.IN_OUT) && 'in-out',
      ].filter(Boolean)
      : ['all_data'];

    const { timeframeQueryParams: { from_date, to_date } } = processTimeframe(timerange);

    return {
      ...serializedAreas, ...serializedCameras, data_types, from_date, to_date,
    };
  }
}

export { ReportQuerySerializer };
