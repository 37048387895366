import dayjs from 'dayjs';

class FacemaskStatsSerializer {
  static deSerialize(stats) {
    return {
      lastUpdated: stats.time && dayjs(stats.time).format('h:mm a'),
      data: {
        trend: stats.trend,
        unknown: stats.no_face || 0,
        facemask: stats.face_with_mask || 0,
        noFacemask: stats.face_without_mask || 0,
      },
    };
  }
}

export { FacemaskStatsSerializer };
