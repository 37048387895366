class InOutBoundarySerializer {
  static serialize(points, cameraId) {
    return {
      in_out_boundaries: points.reduce((acc, point, i) => {
        if (i % 2 === 0) {
          // Add new line, it's name and it's first point
          acc.push({
            name: `cam${cameraId}-line${i / 2}`,
            in_out_boundary: [[point.x, point.y]],
          });
        } else {
          // Add second point to the last line
          acc[acc.length - 1].in_out_boundary.push([point.x, point.y]);
        }
        return acc;
      }, []),
    };
  }

  static deSerialize(lines) {
    return lines.reduce((acc, line) => {
      acc.push({ x: line.in_out_boundary[0][0], y: line.in_out_boundary[0][1] });
      acc.push({ x: line.in_out_boundary[1][0], y: line.in_out_boundary[1][1] });
      return acc;
    }, []);
  }
}

export { InOutBoundarySerializer };
