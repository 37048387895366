import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  CANVAS_LINES,
  lineWidth,
  canvasMargin,
  drawPoint,
  drawSegment,
  drawLine,
  getMousePosition,
  findClosePoint,
  offset,
  clampPointToSize,
  maskOutsidePolygon,
} from 'helpers/canvas-helper';

import styles from './polygonCanvas.module.scss';

const CANVAS_STATES = {
  OFF: 'off',
  ADDING: 'adding',
  MOVING: 'moving',
};

const dragging = {
  point: -1,
  offset: { x: 0, y: 0 },
};

const PolygonCanvas = ({
  state, returnCanMove, returnPoints, config, startingPoints, children,
}) => {
  const {
    size: contentSize, minPoints, maxPoints, lineStyle, color, maskOutside,
  } = config;

  const [points, setPoints] = useState([]);
  const canvasRef = useRef(null);
  const pointsRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ x: 0, y: 0 });

  useEffect(() => {
    setCanvasSize({ x: contentSize.x + 2 * canvasMargin, y: contentSize.y + 2 * canvasMargin });
  }, [contentSize]);

  useEffect(() => { setPoints(startingPoints); }, [startingPoints]);
  useEffect(() => {
    if (state === CANVAS_STATES.OFF) setPoints([]);
  }, [state, startingPoints]);

  const drawCanvas = useCallback(() => {
    const context = canvasRef.current.getContext('2d');
    context.fillStyle = color;
    context.lineWidth = lineWidth;
    context.strokeStyle = color;
    context.clearRect(0, 0, canvasSize.x, canvasSize.y);

    // Area mask
    if (maskOutside && points.length >= 3) maskOutsidePolygon(context, canvasSize, points);

    // Lines
    points.forEach((_, index) => {
      const p1 = points[index];
      const p2 = points[index < points.length - 1 ? index + 1 : 0];
      if (lineStyle === CANVAS_LINES.INFINITE) drawLine(context, p1, p2, canvasSize);
      else if (lineStyle === CANVAS_LINES.SEGMENTS) drawSegment(context, p1, p2);
    });

    // Points
    points.forEach((point) => drawPoint(context, point));
  }, [canvasSize, color, lineStyle, points, maskOutside]);

  useEffect(() => drawCanvas(), [drawCanvas]);

  const mouseDownPoint = useCallback((event) => {
    if (!canvasRef.current) return;
    const mouse = getMousePosition(canvasRef.current, event);
    const clampedMouse = clampPointToSize(mouse, contentSize);
    setPoints((currentState) => [...currentState, clampedMouse]);
  }, [contentSize]);

  const mouseDownDrag = useCallback((event) => {
    if (!canvasRef.current) return;
    const mouse = getMousePosition(canvasRef.current, event);
    const movedPoint = findClosePoint(mouse, pointsRef.current);
    if (movedPoint >= 0) {
      dragging.point = movedPoint;
      dragging.from = offset(mouse, pointsRef.current[movedPoint]);
    }
  }, [pointsRef]);

  const mouseUp = () => { dragging.point = -1; };

  const drag = useCallback((event) => {
    if (!canvasRef.current) return;
    if (dragging.point < 0) return;
    const newPoints = [...pointsRef.current];
    const mouse = getMousePosition(canvasRef.current, event);
    const clampedMouse = clampPointToSize(mouse, contentSize);
    newPoints[dragging.point].x = clampedMouse.x + dragging.offset.x;
    newPoints[dragging.point].y = clampedMouse.y + dragging.offset.y;
    pointsRef.current = newPoints;
    drawCanvas();
  }, [drawCanvas, contentSize]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (state === CANVAS_STATES.ADDING && points.length < maxPoints) {
      pointsRef.current = points;
      canvasRef.current.addEventListener('mousedown', mouseDownPoint);
    } else if (state === CANVAS_STATES.MOVING) {
      canvasRef.current.addEventListener('mousedown', mouseDownDrag);
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('mousemove', drag);
    }
    return () => {
      canvas.removeEventListener('mousedown', mouseDownPoint);
    };
  }, [points, maxPoints, state, mouseDownDrag, mouseDownPoint, drag]);

  useEffect(() => {
    if (points.length >= minPoints) {
      pointsRef.current = points;
      returnCanMove();
    }
  }, [points, minPoints, returnCanMove]);

  useEffect(
    () => {
      if (returnPoints) {
        returnPoints(pointsRef.current.map(
          (p) => ({ x: p.x - canvasMargin, y: p.y - canvasMargin }),
        ));
      }
    }, [returnPoints],
  );

  return (
    <div className={styles.container} style={{ width: canvasSize.x, height: canvasSize.y }}>
      <div style={{ width: contentSize.x, height: contentSize.y }}>{children}</div>
      <canvas
        ref={canvasRef}
        width={canvasSize.x}
        height={canvasSize.y}
        style={{ width: canvasSize.x, height: canvasSize.y }}
      />
    </div>
  );
};

PolygonCanvas.propTypes = {
  returnPoints: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  returnCanMove: PropTypes.func.isRequired,
  state: PropTypes.oneOf(Object.values(CANVAS_STATES)).isRequired,
  startingPoints: PropTypes.array,
  config: PropTypes.shape({
    size: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired,
    minPoints: PropTypes.number.isRequired,
    maxPoints: PropTypes.number.isRequired,
    lineStyle: PropTypes.oneOf(Object.values(CANVAS_LINES)).isRequired,
    color: PropTypes.string.isRequired,
    maskOutside: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.any,
};

PolygonCanvas.defaultProps = {
  startingPoints: [],
  returnPoints: () => {},
  children: (<div />),
};

export { PolygonCanvas, CANVAS_STATES, CANVAS_LINES };
