import React from 'react';
import PropTypes from 'prop-types';

import styles from './textInput.module.scss';

const TextInput = (props) => {
  const {
    type, password, error, className, containerClassName, fat, ...native
  } = props;
  let inputType = type;
  if (password) inputType = 'password';
  return (
    <div className={[styles.container, containerClassName].join(' ')}>
      <input
        type={inputType}
        className={[error ? styles.textInputError : styles.textInput, className].join(' ')}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...native}
        fat={fat ? 'true' : 'false'}
      />
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  password: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  fat: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'text',
  password: false,
  error: '',
  className: '',
  containerClassName: '',
  fat: false,
};

export { TextInput };
