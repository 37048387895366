const LOADING_CONFIG = {
  FALSE: 0,
  ACCOUNT: 1,
  PROCESSOR: 2,
  BLANK: 3,
};

const LOADING_MESSAGES = [
  '',
  'Loading cloud configuration...',
  'Loading processor configuration...',
];

export { LOADING_CONFIG, LOADING_MESSAGES };
