import React from 'react';

import { routeConfig } from 'route-components';
import { Router } from './routes';

import 'index.scss';

const App = () => (
  <div id="app">
    <div id="modal-root" />
    <Router routeConfig={routeConfig} />
  </div>
);

export { App };
