import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Timeframe, TIMEFRAME_TYPES } from 'models/timeframe';
import { ReportsController } from 'networking/controllers/reports-controller';
import { METRICS } from 'networking/processor-routes';
import { errorMessage } from 'helpers/error-helper';

import { Modal } from 'common/modal';
import { ModalAlert } from 'common/modalAlert';
import { Checkbox } from 'common/checkbox';
import { BUTTON_COLORS, MainButton } from 'common/buttons';
import { TimeframePicker } from 'common/timeframePicker';

import camerasIcon from 'assets/images/icons-camera.svg';
import areasIcon from 'assets/images/icons-areas.svg';
import styles from './exportDataModal.module.scss';

const ExportDataModal = ({
  dismiss, areas, cameras, processorMetrics,
}) => {
  const [dateRange, setDateRange] = useState(new Timeframe({ type: TIMEFRAME_TYPES.DAILY }));
  const [errorText, setErrorText] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCheckbox = (selected, setSelected, allSelected, id) => {
    setErrorText(null);
    if (id) {
      const index = selected.findIndex((m) => m.id === id);
      const newSelected = [...selected];
      newSelected[index] = {
        ...selected[index],
        selected: !selected[index].selected,
      };
      return setSelected(newSelected);
    }
    return setSelected([...selected].map((m) => ({ ...m, selected: !allSelected })));
  };

  // Area list
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [allAreasSelected, setAllAreasSelected] = useState(false);
  useEffect(() => {
    setSelectedAreas(areas.map((area) => (
      { id: area.id, areaName: area.areaName, selected: false }
    )));
  }, [areas]);
  useEffect(
    () => setAllAreasSelected(!selectedAreas.find((a) => !a.selected)),
    [selectedAreas],
  );
  const handleAreaCheckbox = (area) => handleCheckbox(
    selectedAreas, setSelectedAreas, allAreasSelected, area,
  );

  // Camera list
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [allCamerasSelected, setAllCamerasSelected] = useState(false);
  useEffect(() => {
    setSelectedCameras(cameras.map((camera) => (
      { id: camera.id, cameraName: camera.cameraName, selected: false }
    )));
  }, [cameras]);
  useEffect(
    () => setAllCamerasSelected(!selectedCameras.find((c) => !c.selected)),
    [selectedCameras],
  );
  const handleCameraCheckbox = (camera) => handleCheckbox(
    selectedCameras, setSelectedCameras, allCamerasSelected, camera,
  );

  // Metrcic list
  const [selectedMetrics, setSelectedMetrics] = useState([
    { id: METRICS.OCCUPANCY, name: 'Occupancy', selected: false },
    { id: METRICS.SOCIAL_DST, name: 'Social Distancing', selected: false },
    { id: METRICS.FACEMASK, name: 'Facemask', selected: false },
    { id: METRICS.IN_OUT, name: 'In/Out', selected: false },
  ]);
  const [allMetricsSelected, setAllMetricsSelected] = useState(false);
  useEffect(
    () => setAllMetricsSelected(!selectedMetrics.find((m) => !m.selected)),
    [selectedMetrics],
  );
  const handleMetricCheckbox = (metric) => handleCheckbox(
    selectedMetrics, setSelectedMetrics, allMetricsSelected, metric,
  );

  const download = async () => {
    if (selectedAreas.filter((a) => a.selected).length < 1
      && selectedCameras.filter((c) => c.selected).length < 1) {
      return setErrorText('Please select at least one area or camera.');
    }
    if (selectedMetrics.filter((m) => m.selected).length < 1) {
      return setErrorText('Please select at least one metric.');
    }
    setLoading(true);
    const { errorCode } = await ReportsController.downloadReports(
      allAreasSelected || selectedAreas.filter((a) => a.selected).map((a) => a.id),
      allCamerasSelected || selectedCameras.filter((c) => c.selected).map((c) => c.id),
      allMetricsSelected || selectedMetrics.filter((m) => m.selected).map((m) => m.id),
      dateRange,
    );
    setLoading(false);
    if (errorCode) return setErrorText(errorMessage(errorCode));
    return dismiss();
  };

  return (
    <Modal dismiss={dismiss}>
      <ModalAlert
        dismiss={dismiss}
        buttons={[
          <MainButton text="Cancel" onClick={dismiss} />,
          <MainButton text="Export" onClick={download} loading={loading} color={BUTTON_COLORS.TWILIGHT_BLUE} />,
        ]}
        errorText={errorText}
      >
        <h2 className={styles.title}>Export Raw Data</h2>
        <div className={styles.content}>
          <h3>Choose date range:</h3>
          <TimeframePicker
            timeframe={dateRange}
            setTimeframe={setDateRange}
            className={styles.dateRangePicker}
          />
          <h3>Choose data source:</h3>
          <div className={styles.h4}>
            <img alt="areas" src={areasIcon} />
            <h4>Areas</h4>
          </div>
          <div className={styles.areaList}>
            {[
              (
                <Checkbox
                  key="areaAll"
                  value="areaAll"
                  id="areaAll"
                  checked={allAreasSelected}
                  onClick={() => handleAreaCheckbox()}
                  text="All Areas"
                  label="after"
                  labelClassName={styles.label}
                />
              ),
              ...selectedAreas.map((area) => (
                <Checkbox
                  key={`area${area.id}`}
                  value={`area${area.id}`}
                  id={`area${area.id}`}
                  checked={area.selected}
                  onClick={() => handleAreaCheckbox(area.id)}
                  text={area.areaName}
                  label="after"
                  labelClassName={styles.label}
                />
              )),
            ]}
          </div>
          <div className={styles.h4}>
            <img alt="camera" src={camerasIcon} />
            <h4>Cameras</h4>
          </div>
          <div className={styles.cameraList}>
            {[
              (
                <Checkbox
                  key="camAll"
                  value="camAll"
                  id="camAll"
                  checked={allCamerasSelected}
                  onClick={() => handleCameraCheckbox()}
                  text="All Cameras"
                  label="after"
                  labelClassName={styles.label}
                />
              ),
              ...selectedCameras.map((camera) => (
                <Checkbox
                  key={`cam${camera.id}`}
                  value={`cam${camera.id}`}
                  id={`cam${camera.id}`}
                  checked={camera.selected}
                  onClick={() => handleCameraCheckbox(camera.id)}
                  text={camera.cameraName}
                  label="after"
                  labelClassName={styles.label}
                />
              )),
            ]}
          </div>
          <h3>Choose data type:</h3>
          <div className={styles.metricList}>
            {[
              (
                <Checkbox
                  key="metricAll"
                  value="metricAll"
                  id="metricAll"
                  checked={allMetricsSelected}
                  onClick={() => handleMetricCheckbox()}
                  text="All Metrics"
                  label="after"
                  labelClassName={styles.label}
                />
              ),
              selectedMetrics.map((metric) => (processorMetrics[metric.id]
                ? (
                  <Checkbox
                    key={metric.name}
                    value={metric.name}
                    id={metric.name}
                    checked={metric.selected}
                    onClick={() => handleMetricCheckbox(metric.id)}
                    text={metric.name}
                    label="after"
                    labelClassName={styles.label}
                  />
                ) : null
              )),
            ]}
          </div>
        </div>
      </ModalAlert>
    </Modal>
  );
};

ExportDataModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  cameras: PropTypes.array.isRequired,
  processorMetrics: PropTypes.shape({
    [METRICS.OCCUPANCY]: PropTypes.bool.isRequired,
    [METRICS.IN_OUT]: PropTypes.bool.isRequired,
    [METRICS.FACEMASK]: PropTypes.bool.isRequired,
    [METRICS.SOCIAL_DST]: PropTypes.bool.isRequired,
  }).isRequired,
};

export { ExportDataModal };
