class Area {
  constructor(params) {
    this.violation_threshold = params.violation_threshold;
    this.notify_every_minutes = params.notify_every_minutes;
    this.daily_report = params.daily_report;
    this.daily_report_time = params.daily_report_time;
    this.areaCapacity = params.areaCapacity;
    this.id = params.id;
    this.areaName = params.areaName;
    this.cameras = params.cameras;
    this.emails = params.emails;
    this.slackNotifications = params.slackNotifications;
    this.occupancyRules = params.occupancyRules;
  }
}

export { Area };
