import { ProcessorService } from 'networking/processor-service';
import {
  METRICS, METRIC_TIMEFRAMES, METRIC_TARGETS, PROCESSOR_ROUTES,
} from 'networking/processor-routes';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { GlobalReportsSerializer } from 'networking/serializers/globalReports-serializer';
import { fileDownload } from 'helpers/file-download-helper';
import {
  FacemaskStatsSerializer,
  OccupancyStatsSerializer,
  SocialDistancingStatsSerializer,
} from 'networking/serializers/stats-serializers';
import { processTimeframe } from 'helpers/timeframe-helper';
import { ReportQuerySerializer } from 'networking/serializers/report-query-serializer';
import { InOutStatsSerializer } from 'networking/serializers/stats-serializers/in-out-serializer';

class ReportsController {
  static async downloadReports(areas, cameras, metrics, timerange) {
    const serializedReportParameters = ReportQuerySerializer.serialize(
      areas, cameras, metrics, timerange,
    );
    try {
      const response = await ProcessorService.put(
        PROCESSOR_ROUTES.DOWNLOAD_REPORTS, serializedReportParameters, { responseType: 'blob' },
      );
      fileDownload(response.data, 'report.zip');
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
    return { errorCode: false };
  }

  static async getLiveFacemaskStats(areas, ids) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.FACEMASK, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], METRIC_TIMEFRAMES.LIVE,
      ), body);
      return { errorCode: false, ...FacemaskStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getHistoricFacemaskData(areas, ids, timeframe) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    const { requestTimeframe, timeframeQueryParams } = processTimeframe(timeframe);
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.FACEMASK, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], requestTimeframe,
      ), { ...body, ...timeframeQueryParams });
      return { errorCode: false, ...FacemaskStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getLiveSocialDistancingStats(areas, ids) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.SOCIAL_DST, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], METRIC_TIMEFRAMES.LIVE,
      ), body);
      return { errorCode: false, ...SocialDistancingStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getHistoricSocialDistancingData(areas, ids, timeframe) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    const { requestTimeframe, timeframeQueryParams } = processTimeframe(timeframe);
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.SOCIAL_DST, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], requestTimeframe,
      ), { ...body, ...timeframeQueryParams });
      return { errorCode: false, ...SocialDistancingStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getLiveOccupancyStats(ids) {
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.OCCUPANCY, METRIC_TARGETS.AREAS, METRIC_TIMEFRAMES.LIVE,
      ), { areas: ids.join(',') });
      return { errorCode: false, ...OccupancyStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getHistoricOccupancyData(ids, timeframe) {
    const { requestTimeframe, timeframeQueryParams } = processTimeframe(timeframe);
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.OCCUPANCY, METRIC_TARGETS.AREAS, requestTimeframe,
      ), { ...timeframeQueryParams, areas: ids.join(',') });
      return { errorCode: false, ...OccupancyStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getLiveInOutStats(areas, ids) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.IN_OUT, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], METRIC_TIMEFRAMES.LIVE,
      ), { ...body });
      return { errorCode: false, ...InOutStatsSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getHistoricInOutData(areas, ids, timeframe) {
    const body = areas ? { areas: ids.join(',') } : { cameras: ids.join(',') };
    const { requestTimeframe, timeframeQueryParams } = processTimeframe(timeframe);
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.METRIC(
        METRICS.IN_OUT, METRIC_TARGETS[areas ? 'AREAS' : 'CAMERAS'], requestTimeframe,
      ), { ...timeframeQueryParams, ...body });
      const hue = { errorCode: false, ...InOutStatsSerializer.deSerialize(response.data) };
      return hue;
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getGlobalReports() {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.GLOBAL_REPORTS);
      const deSerializedGlobalReport = GlobalReportsSerializer.deSerialize(response.data);
      return { errorCode: false, globalReport: deSerializedGlobalReport };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async updateGlobalReports(emails, daily, weekly) {
    const serializedGlobalReports = GlobalReportsSerializer.serialize(emails, daily, weekly);
    try {
      const response = await ProcessorService
        .put(`${PROCESSOR_ROUTES.GLOBAL_REPORTS}`, serializedGlobalReports);
      return response;
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }
}

export { ReportsController };
