import React, { Fragment } from 'react';
import { PropTypes } from 'prop-types';

import styles from './segmentedControl.module.scss';

const SegmentedControl = ({ elements, className }) => (
  <div className={`${styles.container} ${className}`}>
    {
      elements.map((element, index) => {
        const buttonClassname = element.selected ? styles.selected : styles.unselected;
        const separatorClassname = (
          index === elements.length - 1 || element.selected || elements[index + 1].selected
        ) ? styles.invisibleSeparator : styles.separator;
        return (
          <Fragment key={element.title}>
            <button
              type="button"
              className={buttonClassname}
              onClick={element.onClick}
            >
              {element.title}
            </button>
            <div className={separatorClassname} />
          </Fragment>
        );
      })
    }
  </div>
);

SegmentedControl.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  })).isRequired,
  className: PropTypes.string,
};

SegmentedControl.defaultProps = {
  className: '',
};

export { SegmentedControl };