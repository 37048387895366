import React from 'react';
import { constants } from 'config/constants';
import { LoginCard } from 'common/loginCard';
import styles from './registerEmailSent.module.scss';

const RegisterEmailSent = () => (
  <LoginCard>
    <div className={styles.content}>
      <h3 className={styles.title}>Great! You’ve successfully joined Lanthorn.</h3>

      <p>
        We’ve emailed you a link to confirm your account.
        <br />
        Please check your inbox.
        <br />
        Haven’t received an email? Please check your spam folder to make sure it’s
        not there.
        <br />
        If our email is nowhere to be found please contact us at
        {' '}
        <a href={`mailto:${constants.lanthornEmail}`}>{constants.lanthornEmail}</a>
        &nbsp;for help.
      </p>
    </div>
  </LoginCard>
);

export { RegisterEmailSent };
