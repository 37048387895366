import React from 'react';
import PropTypes from 'prop-types';
import styles from './loader.module.scss';

const Loader = ({ className }) => <div className={`${styles.loader} ${className}`} />;

Loader.defaultProps = {
  className: '',
};

Loader.propTypes = {
  className: PropTypes.string,
};

export { Loader };
