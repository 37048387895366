import React from 'react';
import { PropTypes } from 'prop-types';

import { constants } from 'config/constants';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { CameraController } from 'networking/controllers/camera-controller';
import { AreaController } from 'networking/controllers/area-controller';

import { Loader } from 'common/loader';

import { SessionController } from 'networking/controllers/session-controller';
import { ModalAlert } from 'common/modalAlert';
import { Modal } from 'common/modal';
import { BUTTON_COLORS, MainButton } from 'common/buttons';
import { loadRefreshToken, loadToken } from 'helpers/cookie-helper';
import { goToPage, routeNaming } from 'routes';

import { LOADING_CONFIG, LOADING_MESSAGES } from './constants';

import styles from './with-config.module.scss';

class DemoConfigProvider extends React.Component {
  constructor(props) {
    super(props);
    if (loadToken() || loadRefreshToken()) goToPage(routeNaming.DASHBOARD);
    this.state = {
      processorInfo: {},
      cameras: [],
      areas: [],
      loading: LOADING_CONFIG.BLANK,
      showModal: false,
    };
  }

  async componentDidMount() {
    SessionController.expire();
    this.setState({ loading: LOADING_CONFIG.ACCOUNT });
    ProcessorController.updateProcessorURL(constants.demoProcessorURL);
    ProcessorController.updateProcessorToken('');
    this.setState({ loading: LOADING_CONFIG.PROCESSOR });
    const {
      processorInfo, errorCode: processorInfoError,
    } = await ProcessorController.getProcessorInfo();
    const { cameras, errorCode: cameraError } = await CameraController.getCameras();
    const { areas, errorCode: areaError } = await AreaController.getAreas();
    if (cameraError || areaError || processorInfoError) {
      return this.setState({ showModal: true, loading: LOADING_CONFIG.BLANK });
    }
    return this.setState({
      loading: LOADING_CONFIG.FALSE, processorInfo, cameras, areas,
    });
  }

  dismissModal = () => this.setState({ showModal: false });

  render() {
    const {
      processorInfo, cameras, areas, loading, showModal,
    } = this.state;
    const { render } = this.props;
    const config = { processorInfo, cameras, areas };

    const loadingScreen = (
      <div className={styles.background}>
        <Loader className={styles.loader} />
        {LOADING_MESSAGES[loading]}
      </div>
    );

    const modalAlert = showModal && (
      <Modal dismiss={this.dismissModal}>
        <ModalAlert
          text={['The demo dashboard is unavailable at the moment.', 'Please refresh the page or try again later.']}
          buttons={[<MainButton text="Accept" type="submit" form="decrypt-form" color={BUTTON_COLORS.SKY} />]}
        />
      </Modal>
    );

    return (
      <>
        {showModal ? modalAlert : null}
        {loading ? loadingScreen : render(config)}
      </>
    );
  }
}

DemoConfigProvider.propTypes = {
  render: PropTypes.func.isRequired,
};

export { DemoConfigProvider };
