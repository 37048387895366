import { ProcessorService } from 'networking/processor-service';
import { PROCESSOR_ROUTES } from 'networking/processor-routes';
import { logger } from 'helpers/logger';
import { ERRORS, setCustomMessage } from 'helpers/error-helper';
import { ProcessorInfoSerializer } from 'networking/serializers/processorInfo-serializer';
import { ProcessorInfo } from 'models/processorInfo';
import {
  deleteProcessorToken, deleteProcessorURL, storeProcessorToken, storeProcessorURL,
} from 'helpers/cookie-helper';

class ProcessorController {
  static async updateProcessorURL(url) {
    if (typeof url === 'string') storeProcessorURL(url);
    else deleteProcessorURL();
    ProcessorService.updateBaseURL();
  }

  static async updateProcessorToken(token) {
    if (typeof token === 'string') storeProcessorToken(token);
    else deleteProcessorToken();
    ProcessorService.updateToken();
  }

  static async getProcessorInfo() {
    try {
      const { data } = await ProcessorService.get(PROCESSOR_ROUTES.INFO);
      const deSerializedProcessorInfo = ProcessorInfoSerializer.deSerialize(data);
      return { processorInfo: new ProcessorInfo(deSerializedProcessorInfo), errorCode: false };
    } catch (error) {
      return this.handleProcessorError(error);
    }
  }

  static async getToken(user, password) {
    try {
      const { data: { token } } = await ProcessorService
        .put(PROCESSOR_ROUTES.GET_TOKEN, { user, password });
      return { token, errorCode: false };
    } catch (error) {
      return this.handleProcessorError(error);
    }
  }

  static async isSlackConfigured() {
    try {
      const { data: { enabled } } = await ProcessorService.get(PROCESSOR_ROUTES.SLACK_ENABLED);
      return { enabled, error: false };
    } catch (error) {
      return this.handleProcessorError(error);
    }
  }

  static async changeSlackChannel(channel) {
    try {
      return await ProcessorService.post(`${PROCESSOR_ROUTES.SLACK_CHANNEL}?channel=${channel}`);
    } catch (error) {
      return this.handleProcessorError(error);
    }
  }

  static async sendUserTokenToProcessor(accessToken, channelParam) {
    try {
      return await ProcessorService
        .post(PROCESSOR_ROUTES.SLACK_ACTIVATE, { user_token: accessToken, channel: channelParam });
    } catch (error) {
      return this.handleProcessorError(error);
    }
  }

  static async handleProcessorError(error, customErrorHandler = () => ({})) {
    logger.error(error);
    if (error.code === ERRORS.PROCESSOR.NO_URL) return { errorCode: ERRORS.PROCESSOR.NO_URL };
    if (error.code === ERRORS.PROCESSOR.NO_TOKEN) return { errorCode: ERRORS.PROCESSOR.NO_TOKEN };
    if (error instanceof TypeError) return { errorCode: ERRORS.LOCAL.BAD_DATA };
    if (!error.status) return { errorCode: ERRORS.PROCESSOR.CANT_REACH };
    const { errorCode: customError } = customErrorHandler(error);
    if (customError) return { errorCode: customError };
    if (error.status === 400) return { errorCode: ERRORS.PROCESSOR.BAD_REQUEST };
    if (error.status === 401) return { errorCode: ERRORS.PROCESSOR.UNAUTHORIZED };
    if (error.status === 404) return { errorCode: ERRORS.PROCESSOR.NO_CONFIG };
    if (error.message || error.detail) {
      if (error.message) setCustomMessage(error.message);
      else setCustomMessage(error.detail);
      return { obj: error.body, errorCode: ERRORS.PROCESSOR.CUSTOM_MESSAGE };
    }
    return { errorCode: ERRORS.PROCESSOR.UNEXPECTED };
  }
}

export { ProcessorController };
