import React, { useEffect, useState } from 'react';
import { withAuthentication } from 'hocs/with-authentication';
import { LAYOUT_TYPES, withLayout } from 'hocs/with-layout';
import { UserController } from 'networking/controllers/user-controller';
import { SettingsController } from 'networking/controllers/settings-controller';
import { ERRORS } from 'helpers/error-helper';

import { RegisterForm } from 'common/registerForm';

import spinner from 'assets/images/loading-blackBG.gif';

import styles from './profile.module.scss';

const Profile = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { user: response, errorCode: error } = await UserController.getMe();
      if (!error) setUser(response);
    };
    fetchUser();
  }, []);

  const saveUser = async (editedUser) => {
    const passwordErrorMessage = 'Wrong current password.';
    const saveErrorMessage = 'We couldn\'t update your configuration. Please refresh the page or try again later.';
    const configErrorMessage = 'We couldn\'t load your processor configuration before sending it. Please refresh the page or try again later.';
    const decryptionErrorMessage = 'We couldn\'t decrypt your configuration. You can try relogging and resetting your configuration in the settings page.';
    const encryptionErrorMessage = 'We couldn\'t encrypt your configuration with you new password. You can try relogging and resetting your configuration in the settings page.';
    if (editedUser.password) {
      // Check validity of old password
      const oldPassword = editedUser.currentPassword;
      const { valid, errorCode: passwordError } = await UserController.validatePassword(
        oldPassword,
      );
      if (passwordError || !valid) {
        return { error: ERRORS.API.UNAUTHORIZED, message: passwordErrorMessage };
      }

      // Get stored settings from account
      const {
        data: { encryptedURL, encryptedUsername, encryptedPassword } = {}, errorCode: configError,
      } = await SettingsController.requestProcessorData();
      if (configError) return { error: configError, message: configErrorMessage };

      // Decrypt stored settings
      const {
        data: { processorURL, processorUsername, processorPassword }, errorCode: decryptionError,
      } = await SettingsController.decryptData(
        oldPassword, encryptedURL, encryptedUsername, encryptedPassword,
      );
      if (decryptionError) return { error: decryptionError, message: decryptionErrorMessage };

      const newPassword = editedUser.password;
      const {
        data: encryptedData, errorCode: encryptionError,
      } = await SettingsController.encryptData(
        newPassword, processorURL, processorUsername, processorPassword,
      );
      if (encryptionError) return { error: encryptionError, message: encryptionErrorMessage };

      // Send encrypted data
      const {
        errorCode: sendDataError,
      } = await SettingsController.sendEncryptedData(encryptedData);
      if (sendDataError) return { error: sendDataError, message: saveErrorMessage };
    }
    const { errorCode: editError } = await UserController.editUser(editedUser);
    if (editError) return { error: editError, message: saveErrorMessage };
    return { error: false };
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.sectionTitle}>Profile</h2>
      {user
        ? (<RegisterForm existingUser={user} saveUser={saveUser} />)
        : <img src={spinner} alt="loading" />}
    </div>
  );
};

const WrappedProfile = withAuthentication(withLayout(
  LAYOUT_TYPES.MAIN, Profile,
));

export { WrappedProfile as Profile };
