import React from 'react';
import ReactGA from 'react-ga';

import { UserController } from 'networking/controllers/user-controller';
import { goToPage, routeNaming } from 'routes';

import { LoginCard } from 'common/loginCard';
import { RegisterForm } from 'common/registerForm';

const Register = () => {
  const saveUser = async (user) => {
    const { errorCode: error } = await UserController.saveUser(user);
    if (error) return { error };
    ReactGA.event({
      category: 'Register',
      action: 'User register',
      label: user.email,
    });
    return goToPage(routeNaming.REGISTER_EMAIL_SENT);
  };

  return (
    <LoginCard>
      <RegisterForm saveUser={saveUser} />
    </LoginCard>
  );
};

export { Register };
