const fetchVariable = (varName, defaultValue) => {
  if (!process.env[varName] && typeof defaultValue === 'undefined') {
    throw new Error(`Mandatory environment variable ${varName} is not set.`);
  }
  return process.env[varName] || defaultValue;
};

const nodeEnv = fetchVariable('NODE_ENV');
const environment = {
  isDevelopment: nodeEnv === 'development',
  isProduction: nodeEnv === 'production',
};

export const constants = {
  apiBaseURL: fetchVariable('REACT_APP_API_BASE_URL'),
  demoProcessorURL: fetchVariable('REACT_APP_DEMO_PROCESSOR_URL'),
  slackClientId: fetchVariable('REACT_APP_SLACK_CLIENT'),
  slackScopes: fetchVariable('REACT_APP_SLACK_SCOPES'),
  redirectUrl: fetchVariable('REACT_APP_REDIRECT_URL'),
  googleAnalyticsId: fetchVariable('REACT_APP_GOOGLE_ANALYTICS_ID', null),
  lanthornEmail: 'hello@Lanthorn.ai',
  environment,
  METRICS: {
    SOCIAL_DST: 'social-distancing',
    FACEMASK: 'facemask',
    OCCUPANCY: 'occupancy',
    IN_OUT: 'in-out',
  },
};
