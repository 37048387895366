import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'chart.js';

import styles from './chart.module.scss';

const ReactChart = ({
  type, data, options, className,
}) => {
  const canvas = useRef(null);
  const chart = useRef(null);

  const createGraph = () => {
    if (chart.current) chart.current.destroy();
    chart.current = new Chart(canvas.current, { type, data, options });
  };

  useEffect(createGraph, [type]);
  useEffect(() => {
    chart.current.data = data;
    chart.current.options = options;
    chart.current.update();
  }, [data, options]);

  return (<div className={`${styles.container}${className}`}><canvas ref={canvas} /></div>);
};

ReactChart.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ReactChart.defaultProps = {
  className: '',
};

export { ReactChart as Chart };
