import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ModalBox } from 'common/modalBox';

import styles from './modalAlert.module.scss';

const ModalAlert = ({
  text, buttons, children, dismiss, errorText,
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ModalBox dismiss={dismiss}>
    {text.map((line) => (<p className={styles.text} key={line}>{line}</p>))}
    {children}
    <span className={styles.errorText}>{errorText || '\u200b'}</span>
    <div className={styles.buttonBox}>
      {buttons.map((b) => (<Fragment key={b.props.text}>{b}</Fragment>))}
    </div>
  </ModalBox>
);

ModalAlert.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.element),
  text: PropTypes.arrayOf(PropTypes.string),
  dismiss: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  errorText: PropTypes.string,
};

ModalAlert.defaultProps = {
  buttons: [],
  text: [],
  children: null,
  errorText: '',
};

export { ModalAlert };
