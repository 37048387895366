import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { TextInput } from 'common/textInput';
import { SettingsController } from 'networking/controllers/settings-controller';
import { Toggle } from 'common/toggle';
import { loadProcessorToken, loadProcessorURL } from 'helpers/cookie-helper';
import { METRICS } from 'networking/processor-routes';
import {
  MODAL_ERRORS,
  UnexpectedErrorDialogue, DataErrorModal, DecryptDialogue,
  EncryptDialogue, HasBeenConfiguredDialogue,
} from 'common/modalErrors';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { errorMessage, ERRORS } from 'helpers/error-helper';
import { Modal } from 'common/modal';
import { validURL } from 'helpers/uri-helper';
import { UserController } from 'networking/controllers/user-controller';
import { goToPage, routeNaming } from 'routes';
import { AddButton } from '../setupButtons';
import { SETUP_STEPS } from '../setupSteps';
import styles from './connectProcessor.module.scss';

const ConnectProcessor = ({ setSetupStep }) => {
  const [serverPath, setServerPath] = useState('');
  const [useCredentials, setUseCredentials] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [facemask, setFacemask] = useState('❔');
  const [inOut, setInOut] = useState('❔');
  const [occupancy, setOccupancy] = useState('❔');
  const [socialDistancing, setSocialDistancing] = useState('❔');
  const [showCapabilities, setShowCapabilities] = useState(false);

  const [canSave, setCanSave] = useState(false);
  const [configured, setConfigured] = useState(false);
  const [modal, setModal] = useState(MODAL_ERRORS.FALSE);
  const [incomingEncryptedData, setIncomingEncryptedData] = useState({});
  const [outgoingDecryptedData, setOutgoingDecryptedData] = useState({});

  const setAndCheckServerPath = (newPath) => {
    setServerPath(newPath);
    setCanSave(validURL(newPath));
  };

  // -------------------------------------- SEND DATA ------------------------------------------ //

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const newProcessorURL = serverPath.replace(/[/]$/, '');
    const newProcessorUsername = username;
    const newProcessorPassword = password;

    if (!validURL(newProcessorURL)) return setError('Invalid URL.');

    if (newProcessorURL === loadProcessorURL() && !useCredentials) {
      const tokenBackup = loadProcessorToken();
      let artificialToken = false;
      if (!loadProcessorToken()) {
        ProcessorController.updateProcessorToken('');
        artificialToken = true;
      }
      const { errorCode: infoError } = await ProcessorController.getProcessorInfo();
      if (infoError) {
        // Delete if new empty token, keep old token just in case
        if (artificialToken) ProcessorController.updateProcessorToken(tokenBackup);

        if (infoError === ERRORS.PROCESSOR.UNAUTHORIZED) return setError(errorMessage(infoError));

        // Can't connect/unexpected error. TODO: Modal to ask for confirmation?
        if (infoError === ERRORS.PROCESSOR.CANT_REACH) {
          return setError('We can\'t contact your processor. Please check your URL.');
        }
        return setModal(MODAL_ERRORS.UNEXPECTED);
      }
      // Old credentials work/credentials aren't needed. Can continue setup without encrypting data.
      return setSetupStep(SETUP_STEPS.CAMERAS);
    }

    // Check if URL and credentials are correct
    const urlBackup = loadProcessorURL();
    const tokenBackup = loadProcessorToken();
    let newToken = '';
    if (useCredentials) {
      // With credentials
      if (!newProcessorUsername || !newProcessorPassword) return setError('Missing credentials.');

      ProcessorController.updateProcessorURL(newProcessorURL);
      ProcessorController.updateProcessorToken('');
      const { token, errorCode: credentialsError } = await ProcessorController.getToken(
        newProcessorUsername, newProcessorPassword,
      );
      ProcessorController.updateProcessorURL(urlBackup);
      ProcessorController.updateProcessorToken(tokenBackup);

      if (credentialsError) {
        if (credentialsError === ERRORS.PROCESSOR.UNAUTHORIZED) {
          return setError(errorMessage(credentialsError));
        }
        if (credentialsError === ERRORS.PROCESSOR.CANT_REACH) {
          return setError('We can\'t contact your processor. Please check your URL.');
        }
        return setModal(MODAL_ERRORS.UNEXPECTED);
      }

      // Correct credentials
      newToken = token;
    } else {
      // Without credentials
      ProcessorController.updateProcessorURL(newProcessorURL);
      ProcessorController.updateProcessorToken('');
      const { errorCode: infoError } = await ProcessorController.getProcessorInfo();
      ProcessorController.updateProcessorURL(urlBackup);
      ProcessorController.updateProcessorToken(tokenBackup);

      if (infoError) {
        if (infoError === ERRORS.PROCESSOR.UNAUTHORIZED) {
          return setError(errorMessage(infoError));
        }
        if (infoError === ERRORS.PROCESSOR.CANT_REACH) {
          return setError('We can\'t contact your processor. Please check your URL.');
        }
        // Can't connect/unexpected error. TODO: Modal to ask for confirmation?
        return setModal(MODAL_ERRORS.UNEXPECTED);
      }
    }

    // Valid config. Preparing to encrypt it.
    setOutgoingDecryptedData({
      processorURL: newProcessorURL,
      processorUsername: useCredentials && newProcessorUsername,
      processorPassword: useCredentials && newProcessorPassword,
      processorToken: newToken,
    });
    return setModal(MODAL_ERRORS.ENCRYPT);
  };

  const encryptAndSend = async (accountPassword) => {
    // Validate account password.
    const { valid, errorCode: passwordError } = await UserController.validatePassword(
      accountPassword,
    );
    if (passwordError || !valid) return { errorMessage: 'Wrong password.' };

    const {
      processorURL, processorUsername, processorPassword, processorToken,
    } = outgoingDecryptedData;
    const { errorCode: encryptionError } = await SettingsController.encryptAndSend(
      accountPassword, processorURL, processorUsername, processorPassword,
    );

    if (encryptionError) return { errorMessage: errorMessage(encryptionError) };

    // Success
    ProcessorController.updateProcessorURL(processorURL);
    ProcessorController.updateProcessorToken(processorToken);
    return setSetupStep(SETUP_STEPS.CAMERAS);
  };

  // -------------------------------------- SEND DATA ------------------------------------------ //

  // -------------------------------------- LOAD DATA ------------------------------------------ //

  const checkIfConfigured = async () => {
    const { processorInfo, errorCode: infoError } = await ProcessorController.getProcessorInfo();
    if (infoError) return setModal(MODAL_ERRORS.UNEXPECTED);
    if (processorInfo.has_been_configured) {
      return setModal(MODAL_ERRORS.HAS_BEEN_CONFIGURED);
    }
    return {};
  };

  useEffect(() => {
    const loadProcessorData = async () => {
      const localProcessorURL = loadProcessorURL();
      const localProcessorToken = loadProcessorToken();
      const { errorCode } = await ProcessorController.getProcessorInfo();

      // Local processor data works
      if (!errorCode) {
        setConfigured(true);
        setAndCheckServerPath(localProcessorURL);
        return checkIfConfigured();
      }

      // Local processor data doesn't work
      if (localProcessorURL && typeof localProcessorToken === 'string') {
        setConfigured(true);
        return setAndCheckServerPath(localProcessorURL);
        // "cant reach processor, might want to reconfigure"
      }

      // No local processor data
      const {
        data: { encryptedURL, encryptedUsername, encryptedPassword } = {}, errorCode: apiError,
      } = await SettingsController.requestProcessorData();
      if (apiError) {
        // New account with no config
        if (apiError === ERRORS.API.NO_CONFIG) return null;
        // "we couldn't retrieve your configuration"
        return setError(errorMessage(apiError));
      }

      // Decrypt data from API
      setIncomingEncryptedData({ encryptedURL, encryptedUsername, encryptedPassword });
      return setModal(MODAL_ERRORS.DECRYPT);
    };

    loadProcessorData();
  }, []);

  const decrypt = async (accountPassword) => {
    const { encryptedURL, encryptedUsername, encryptedPassword } = incomingEncryptedData;

    // Decrypt data from API
    const {
      data: { processorURL, processorUsername, processorPassword }, errorCode: decryptionError,
    } = await SettingsController.decryptData(
      accountPassword, encryptedURL, encryptedUsername, encryptedPassword,
    );
    if (decryptionError) return { errorMessage: 'Wrong password.' };

    if (!validURL(processorURL)) return setModal(MODAL_ERRORS.BAD_DATA);

    const urlBackup = loadProcessorURL();
    const tokenBackup = loadProcessorToken();
    ProcessorController.updateProcessorURL(processorURL);
    ProcessorController.updateProcessorToken('');
    // This request validates the URL and gives us the access token if necessary
    const { token, errorCode: tokenError } = await ProcessorController.getToken(
      processorUsername, processorPassword,
    );
    ProcessorController.updateProcessorURL(urlBackup);
    ProcessorController.updateProcessorToken(tokenBackup);

    if (tokenError) {
      // Bad URL
      if (tokenError === ERRORS.PROCESSOR.CANT_REACH) {
        setError('Can\'t reach processor');
        return setModal(MODAL_ERRORS.FALSE);
      }

      if (tokenError === ERRORS.PROCESSOR.BAD_REQUEST) {
        // Success, processor authentication isn't enabled
        if (!processorUsername || !processorPassword) {
          ProcessorController.updateProcessorURL(processorURL);
          ProcessorController.updateProcessorToken('');
          setAndCheckServerPath(processorURL);
          return setModal(MODAL_ERRORS.FALSE);
        }
        // Wrong credentials
        return setModal(MODAL_ERRORS.BAD_PROCESSOR_CREDENTIALS);
      }
      return setModal(MODAL_ERRORS.UNEXPECTED);
    }
    // Successful processor authentication
    ProcessorController.updateProcessorURL(processorURL);
    ProcessorController.updateProcessorToken(token);
    setAndCheckServerPath(processorURL);
    setUsername(processorUsername);
    setPassword(processorPassword);
    checkIfConfigured();
    return {};
  };

  const checkCapabilities = async () => {
    const { errorCode, processorInfo } = await ProcessorController.getProcessorInfo();
    if (!errorCode) {
      setShowCapabilities(true);
      setFacemask(processorInfo.metrics[METRICS.FACEMASK] ? '✅' : '❌');
      setOccupancy(processorInfo.metrics[METRICS.OCCUPANCY] ? '✅' : '❌');
      setInOut(processorInfo.metrics[METRICS.IN_OUT] ? '✅' : '❌');
      setSocialDistancing(processorInfo.metrics[METRICS.SOCIAL_DST] ? '✅' : '❌');
    }
  };
  // -------------------------------------- LOAD DATA ------------------------------------------ //

  const dismissModal = () => setModal(MODAL_ERRORS.FALSE);

  const httpWarn = () => {
    if (serverPath.length > 5) {
      if (!/^(https:)/.test(serverPath)) {
        setError('You\'re not using HTTPS. Remember to enable mixed content on the browser or SSL on your processor.');
      }
    }
  };

  return (
    <>
      {modal !== MODAL_ERRORS.FALSE && (
        <Modal dismiss={dismissModal}>
          {modal === MODAL_ERRORS.DECRYPT
            && <DecryptDialogue decrypt={decrypt} />}
          {modal === MODAL_ERRORS.ENCRYPT
            && <EncryptDialogue encrypt={encryptAndSend} />}
          {modal === MODAL_ERRORS.UNEXPECTED
            && <UnexpectedErrorDialogue accept={dismissModal} />}
          {modal === MODAL_ERRORS.BAD_DATA
            && <DataErrorModal retry={() => {}} />}
          {modal === MODAL_ERRORS.HAS_BEEN_CONFIGURED
            && <HasBeenConfiguredDialogue goToDashboard={() => goToPage(routeNaming.DASHBOARD)} />}
        </Modal>
      )}
      <div className={styles.container}>
        <h1 className={styles.title}>
          Connect your processor
        </h1>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h2 className={styles.subtitle}>
            Server Path
          </h2>
          <p className={styles.description}>
            Type the URL or IP of your processor, if you don&apos;t
            know where to find this, please ask your IT team
          </p>
          <TextInput
            name="serverPath"
            placeholder="http://yourdomain:port"
            onChange={(e) => { setAndCheckServerPath(e.target.value); httpWarn(); }}
            value={serverPath}
            required
          />
          {error && (<p>{error}</p>)}
          <div className={styles.checkCapabilitiesDiv}>
            <AddButton type="button" noIcon onClick={() => checkCapabilities()}>CHECK CAPABILITIES</AddButton>
          </div>
          <div className={styles.toggleDiv}>
            <h2 className={styles.loginCredentials}>
              {configured ? 'Edit processor credentials' : 'Processor credentials'}
            </h2>
            <Toggle value={useCredentials} setValue={setUseCredentials} />
          </div>
          <div className={styles.credentialsContainer}>
            <div className={styles.usernameDiv}>
              <h2 className={styles.username}>Username</h2>
              <TextInput
                name="username"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                required
                disabled={!useCredentials}
              />
            </div>
            <div className={styles.passwordDiv}>
              <h2 className={styles.password}>Password</h2>
              <TextInput
                name="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
                disabled={!useCredentials}
                password
              />
            </div>
          </div>
          <h2 className={styles.subtitle}>
            Capabilities
          </h2>
          {showCapabilities && (
            <div className={styles.capabilitiesDiv}>
              <p>
                Facemask:
                {' '}
                {facemask}
              </p>
              <p>
                Occupancy:
                {' '}
                {occupancy}
              </p>
              <p>
                In / Out:
                {' '}
                {inOut}
              </p>
              <p>
                Social Distancing:
                {' '}
                {socialDistancing}
              </p>
            </div>
          )}
          <div className={styles.stepButtonsDiv}>
            <div />
            <MainButton
              className={styles.stepButton}
              type="submit"
              color={BUTTON_COLORS.SKY}
              text="NEXT"
              disabled={!canSave}
            />
          </div>
        </form>
      </div>
    </>
  );
};

ConnectProcessor.propTypes = {
  setSetupStep: PropTypes.func.isRequired,
};

export { ConnectProcessor };
