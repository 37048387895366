import React from 'react';
import ReactGA from 'react-ga';
import { SessionController } from 'networking/controllers/session-controller';
import { routeNaming, goToPage } from 'routes';

import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { TextInput } from 'common/textInput';
import { LoginCard } from 'common/loginCard';
import { SettingsController } from 'networking/controllers/settings-controller';
import { ERRORS, errorMessage } from 'helpers/error-helper';
import { ProcessorController } from 'networking/controllers/processor-controller';

import styles from './login.module.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: false,
      message: '',
      canSend: false,
      loading: false,
    };
    this.login = this.login.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
  }

  async login(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const { email, password } = this.state;
    const { errorCode: error } = await SessionController.login(email, password);
    if (error === ERRORS.API.UNACTIVATED) {
      return goToPage(routeNaming.ACTIVATE_ACCOUNT, {}, { email });
    }
    if (error) {
      return this.setState({
        error,
        message: errorMessage(error),
        loading: false,
      });
    }
    ProcessorController.updateProcessorURL(null);
    ProcessorController.updateProcessorToken(null);
    await SettingsController.requestProcessorData(password);
    ReactGA.event({
      category: 'Login',
      action: 'User logged in',
      label: email,
    });
    return goToPage(routeNaming.DASHBOARD);
  }

  fieldChange(email, password) {
    this.setState({
      email,
      password,
      error: false,
      canSend: email !== '' && password !== '',
    });
  }

  render() {
    const {
      email,
      password,
      error,
      message,
      canSend,
      loading,
    } = this.state;
    return (
      <>
        <LoginCard>
          <h1 className={styles.title}>Sign in</h1>
          <form className={styles.form} onSubmit={this.login}>
            <TextInput
              id="email"
              placeholder="Email"
              onChange={(e) => this.fieldChange(e.target.value, password)}
              value={email}
              className={styles.emailInput}
              fat
            />
            <TextInput
              password
              id="password"
              placeholder="Password"
              onChange={(e) => this.fieldChange(email, e.target.value)}
              value={password}
              style={{ marginTop: '24px' }}
              fat
            />
            {error ? (<p className={styles.errorMessage}>{message}</p>) : null}
            <MainButton text="Login" type="submit" disabled={!canSend} loading={loading} color={BUTTON_COLORS.SKY} wide />
          </form>
          <button
            type="button"
            className={styles.forgotPassword}
            onClick={() => goToPage(routeNaming.FORGOT_PASSWORD)}
          >
            Forgot your password?
          </button>
          <button
            type="button"
            className={`${styles.forgotPassword} ${styles.createAccount}`}
            onClick={() => goToPage(routeNaming.REGISTER)}
          >
            No account? Click here to register
          </button>
        </LoginCard>
      </>
    );
  }
}

export { Login };
