import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './modal.module.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRoot = document.getElementById('modal-root');
    this.modalDiv = document.createElement('div');
    this.modalRoot.appendChild(this.modalDiv);
  }

  render() {
    const { children, dismiss } = this.props;
    return ReactDOM.createPortal(
      (
        <div className={styles.modal}>
          {
            React.Children.map(children, (child) => (
              React.isValidElement(child)
                ? React.cloneElement(child, { dismiss })
                : child
            ))
          }
          <div className={styles.background}>
            <button type="button" onClick={dismiss} onKeyPress={dismiss} className={styles.dismissButton}>Dismiss</button>
          </div>
        </div>
      ),
      this.modalDiv,
    );
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  dismiss: PropTypes.func.isRequired,
};

export { Modal };
