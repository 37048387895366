class Camera {
  constructor(params) {
    this.notificationViolationsThreshold = params.notificationViolationsThreshold;
    this.notificationTimeThreshold = params.notificationTimeThreshold;
    this.notificationPerHour = params.notificationPerHour;
    this.emails = params.emails;
    this.dailyReportTime = params.dailyReportTime;
    this.dailyReport = params.dailyReport;
    this.id = params.id;
    this.cameraName = params.cameraName;
    this.cameraPath = params.cameraPath;
    this.tags = params.tags;
    this.image = params.image;
    this.slackNotifications = params.slackNotifications;
    this.isChecked = params.isChecked;
    this.calibrated = params.calibrated;
    this.liveFeedEnabled = params.liveFeedEnabled;
    this.hasAreaOfInterest = params.hasAreaOfInterest;
    this.hasInOutBoundary = params.hasInOutBoundary;
  }
}

export { Camera };
