import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './mainButton.module.scss';

const BUTTON_COLORS = {
  SKY: 'sky',
  GREY: 'grey',
  WHITE: 'white',
  TWILIGHT_BLUE: 'twilightBlue',
  RED: 'lanthornRed',
};

const MainButton = (props) => {
  const {
    type, className, loading, children, color, text, wide, short, ...native
  } = props;

  // Sets color and size for loading dots based on button text color and height
  const [loaderStyle, setLoaderStyle] = useState({});
  const [dotStyle, setDotStyle] = useState({});
  const button = useRef();
  useEffect(() => {
    const textColor = window.getComputedStyle(button.current).getPropertyValue('color');
    const height = window.getComputedStyle(button.current).getPropertyValue('height');
    setLoaderStyle({
      height: `calc(${height} * 0.25)`,
      width: `calc(${height} * 0.25 * 17 / 5)`,
    });
    setDotStyle({
      backgroundColor: `${textColor}`,
    });
  }, [button]);
  //

  return (
    <button
      ref={button}
      className={[styles.button, className].join(' ')}
      loading={loading ? 'true' : 'false'}
      wide={wide ? 'true' : 'false'}
      short={short ? 'true' : 'false'}
      color={color}
      // eslint-disable-next-line react/button-has-type
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...native}
    >
      {loading
        ? (
          <div className={styles.loader} style={loaderStyle}>
            <div className={styles.dot} style={dotStyle} />
            <div className={styles.dot} style={dotStyle} />
            <div className={styles.dot} style={dotStyle} />
          </div>
        )
        : (children || text)}
    </button>
  );
};

MainButton.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  className: PropTypes.string,
  text: PropTypes.string,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  short: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(BUTTON_COLORS)),
};

MainButton.defaultProps = {
  children: null,
  type: 'button',
  className: '',
  text: '',
  loading: false,
  wide: false,
  short: false,
  color: BUTTON_COLORS.GREY,
};

export { MainButton, BUTTON_COLORS };
