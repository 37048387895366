import dayjs from 'dayjs';

class OccupancyStatsSerializer {
  static deSerialize(stats) {
    const limit = stats.occupancy_threshold.length
      ? stats.occupancy_threshold.reverse().find((i) => i > 0) || 0
      : stats.occupancy_threshold;
    return {
      lastUpdated: stats.time ? dayjs(stats.time).format('h:mm a') : dayjs().format('h:mm a'),
      data: {
        trend: stats.trend,
        limit,
        average: stats.average_occupancy || 0,
        max: stats.max_occupancy || 0,
        violations: stats.violations || 0,
      },
    };
  }
}

export { OccupancyStatsSerializer };
