import React, { Component } from 'react';
import Hls from 'hls.js';
import PropTypes from 'prop-types';
import { loadProcessorToken } from 'helpers/cookie-helper';
import { ProcessorController } from 'networking/controllers/processor-controller';

const hlsConfig = {
  manifestLoadingRetryDelay: 500,
  levelLoadingRetryDelay: 500,
  liveBackBufferLength: 0,
  xhrSetup: (xhr) => {
    xhr.setRequestHeader('Authorization', `Bearer ${loadProcessorToken()}`);
  },
};

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.hls = new Hls(hlsConfig);
    this.state = {
      retries: 0,
    };
  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const {
      src, video, setLoading, setRefetchVideo, refetchVideo,
    } = this.props;
    const { retries } = this.state;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
      this.hls.attachMedia(video);
      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        this.hls.loadSource(src);
        this.hls.on(Hls.Events.LEVEL_LOADED, () => {
          setLoading(false);
          this.setState(() => ({ retries: 0 }));
          video.play();
        });
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
            this.setState((prevState) => ({ retries: prevState.retries + 1 }));
          }
          if (data.fatal || retries > 3) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                // try to recover network error
                setLoading(true);
                if (ProcessorController.getProcessorInfo()) {
                  setRefetchVideo(!refetchVideo);
                }
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                this.hls.recoverMediaError();
                break;
              default:
                // cannot recover
                this.hls.destroy();
                break;
            }
          }
        });
      });
    }
  }

  componentWillUnmount() {
    // destroy hls video source
    if (this.hls) {
      this.hls.destroy();
    }
  }

  render() {
    const { src, type } = this.props;
    return (
      <source
        src={src}
        type={type || 'application/x-mpegURL'}
      />
    );
  }
}

HLSSource.propTypes = {
  src: PropTypes.string.isRequired,
  video: PropTypes.object,
  setLoading: PropTypes.func.isRequired,
  type: PropTypes.string,
  setRefetchVideo: PropTypes.func.isRequired,
  refetchVideo: PropTypes.bool.isRequired,
};

HLSSource.defaultProps = {
  video: null,
  type: null,
};
