import dayjs from 'dayjs';

class SocialDistancingStatsSerializer {
  static deSerialize(stats) {
    return {
      lastUpdated: stats.time && dayjs(stats.time).format('h:mm a'),
      data: {
        trend: stats.trend,
        detections: stats.detected_objects || 0,
        safe: stats.no_infringement || 0,
        infringers: stats.detected_objects && stats.no_infringement
          ? stats.detected_objects - stats.no_infringement
          : 0,
        low: stats.low_infringement || 0,
        high: stats.high_infringement || 0,
        critical: stats.critical_infringement || 0,
      },
    };
  }
}

export { SocialDistancingStatsSerializer };
