class MatrixSerializer {
  static serialize(points) {
    return {
      pts_destination: [
        [points[0].x, points[0].y], // (0,0)
        [points[3].x, points[3].y], // (0,1)
        [points[2].x, points[2].y], // (1,1)
        [points[1].x, points[1].y], // (1,0)
      ],
    };
  }
}

export { MatrixSerializer };
