import dayjs from 'dayjs';
import { parseHour, parseMinute } from 'helpers/time-without-date-helper';

class OccupancyRuleSerializer {
  static deSerialize(rule) {
    return {
      occupancy: rule.max_occupancy,
      from: { h: parseHour(rule.start_hour), m: parseMinute(rule.start_hour) },
      to: { h: parseHour(rule.finish_hour), m: parseMinute(rule.finish_hour) },
      days: [rule.days[6], ...rule.days.slice(0, 6)],
    };
  }

  static serialize(rule) {
    return {
      max_occupancy: rule.occupancy,
      days: [...rule.days.slice(1), rule.days[0]],
      start_hour: dayjs().hour(rule.to.h).minute(rule.to.m).format('HH:mm'),
      finish_hour: dayjs().hour(rule.from.h).minute(rule.from.m).format('HH:mm'),
    };
  }
}

export { OccupancyRuleSerializer };
