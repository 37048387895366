import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heatmap } from 'common/heatmap';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { LiveVideo } from '../live-video';
import styles from './viewCameraModal.module.scss';

const ViewCameraModal = ({ camera }) => {
  const [showLiveVideo, setShowLiveVideo] = useState(false);
  return (
    <div className={styles.card}>
      <h1 className={styles.title}>View camera</h1>
      {showLiveVideo ? (<LiveVideo selectedCamera={camera} />) : (<Heatmap camera={camera} />)}
      <MainButton
        text={showLiveVideo ? 'Hide live video' : 'Show live video'}
        type="button"
        onClick={showLiveVideo ? () => setShowLiveVideo(false) : () => setShowLiveVideo(true)}
        color={BUTTON_COLORS.SKY}
        className={styles.liveVideoButton}
        short
      />
    </div>
  );
};

ViewCameraModal.propTypes = {
  camera: PropTypes.object.isRequired,
};

export { ViewCameraModal };
