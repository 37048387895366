import React, { useEffect } from 'react';
import { LoginCard } from 'common/loginCard';
import { goToPage, routeNaming } from 'routes';

import styles from './notFound.module.scss';

const NotFound = () => {
  useEffect(() => {
    setTimeout(() => goToPage(routeNaming.LOGIN), 3000);
  }, []);

  return (
    <LoginCard>
      <h1 className={styles.title}>Page not found.</h1>
    </LoginCard>
  );
};

export { NotFound };
