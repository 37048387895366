import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const TIMEFRAME_TYPES = {
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

class Timeframe {
  constructor(params = {}) {
    this.type = params.type || TIMEFRAME_TYPES.HOURLY;
    switch (this.type) {
      case TIMEFRAME_TYPES.HOURLY:
        this.date = params.date || dayjs().format('YYYY-MM-DD');
        break;
      case TIMEFRAME_TYPES.DAILY:
        this.startDate = params.startDate || dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        this.endDate = params.endDate || dayjs().format('YYYY-MM-DD');
        break;
      case TIMEFRAME_TYPES.WEEKLY:
        this.weeksBack = params.weeksBack || 2;
        break;
      default:
    }
  }
}

const timeframePropType = PropTypes.oneOfType([
  PropTypes.shape({
    type: PropTypes.oneOf([TIMEFRAME_TYPES.HOURLY]),
    date: PropTypes.string.isRequired,
  }),
  PropTypes.shape({
    type: PropTypes.oneOf([TIMEFRAME_TYPES.DAILY]),
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
  PropTypes.shape({
    type: PropTypes.oneOf([TIMEFRAME_TYPES.WEEKLY]),
    weeksBack: PropTypes.number.isRequired,
  }),
]);

export { Timeframe, TIMEFRAME_TYPES, timeframePropType };
