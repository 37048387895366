class EncryptedConfigurationSerializer {
  static deSerialize(configuration) {
    return {
      encryptedURL: configuration.processor_url,
      encryptedUsername: configuration.processor_user,
      encryptedPassword: configuration.processor_password,
    };
  }

  static serialize(configuration) {
    return {
      processor_url: configuration.processorURL,
      processor_user: configuration.processorUsername || undefined,
      processor_password: configuration.processorPassword || undefined,
    };
  }
}

export { EncryptedConfigurationSerializer };
