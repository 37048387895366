import React from 'react';
import PropTypes from 'prop-types';
import tooltipIcon from 'assets/images/icons-tooltip.svg';
import { Loader } from 'common/loader';

import styles from './metricCard.module.scss';

const MetricCard = ({
  title, tooltip, children, lastUpdated, graph,
}) => (
  <div className={styles.card}>
    <div className={styles.main}>
      <div className={styles.left}>
        <span className={styles.title}>
          {title}
          <div className={styles.tooltipContainer}>
            <img src={tooltipIcon} alt="tooltip" />
            <span>{tooltip}</span>
          </div>
        </span>
        {
          children
            ? <div className={styles.live}>{children}</div>
            : <Loader className={styles.loading} />
        }
      </div>
      <div className={styles.right}>
        {graph || <Loader className={styles.loading} />}
      </div>
    </div>
    <div className={styles.footer}>
      <div className={styles.liveFooter}>
        <div className={styles.indicator}>REAL TIME</div>
        <div className={styles.lastUpdated}>
          Last update:
          <br />
          {lastUpdated || <Loader className={styles.loading} />}
        </div>
      </div>
      <div className={styles.historicFooter}>
        <div className={styles.indicator}>HISTORICAL</div>
      </div>
    </div>
  </div>
);

MetricCard.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  graph: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
  lastUpdated: PropTypes.string,
};

MetricCard.defaultProps = {
  children: null,
  graph: null,
  lastUpdated: '',
};

export { MetricCard };
