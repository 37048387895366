import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'common/loader';
import { SessionController } from 'networking/controllers/session-controller';
import { routeNaming, goToPage } from 'routes';
import styles from './with-authentication.module.scss';

class AuthenticationEnforcer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { errorCode: error } = await SessionController.auth();
    if (error) {
      SessionController.expire();
      goToPage(routeNaming.LOGIN);
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div className={styles.circularProgressDiv}>
          <Loader />
        </div>
      );
    }
    const { children } = this.props;
    return children;
  }
}
AuthenticationEnforcer.propTypes = {
  children: PropTypes.node.isRequired,
};

const withAuthentication = (Component) => (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AuthenticationEnforcer><Component {...props} /></AuthenticationEnforcer>
);

export { withAuthentication };
