import dayjs from 'dayjs';
import { TIMEFRAME_TYPES } from 'models/timeframe';
import { METRIC_TIMEFRAMES } from 'networking/processor-routes';

const processTimeframe = (timeframe) => {
  let requestTimeframe;
  let timeframeQueryParams;
  if (timeframe.type === TIMEFRAME_TYPES.HOURLY) {
    requestTimeframe = METRIC_TIMEFRAMES.HOURLY;
    timeframeQueryParams = { date: timeframe.date };
  } else if (timeframe.type === TIMEFRAME_TYPES.DAILY) {
    requestTimeframe = METRIC_TIMEFRAMES.DAILY;
    timeframeQueryParams = { from_date: timeframe.startDate, to_date: timeframe.endDate };
  } else {
    requestTimeframe = METRIC_TIMEFRAMES.WEEKLY;
    const thisMonday = dayjs().day() === 0
      ? dayjs().subtract(6, 'days')
      : dayjs().subtract(dayjs().day(), 'days').add(1, 'day');
    timeframeQueryParams = {
      from_date: thisMonday.subtract(timeframe.weeksBack - 1, 'weeks').format('YYYY-MM-DD'),
      to_date: dayjs().format('YYYY-MM-DD'),
    };
  }
  return { requestTimeframe, timeframeQueryParams };
};

export { processTimeframe };
