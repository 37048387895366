import React, {
  useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { MainButton, BUTTON_COLORS } from 'common/buttons/mainButton';
import {
  lineWidth, selectedLineWidth, canvasMargin, selectedPointDiameter,
  drawPoint, drawSegment, drawInArrow, drawInArrowShadow,
  getMousePosition, findClosePoint, clampPointToSize, clampPointToSizeInPlace, findCloseLine,
} from 'helpers/canvas-helper';

import styles from './inOutCanvas.module.scss';

const InOutCanvas = ({
  enabled, returnPointsSet, returnPoints, startingPoints, config, children,
}) => {
  const { size: contentSize, controls, color } = config;
  const canvasSize = { x: contentSize.x + 2 * canvasMargin, y: contentSize.y + 2 * canvasMargin };

  const canvas = useRef(null);
  const points = useRef([...startingPoints]);
  const selected = useRef([]);
  const dragging = useRef([]);
  const lastDrag = useRef({ x: 0, y: 0 });

  const returnPointsSetCallback = useCallback(async (value) => {
    returnPointsSet(value);
  }, [returnPointsSet]);

  const drawCanvas = useCallback(() => {
    if (!canvas || !canvas.current) return;
    const context = canvas.current.getContext('2d');

    // Draw selected lines
    context.fillStyle = 'white';
    context.strokeStyle = 'white';
    context.lineWidth = selectedLineWidth;
    context.clearRect(0, 0, canvasSize.x, canvasSize.y);
    for (let i = 0; i < selected.current.length; i += 1) {
      const line = selected.current[i];
      drawPoint(context, points.current[2 * line], selectedPointDiameter);
      drawSegment(context, points.current[2 * line], points.current[2 * line + 1]);
      drawInArrowShadow(context, points.current[2 * line], points.current[2 * line + 1]);
      drawPoint(context, points.current[2 * line + 1], selectedPointDiameter);
    }

    // Draw "real" points
    context.fillStyle = color;
    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    for (let line = 0; line < Math.floor(points.current.length / 2); line += 1) {
      if (points.current[2 * line + 1]) {
        drawSegment(context, points.current[2 * line], points.current[2 * line + 1]);
        drawInArrow(context, points.current[2 * line], points.current[2 * line + 1]);
        drawPoint(context, points.current[2 * line + 1]);
      }
      drawPoint(context, points.current[2 * line]);
    }
  }, [canvasSize, color]);

  useEffect(() => {
    if (startingPoints[0]) returnPointsSet(true);
    drawCanvas();
  }, [startingPoints, returnPointsSet, drawCanvas]);

  const mouseDown = useCallback((event) => {
    if (event.buttons !== 1) return;
    const mouse = getMousePosition(canvas.current, event);
    lastDrag.current = mouse;

    let existingPointIndex;
    let existingLineIndex;

    // eslint-disable-next-line no-cond-assign
    if ((existingPointIndex = findClosePoint(mouse, points.current)) >= 0) {
      // Find close point
      dragging.current = [existingPointIndex];
      selected.current = [Math.floor(existingPointIndex / 2)];
      // eslint-disable-next-line no-cond-assign
    } else if ((existingLineIndex = findCloseLine(mouse, points.current)) >= 0) {
      // Find close segment
      dragging.current = [existingLineIndex * 2, existingLineIndex * 2 + 1];
      selected.current = [existingLineIndex];
    } else if (selected.current.length > 0) {
      // Remove selection
      dragging.current = [];
      selected.current = [];
    } else {
      // Add new pair of points
      if (!points.current[1]) returnPointsSetCallback(true);
      points.current.push({ ...mouse });
      points.current.push({ ...mouse });
      dragging.current = [points.current.length - 1];
      selected.current = [(points.current.length - 2) / 2];
    }

    drawCanvas();
  }, [drawCanvas, returnPointsSetCallback]);

  const mouseUp = useCallback(() => {
    dragging.current = [];
  }, []);

  const drag = useCallback((event) => {
    if (event.buttons !== 1) return;
    if (!Number.isInteger(dragging.current[0])) return;
    const mouse = getMousePosition(canvas.current, event);
    const clampedMouse = clampPointToSize(mouse, contentSize);
    // "displacement"
    const d = {
      x: clampedMouse.x - lastDrag.current.x,
      y: clampedMouse.y - lastDrag.current.y,
    };

    // Check if any point is on one of the edges of the canvas
    let xClamp = false;
    let yClamp = false;
    for (let i = 0; i < dragging.current.length; i += 1) {
      xClamp = xClamp
            || points.current[dragging.current[i]].x + d.x < canvasMargin
            || points.current[dragging.current[i]].x + d.x > contentSize.x + canvasMargin;
      yClamp = yClamp
            || points.current[dragging.current[i]].y + d.y < canvasMargin
            || points.current[dragging.current[i]].y + d.y > contentSize.y + canvasMargin;
    }

    // Move the points in the axis in which none of them is on the edge
    for (let i = 0; i < dragging.current.length; i += 1) {
      if (!xClamp) points.current[dragging.current[i]].x += d.x;
      if (!yClamp) points.current[dragging.current[i]].y += d.y;
      clampPointToSizeInPlace(points.current[dragging.current[i]], contentSize);
    }

    // Update dragging position and canvas
    lastDrag.current = clampedMouse;
    drawCanvas();
  }, [contentSize, drawCanvas]);

  useEffect(() => {
    if (enabled) {
      const currentRef = canvas.current;
      currentRef.addEventListener('mousedown', mouseDown);
      document.addEventListener('mouseup', mouseUp);
      document.addEventListener('mousemove', drag);
      return () => {
        currentRef.removeEventListener('mousedown', mouseDown);
      };
    }
    return () => {};
  }, [mouseDown, drag, mouseUp, enabled]);

  useEffect(
    () => {
      if (returnPoints) {
        returnPoints(points.current.map(
          (p) => ({ x: p.x - canvasMargin, y: p.y - canvasMargin }),
        ));
      }
    }, [returnPoints],
  );

  const deleteSelected = useCallback(() => {
    points.current = points.current.filter((_, i) => !selected.current.includes(Math.floor(i / 2)));
    selected.current = [];
    dragging.current = [];
    drawCanvas();
    if (!points.current[1]) returnPointsSetCallback(false);
  }, [points, selected, drawCanvas, returnPointsSetCallback]);

  const reverseSelected = useCallback(() => {
    selected.current.forEach((i) => {
      const aux = points.current[2 * i];
      points.current[2 * i] = points.current[2 * i + 1];
      points.current[2 * i + 1] = aux;
    });
    drawCanvas();
  }, [points, selected, drawCanvas]);

  const buttonBar = controls
    ? (
      <div className={styles.buttonBar} style={{ top: canvasSize.y, left: 0, height: '35px' }}>
        <MainButton text="Delete" onClick={deleteSelected} color={BUTTON_COLORS.RED} />
        <MainButton text="Flip arrow" onClick={reverseSelected} color={BUTTON_COLORS.SKY} />
      </div>
    )
    : null;

  return (
    <div
      className={styles.container}
      style={{
        width: canvasSize.x,
        height: canvasSize.y + (controls ? 35 : 0),
      }}
    >
      <div
        style={{
          width: contentSize.x,
          height: contentSize.y,
          top: canvasMargin,
          left: canvasMargin,
        }}
        className={styles.background}
      >
        {children}
      </div>
      <canvas
        ref={canvas}
        width={canvasSize.x}
        height={canvasSize.y}
        style={{ width: canvasSize.x, height: canvasSize.y }}
      />
      {buttonBar}
    </div>
  );
};

InOutCanvas.propTypes = {
  returnPoints: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  returnPointsSet: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  startingPoints: PropTypes.array,
  config: PropTypes.shape({
    size: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }).isRequired,
    controls: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.any,
};

InOutCanvas.defaultProps = {
  enabled: true,
  returnPoints: () => {},
  startingPoints: [],
  children: (<div />),
};

export { InOutCanvas };
