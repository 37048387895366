import React from 'react';
import lanthornLogo from 'assets/images/lanthornLogo.svg';
import { withAuthentication } from 'hocs/with-authentication';

import { ConnectProcessor } from './connectProcessor';
import { SetupCameras } from './cameras';
import { SetupAreas } from './areas';
import { SetupReports } from './reports';
import {
  iconForStep, stepStatuses, SETUP_STEPS, STEP_STATUSES,
} from './setupSteps';
import { StepButton } from './setupButtons';
import styles from './setup.module.scss';

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: SETUP_STEPS.CONNECT,
      isMobile: false,
      isEditing: false,
    };
    this.setSetupStep = this.setSetupStep.bind(this);
    this.stepSwitch = this.stepSwitch.bind(this);
  }

  async componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { match: { path } } = this.props;
    const mediaQuery = window.matchMedia('(max-width: 576px)');
    if (mediaQuery.matches) {
      this.setState({ isMobile: true });
    }
    mediaQuery.addEventListener('change', (e) => this.setState({ isMobile: e.matches }));
    if (path === '/setup/editCamera/:id' || path === '/setup/editCameras') {
      this.setSetupStep(SETUP_STEPS.CAMERAS);
      this.setState({ isEditing: true });
    } else if (path === '/setup/editArea/:id' || path === '/setup/editAreas') {
      this.setSetupStep(SETUP_STEPS.AREAS);
      this.setState({ isEditing: true });
    }
    return null;
  }

  setSetupStep(step) { return this.setState({ currentStep: step }); }

  stepSwitch = (step) => {
    const { isEditing } = this.state;
    // eslint-disable-next-line react/prop-types
    const { match: { params: { id } } } = this.props;
    switch (step) {
      case SETUP_STEPS.CONNECT:
        return <ConnectProcessor setSetupStep={this.setSetupStep} />;
      case SETUP_STEPS.CAMERAS:
        return <SetupCameras setSetupStep={this.setSetupStep} isEditing={isEditing} id={id} />;
      case SETUP_STEPS.AREAS:
        return <SetupAreas setSetupStep={this.setSetupStep} isEditing={isEditing} id={id} />;
      case SETUP_STEPS.REPORTS:
        return <SetupReports setSetupStep={this.setSetupStep} />;
      default:
        return <ConnectProcessor setSetupStep={this.setSetupStep} />;
    }
  }

  render() {
    const { setSetupStep } = this;
    const { currentStep, isMobile } = this.state;

    const setupTexts = {
      [SETUP_STEPS.CONNECT]: isMobile ? 'Processor' : 'Connect Processor',
      [SETUP_STEPS.CAMERAS]: 'Cameras',
      [SETUP_STEPS.AREAS]: 'Areas',
      [SETUP_STEPS.REPORTS]: 'Reports',
      [SETUP_STEPS.NON_BUSINESS_HOURS]: 'Non-Business Hours',
    };

    return (
      <>
        <div className={styles.background}>
          {isMobile && (
            <>
              <div className={styles.header}>
                <img src={lanthornLogo} alt="" className={styles.headerLogoImage} />
              </div>
              <div className={styles.content}>
                {this.stepSwitch(currentStep)}
                <div className={styles.phantomDiv} />
              </div>
            </>
          )}
          <div className={styles.sidebar}>
            <img src={lanthornLogo} alt="" className={styles.logoImage} />
            {
              Object.values(SETUP_STEPS).map((step) => (
                <StepButton
                  key={setupTexts[step]}
                  className={styles.sidebarButton}
                  text={setupTexts[step]}
                  type="button"
                  onClick={() => { setSetupStep(step); }}
                  status={stepStatuses(step, currentStep)}
                  icon={iconForStep(step, currentStep)}
                  disabled={stepStatuses(step, currentStep) === STEP_STATUSES.DISABLED}
                />
              ))
            }
          </div>
          {!isMobile && (
            <div className={styles.content}>
              {this.stepSwitch(currentStep)}
            </div>
          )}
        </div>
      </>
    );
  }
}

const WrappedSetup = withAuthentication(Setup);

export { WrappedSetup as Setup, SETUP_STEPS };
