import React from 'react';
import PropTypes from 'prop-types';

import { SidebarCard } from '../sidebarCard/sidebarCard';

const CameraCard = ({
  camera, selected, onClick,
}) => (
  <SidebarCard
    title={camera.cameraName}
    id={`${camera.id}-camera-dashboard-checkbox`}
    selected={selected}
    onClick={onClick(camera.id)}
  />
);

CameraCard.propTypes = {
  camera: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { CameraCard };
