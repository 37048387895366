/* eslint-disable camelcase */
import dayjs from 'dayjs';

class InOutStatsSerializer {
  static deSerialize(stats) {
    return {
      lastUpdated: stats.time ? dayjs(stats.time).format('h:mm a') : dayjs().format('h:mm a'),
      data: {
        in: stats.in,
        out: Array.isArray(stats.in) ? stats.out.map((n) => -n) : stats.out,
        balance: Array.isArray(stats.in)
          ? stats.in.map((_, i) => stats.in[i] - stats.out[i])
          : stats.in - stats.out,
        avgOccupancy: stats.estimated_average_occupancy,
        maxOccupancy: stats.estimated_max_occupancy,
        latestOccupancy: stats.estimated_latest_occupancy,
      },
    };
  }
}

export { InOutStatsSerializer };
