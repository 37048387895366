const API_ROUTES = {
  SESSIONS: '/sessions',
  PASSWORDS: '/passwords',
  CONFIG: '/config',
  USERS: '/users',
  ACTIVATE_USER: '/users/activate',
  RESEND_CONFIRMATION_EMAIL: '/users/resend-email',
};

const SLACK_ROUTES = {
  authorize: 'https://slack.com/oauth/v2/authorize',
};

const COUNTRY_API = {
  ALL: 'https://restcountries.eu/rest/v2/all?fields=name',
};

export {
  API_ROUTES, COUNTRY_API, SLACK_ROUTES,
};
