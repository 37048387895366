import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { ReportsController } from 'networking/controllers/reports-controller';
import {
  historicGraphType, historicGraphData, historicGraphOptions,
} from 'helpers/historic-graph-helper';

import { Chart } from 'common/chart';
import {
  socialDistancingSafe, socialDistancingCritical, socialDistancingHigh, socialDistancingLow,
} from 'assets/stylesheets/colors.scss';

import { MetricCard } from '../metricCard';

import styles from './socialDistancing.module.scss';

const graphItems = [
  { label: 'Low', backgroundColor: socialDistancingLow },
  { label: 'High', backgroundColor: socialDistancingHigh },
  { label: 'Critical', backgroundColor: socialDistancingCritical },
  { label: 'Safe', backgroundColor: socialDistancingSafe },
];

const SocialDistancingCard = ({ timeframe, showAreas, ids }) => {
  const [liveData, setLiveData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [historicData, setHistoricData] = useState({});
  const [processedHistoricData, setProcessedHistoricData] = useState({});
  const [historicChartType, setHistoricChartType] = useState('bar');
  const [loadingLive, setLoadingLive] = useState(true);
  const [loadingHistoric, setLoadingHistoric] = useState(true);

  useEffect(() => {
    const updateLiveData = async () => {
      const {
        lastUpdated: recievedLastUpdated, data, errorCode,
      } = await ReportsController.getLiveSocialDistancingStats(showAreas, ids);
      if (errorCode) return null;
      setLastUpdated(recievedLastUpdated);
      setLiveData(data);
      return setLoadingHistoric(false);
    };

    const updateHistoricData = async () => {
      const { data, errorCode } = await ReportsController.getHistoricSocialDistancingData(
        showAreas, ids, timeframe,
      );
      if (errorCode) return null;
      setHistoricChartType(historicGraphType(timeframe));
      setHistoricData(data);
      return setLoadingLive(false);
    };

    setLoadingHistoric(true);
    setLoadingLive(true);

    updateLiveData();
    updateHistoricData();
  }, [timeframe, showAreas, ids]);

  useEffect(() => (setProcessedHistoricData(historicGraphData(
    timeframe,
    graphItems.map((item) => item.label),
    graphItems.map((i) => historicData[i.label.toLowerCase()]),
    graphItems.map((item) => item.backgroundColor),
    graphItems.map((item) => `${item.backgroundColor}80`),
  ))), [historicData, timeframe]);

  const historicGraph = (
    <Chart type={historicChartType} data={processedHistoricData} options={historicGraphOptions()} />
  );

  return (
    <MetricCard
      title="Social Distancing"
      tooltip="Social Distancing detection is displayed in two ways. Real-time and historical.
        The left-hand real-time display shows the number of times people were too close to one another.
        The right-hand historical display shows the daily infringements and the hours at which they occurred."
      graph={!loadingHistoric && historicGraph}
      lastUpdated={loadingLive ? '' : lastUpdated}
    >
      {
        !loadingLive && (
          <div className={styles.numbers}>
            <div className={styles.detections}>
              <div>Detections:</div>
              <span>{liveData.detections}</span>
            </div>
            <div className={styles.infringements}>
              <div className={styles.title}>
                <div>Infringers:</div>
                <span>{liveData.infringers}</span>
              </div>
              {
                graphItems.slice(0, 3).map((item, i) => (
                  <div className={styles.row} key={item.label}>
                    <div className={styles.indicator}>
                      <div
                        style={{
                          backgroundColor: graphItems[i].backgroundColor,
                          borderColor: graphItems[i].backgroundColor,
                        }}
                      />
                      {item.label}
                    </div>
                    <span>{liveData[item.label.toLowerCase()]}</span>
                  </div>
                ))
              }
            </div>
            <div className={styles.safe}>
              <div className={styles.indicator}>
                <div
                  style={{
                    backgroundColor: graphItems[3].backgroundColor,
                    borderColor: graphItems[3].backgroundColor,
                  }}
                />
                Safe
              </div>
              <span>{liveData.safe}</span>
            </div>
          </div>
        )
      }
    </MetricCard>
  );
};

SocialDistancingCard.propTypes = {
  timeframe: PropTypes.object.isRequired,
  showAreas: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { SocialDistancingCard };
