import React, { useState } from 'react';
import PropTypes from 'prop-types';

import editIconSelected from 'assets/images/setup-icons/icons-edit.png';
import editIconUnselected from 'assets/images/icons-edit.svg';
import deleteIcon from 'assets/images/setup-icons/icons-close.png';
import { Modal } from 'common/modal';
import { ModalAlert } from 'common/modalAlert';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import styles from './editButton.module.scss';

const EditButton = (props) => {
  const {
    text, className, id, setRemove, isSelected, ...native
  } = props;
  const [showModal, setShowModal] = useState(false);
  const modal = (
    <Modal dismiss={() => setShowModal(false)}>
      <ModalAlert
        dismiss={() => setShowModal(false)}
        text={[`Are you sure you want to delete ${text}?`]}
        buttons={[
          (<MainButton
            text="Cancel"
            type="button"
            onClick={() => setShowModal(false)}
            color={BUTTON_COLORS.GREY}
          />),
          (<MainButton
            text="Yes"
            type="button"
            onClick={() => { setRemove(id); setShowModal(false); }}
            color={BUTTON_COLORS.SKY}
          />),
        ]}
      />
    </Modal>
  );

  return (
    <>
      {showModal && modal}
      <div className={[styles.buttonDiv, className].join(' ')}>
        <button
          className={styles.button}
          type="button"
          text={text}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...native}
        >
          <img className={styles.icon} alt="" src={isSelected ? editIconSelected : editIconUnselected} />
          {text}
        </button>
        <button className={styles.button} type="button" onClick={() => setShowModal(true)}>
          <img className={styles.icon} alt="" src={deleteIcon} />
        </button>
      </div>
    </>
  );
};

EditButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  setRemove: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

EditButton.defaultProps = {
  className: '',
  icon: '',
  id: '',
  isSelected: true,
};

export { EditButton };
