import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const routeNaming = {
  CATCH_ALL: 'catchAll',
  LOGIN: 'login',
  REGISTER: 'register',
  REGISTER_EMAIL_SENT: 'registerEmailCent',
  ACTIVATE_ACCOUNT: 'activateAccount',
  FORGOT_PASSWORD: 'forgotPassword',
  PASSWORD_RECOVERY_SENT: 'passwordRecoverySent',
  PASSWORD_RECOVERY: 'passwordRecovery',
  EMAIL_ACTIVATION: 'emailActivation',
  ROOT: 'root',
  SETUP: 'setup',
  ADD_CAMERA: 'addCamera',
  EDIT_CAMERA: 'editCamera',
  EDIT_CAMERAS: 'editCameras',
  ADD_AREA: 'addArea',
  EDIT_AREA: 'editArea',
  EDIT_AREAS: 'editAreas',
  DASHBOARD: 'dashboard',
  HOME: 'home',
  DEMO_HOME: 'demoHome',
  PROFILE: 'profile',
  SLACK_SETUP: 'slack',
};

const PATH_PARAM_REGEX = /:[^/]+/gi;
/**
  Analyzes the path defined for `route` and
  returns a copy of the route with a new attribute
  `pathParams` which is a list of strings that correspond to the path params.

  @param {object} route - Object that represents a route.

  @return {object} updated route with the new attribute.

  @example

    determineRouteParams({ name: 'product', path: '/product/:id', component: ProductPage })
*/
const determineRouteParams = (route) => {
  const newRoute = { ...route };
  const { path } = newRoute;
  // Extract the names of the parameters
  const pathMatch = path.match(PATH_PARAM_REGEX) || [];
  const pathParams = pathMatch.map((param) => param.slice(1));
  newRoute.pathParams = pathParams || [];
  return newRoute;
};

const routes = [
  {
    name: routeNaming.ROOT,
    path: '/',
  },
  {
    name: routeNaming.LOGIN,
    path: '/login',
  },
  {
    name: routeNaming.REGISTER,
    path: '/register',
  },
  {
    name: routeNaming.REGISTER_EMAIL_SENT,
    path: '/register-email',
  },
  {
    name: routeNaming.ACTIVATE_ACCOUNT,
    path: '/activate-account',
  },
  {
    name: routeNaming.FORGOT_PASSWORD,
    path: '/forgot-password',
  },
  {
    name: routeNaming.PASSWORD_RECOVERY_SENT,
    path: '/password-recovery-sent',
  },
  {
    name: routeNaming.SETUP,
    path: '/setup',
  },
  {
    name: routeNaming.SLACK_SETUP,
    path: '/slack',
  },
  {
    name: routeNaming.PASSWORD_RECOVERY,
    path: '/password-recovery',
  },
  {
    name: routeNaming.EDIT_CAMERAS,
    path: '/setup/editCameras',
  },
  {
    name: routeNaming.EDIT_CAMERA,
    path: '/setup/editCamera/:id',
  },
  {
    name: routeNaming.EDIT_AREA,
    path: '/setup/editArea/:id',
  },
  {
    name: routeNaming.EDIT_AREAS,
    path: '/setup/editAreas',
  },
  {
    name: routeNaming.DASHBOARD,
    path: '/dashboard',
  },
  {
    name: routeNaming.HOME,
    path: '/home',
  },
  {
    name: routeNaming.DEMO_HOME,
    path: '/demo',
  },
  {
    name: routeNaming.EMAIL_ACTIVATION,
    path: '/email-confirmation',
  },
  {
    name: routeNaming.PROFILE,
    path: '/profile',
  },
  {
    name: routeNaming.CATCH_ALL,
    path: '*',
  },
].map(determineRouteParams);

export {
  determineRouteParams,
  history,
  routeNaming,
  routes,
};
