import React from 'react';

import connectProcessorEnabled from 'assets/images/setup-icons/icons-processor-enabled.png';
import connectProcessorCompleted from 'assets/images/setup-icons/icons-processor-completed.png';
import camerasCompleted from 'assets/images/setup-icons/icons-cameras-completed.png';
import camerasEnabled from 'assets/images/setup-icons/icons-cameras-enabled.png';
import camerasDisabled from 'assets/images/setup-icons/icons-cameras-disabled.png';
import areasCompleted from 'assets/images/setup-icons/icons-areas-completed.png';
import areasEnabled from 'assets/images/setup-icons/icons-areas-enabled.png';
import areasDisabled from 'assets/images/setup-icons/icons-areas-disabled.png';
import reportsEnabled from 'assets/images/setup-icons/icons-reports-enabled.png';
import reportsDisabled from 'assets/images/setup-icons/icons-reports-disabled.png';

const SETUP_STEPS = {
  CONNECT: 1,
  CAMERAS: 2,
  AREAS: 3,
  REPORTS: 4,
};

const STEP_STATUSES = {
  COMPLETED: 'completed',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

const icons = {
  [SETUP_STEPS.CONNECT]: {
    [STEP_STATUSES.COMPLETED]: connectProcessorCompleted,
    [STEP_STATUSES.ENABLED]: connectProcessorEnabled,
    [STEP_STATUSES.DISABLED]: <svg />,
  },
  [SETUP_STEPS.CAMERAS]: {
    [STEP_STATUSES.COMPLETED]: camerasCompleted,
    [STEP_STATUSES.ENABLED]: camerasEnabled,
    [STEP_STATUSES.DISABLED]: camerasDisabled,
  },
  [SETUP_STEPS.AREAS]: {
    [STEP_STATUSES.COMPLETED]: areasCompleted,
    [STEP_STATUSES.ENABLED]: areasEnabled,
    [STEP_STATUSES.DISABLED]: areasDisabled,
  },
  [SETUP_STEPS.REPORTS]: {
    [STEP_STATUSES.COMPLETED]: reportsDisabled,
    [STEP_STATUSES.ENABLED]: reportsEnabled,
    [STEP_STATUSES.DISABLED]: reportsDisabled,
  },
};

const stepStatuses = (step, currentStep) => {
  if (step < currentStep) return STEP_STATUSES.COMPLETED;
  if (step === currentStep) return STEP_STATUSES.ENABLED;
  return STEP_STATUSES.DISABLED;
};

const iconForStep = (step, currentStep) => icons[step][stepStatuses(step, currentStep)];

export {
  SETUP_STEPS,
  STEP_STATUSES,
  stepStatuses,
  iconForStep,
};
