import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './toggle.module.scss';

export const Toggle = ({ value, setValue, className }) => {
  const [id] = useState(`lanthorn-toggle-${Math.ceil(Math.random() * 10000)}`);
  return (
    <label htmlFor={id} className={styles.switch}>
      <input
        type="checkbox"
        id={id}
        checked={value}
        onClick={() => setValue(!value)}
        readOnly
        className={styles.checkbox}
      />
      <span className={[styles.slider, className].join(' ')} />
    </label>
  );
};

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  className: PropTypes.string,
};
Toggle.defaultProps = {
  className: '',
};
