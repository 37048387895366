import { OccupancyRuleSerializer } from 'networking/serializers/occupancy-rule-serializer';

class AreaSerializer {
  static deSerialize(area) {
    return {
      violation_threshold: area.violation_threshold,
      notify_every_minutes: area.notify_every_minutes,
      emails: area.emails,
      daily_report: area.daily_report,
      daily_report_time: area.daily_report_time,
      areaCapacity: area.occupancy_threshold,
      id: area.id,
      areaName: area.name,
      cameras: [...new Set(area.cameras.split(','))],
      slackNotifications: area.enable_slack_notifications,
      occupancyRules: (area.occupancy_rules || []).map(OccupancyRuleSerializer.deSerialize),
    };
  }

  static serialize(area) {
    return {
      violation_threshold: area.violation_threshold,
      notify_every_minutes: area.notify_every_minutes,
      emails: area.emails,
      daily_report: area.daily_report,
      daily_report_time: area.daily_report_time,
      occupancy_threshold: area.areaCapacity,
      id: area.id,
      name: area.areaName,
      cameras: area.cameras.join(','),
      enable_slack_notifications: area.slackNotifications,
      occupancy_rules: area.occupancyRules.map(OccupancyRuleSerializer.serialize),
    };
  }
}

export { AreaSerializer };
