import React from 'react';
import PropTypes from 'prop-types';

import dismissCross from 'assets/images/cross-black.png';

import styles from './modalBox.module.scss';

const ModalBox = ({ children, dismiss }) => (
  <div className={styles.background}>
    <button type="button" onClick={dismiss} className={styles.dismiss}>
      <img src={dismissCross} alt="Close" />
    </button>
    <div className={styles.content}>
      {children}
      <div className={styles.bottomMargin} />
    </div>
  </div>
);

ModalBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  dismiss: PropTypes.func.isRequired,
};

export { ModalBox };
