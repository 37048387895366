import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import genericStyles from 'assets/stylesheets/generic-styles.module.scss';
import lanthornLogo from 'assets/images/lanthornLogo.svg';
import { goToPage, routeNaming } from 'routes';
import { SessionController } from 'networking/controllers/session-controller';
import styles from './loginCard.module.scss';

const LoginCard = ({ children, skipLoginCheck }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkCredentials = async () => {
      if (!skipLoginCheck) {
        const { errorCode } = await SessionController.auth();
        if (!errorCode) return goToPage(routeNaming.DASHBOARD);
      }
      return setLoading(false);
    };
    checkCredentials();
  }, [skipLoginCheck]);

  return !loading && (
    <div className={[genericStyles.genericContainer, styles.background].join(' ')}>
      <img src={lanthornLogo} alt="" className={styles.logoImage} />
      <div className={styles.loginBox}>
        {children}
      </div>
    </div>
  );
};

LoginCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  skipLoginCheck: PropTypes.bool,
};

LoginCard.defaultProps = {
  skipLoginCheck: false,
};

export { LoginCard };
