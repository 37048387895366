import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { ReportsController } from 'networking/controllers/reports-controller';
import { historicGraphData, historicGraphOptions, historicGraphType } from 'helpers/historic-graph-helper';

import { Chart } from 'common/chart';
import { twilightBlue, occupancyRed } from 'assets/stylesheets/colors.scss';

import { MetricCard } from '../metricCard';

import styles from './occupancy.module.scss';

const OccupancyCard = ({ timeframe, ids }) => {
  const [liveData, setLiveData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [historicGraph, setHistoricGraph] = useState(null);

  useEffect(() => {
    const updateLiveData = async () => {
      const {
        data, lastUpdated: recievedLastUpdated, errorCode,
      } = await ReportsController.getLiveOccupancyStats(ids);
      if (errorCode) return null;
      setLastUpdated(recievedLastUpdated);
      return setLiveData(data);
    };

    const updateHistoricData = async () => {
      setLiveData(null);
      setLastUpdated(null);
      setHistoricGraph(null);
      const { data: recievedData, errorCode } = await ReportsController.getHistoricOccupancyData(
        ids, timeframe,
      );
      if (errorCode) return null;
      const type = historicGraphType(timeframe);
      const options = historicGraphOptions();
      options.scales.yAxes[0].stacked = false;
      options.scales.xAxes[0].stacked = false;
      const data = historicGraphData(
        timeframe,
        ['Occupancy'],
        [recievedData.max],
        ['#00b46d'],
        type === 'line'
          ? ['rgba(0, 182, 98, 0.5)']
          : ['rgba(0, 182, 98, 0.5)'],
      );
      const threshold = {
        label: 'Threshold',
        data: recievedData.average.map(() => recievedData.limit),
        backgroundColor: '#db313188',
        borderColor: '#db313188',
        pointRadius: 0,
        borderWidth: 8,
        type: 'line',
        fill: false,
      };

      data.labels.unshift([]);
      data.labels.push([]);
      data.datasets[0].data.unshift(0);
      data.datasets[0].data.push(0);
      // data.datasets[1].data.unshift(0);
      // data.datasets[1].data.push(0);
      threshold.data.unshift(recievedData.limit);
      threshold.data.push(recievedData.limit);

      data.datasets.unshift(threshold);

      return setHistoricGraph({
        type,
        data,
        options,
      });
    };

    updateLiveData();
    updateHistoricData();
  }, [timeframe, ids]);

  const graph = historicGraph && (
    <Chart type={historicGraph.type} data={historicGraph.data} options={historicGraph.options} />
  );

  const percentage = liveData && liveData.average && liveData.limit
    ? Math.round((liveData.average / liveData.limit) * 100)
    : 0;
  const infringment = percentage > 100;
  return (
    <MetricCard
      title="Occupancy"
      tooltip="Occupancy is displayed in two ways. Real-time and historical. The real-time display is on the left-hand side and also displays the occupancy threshold.
        The historical occupancy analytics is displayed on the right-hand graph for the day."
      graph={graph}
      lastUpdated={lastUpdated}
    >
      {
        liveData && (
          <div className={styles.container}>
            <div className={styles.bar}>
              <div
                className={styles.barProgress}
                style={{
                  width: `${Math.min(100, percentage)}%`,
                  backgroundColor: infringment ? occupancyRed : twilightBlue,
                }}
              />
            </div>
            <span
              className={styles.percentage}
              style={{
                color: infringment ? occupancyRed : twilightBlue,
                fontWeight: infringment ? 600 : 400,
              }}
            >
              {percentage}
              % Occupancy
            </span>
            <div className={styles.numbers}>
              <div
                className={styles.occupancy}
                style={{ backgroundColor: infringment ? occupancyRed : twilightBlue }}
              >
                <div>
                  <span>{liveData.average}</span>
                  <br />
                  Occupants
                </div>
              </div>
              <div className={styles.threshold}>
                <div>
                  <span>{liveData.limit}</span>
                  <br />
                  Threshold
                </div>
              </div>
            </div>
          </div>
        )
      }
    </MetricCard>
  );
};

OccupancyCard.propTypes = {
  timeframe: PropTypes.object.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { OccupancyCard };
