import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { UserSerializer } from 'networking/serializers/user-serializer';
import { loadToken } from 'helpers/cookie-helper';
import { SessionController } from 'networking/controllers/session-controller';
import { ERRORS } from 'helpers/error-helper';

class UserController {
  static async saveUser(user) {
    const serializedUser = UserSerializer.serialize(user);
    try {
      const userResponse = await ApiService.post(API_ROUTES.USERS, serializedUser);
      return { user: UserSerializer.deSerialize(userResponse) };
    } catch (error) {
      return SessionController.handleAPIError(error, (err) => (
        err.status === 400 ? { errorCode: ERRORS.API.REGISTERED } : {}
      ));
    }
  }

  static async editUser(user) {
    const serializedUser = UserSerializer.serialize(user);
    try {
      const userResponse = await ApiService.patch(API_ROUTES.USERS, serializedUser,
        { headers: { Authorization: `Bearer ${loadToken()}` } });
      return { user: UserSerializer.deSerialize(userResponse.data) };
    } catch (error) {
      return SessionController.handleAPIError(error);
    }
  }

  static async activateUser(token) {
    try {
      const userResponse = await ApiService.post(
        `${API_ROUTES.ACTIVATE_USER}?token=${token}`,
      );
      return { user: UserSerializer.deSerialize(userResponse) };
    } catch (error) {
      return SessionController.handleAPIError(error);
    }
  }

  static async resendEmail(email) {
    try {
      const userResponse = await ApiService.post(
        `${API_ROUTES.RESEND_CONFIRMATION_EMAIL}`, null, { params: { email } },
      );
      return { user: UserSerializer.deSerialize(userResponse) };
    } catch (error) {
      return SessionController.handleAPIError(error, (err) => (
        err.status === 404 ? { errorCode: ERRORS.API.UNREGISTERED } : {}
      ));
    }
  }

  static async getMe() {
    try {
      const userResponse = await ApiService.get(API_ROUTES.USERS, {},
        { headers: { Authorization: `Bearer ${loadToken()}` } });
      return { user: UserSerializer.deSerialize(userResponse.data) };
    } catch (error) {
      return SessionController.handleAPIError(error);
    }
  }

  static async validatePassword(password) {
    const { user: { email } = {}, errorCode: emailError } = await UserController.getMe();
    if (emailError) return { errorCode: ERRORS.API.UNEXPECTED };
    try {
      const { data: { access_token: token } } = await ApiService.post(
        API_ROUTES.SESSIONS,
        `grant_type=password&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      );
      ApiService.delete(API_ROUTES.SESSIONS, {}, { headers: { Authorization: `Bearer ${token}` } });
    } catch (error) {
      if (error.status === 401) return { valid: false, errorCode: false };
      return { errorCode: ERRORS.API.UNEXPECTED };
    }
    return { valid: true, errorCode: false };
  }
}

export { UserController };
