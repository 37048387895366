class UserSerializer {
  static deSerialize(user) {
    return {
      firstName: user.first_name,
      lastName: user.last_name,
      fullName: `${user.first_name} ${user.last_name}`,
      company: user.company,
      country: user.country,
      active: user.active,
      email: user.email,
    };
  }

  static serialize(user) {
    return {
      first_name: user.firstName,
      last_name: user.lastName,
      company: user.company,
      country: user.country,
      password: user.password,
      email: user.email,
    };
  }
}

export { UserSerializer };
