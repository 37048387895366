import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { CameraController } from 'networking/controllers/camera-controller';

import { InOutCanvas } from 'common/inOutCanvas';
import { InOutDemoCanvas } from 'common/inOutDemoCanvas';
import { MainButton, BUTTON_COLORS } from 'common/buttons';

import { Loader } from 'common/loader';

import { lanthornOrange } from 'assets/stylesheets/colors.scss';
import styles from './inOutModal.module.scss';

const InOutModal = ({ camera, setShowInOutModal }) => {
  const [imageURI, setImage] = useState();
  const [placeholderURI, setPlaceholderURI] = useState();
  const [imgSize, setImgSize] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true);
  const [tutorial, setTutorial] = useState(true);
  const [sendingData, setSendingData] = useState(false);
  const [pointsAreSet, setPointsAreSet] = useState(false);
  const [finished, setFinished] = useState(false);
  const [existingPoints, setExistingPoints] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      const { image, errorCode: imageError } = await CameraController.getCalibrationImage(
        camera.id,
      );
      if (imageError) return;
      const { points, errorCode: pointsError } = await CameraController.getInOutPoints(
        camera.id,
      );
      if (!pointsError) {
        setTutorial(false);
        setExistingPoints(points);
      }
      setPlaceholderURI(`data:image/jpeg;base64,${image}`);
    };
    fetchImage();
  }, [camera.id]);

  const placeholderImageElement = useRef(null);

  const onLoadPlaceholder = () => {
    setImgSize({
      x: placeholderImageElement.current.naturalWidth,
      y: placeholderImageElement.current.naturalHeight,
    });
    setImage(placeholderURI);
    setLoading(false);
  };

  const sendPoints = useCallback(async (points) => {
    setSendingData(true);
    const { errorCode: error } = await CameraController.setInOutPoints(camera.id, points);
    if (!error) {
      setSendingData(false);
      setShowInOutModal(false);
      return;
    }
    setFinished(false);
  }, [camera.id, setShowInOutModal]);

  const canvasConfig = {
    size: imgSize,
    color: lanthornOrange,
    controls: true,
  };

  const canvas = tutorial
    ? <InOutDemoCanvas config={{ ...canvasConfig, controls: false }} />
    : (
      <InOutCanvas
        config={canvasConfig}
        returnPointsSet={(set) => setPointsAreSet(set)}
        startingPoints={existingPoints}
        returnPoints={finished && sendPoints}
      >
        <img alt="Camera background" src={imageURI} />
      </InOutCanvas>
    );

  return (
    <div className={styles.card}>
      <div className={styles.image}>
        {loading ? <Loader className={styles.loader} /> : canvas}
      </div>
      <div className={styles.text}>
        <p className={styles.text1}>
          Click and drag to draw a line in front of the entrance.
          <br />
          You can adjust the points.
        </p>
        <p className={styles.text2}>
          Position the line to be as wide as the door frame.
          <br />
          The arrow must point to the interior of the building/area.
          <br />
          You can flip the orientation if it does not.
        </p>
        <div className={styles.stepsButtonContainer}>
          <MainButton
            text="Cancel"
            onClick={() => setShowInOutModal(false)}
            color={BUTTON_COLORS.GREY}
          />
          <MainButton
            text={tutorial ? 'Start' : 'Finish'}
            onClick={tutorial ? () => setTutorial(false) : () => setFinished(true)}
            disabled={tutorial ? false : !pointsAreSet}
            color={BUTTON_COLORS.SKY}
            loading={sendingData}
          />
        </div>
      </div>
      <img alt="Placeholder" className={styles.placeholder} src={placeholderURI} ref={placeholderImageElement} onLoad={onLoadPlaceholder} />
    </div>
  );
};

InOutModal.propTypes = {
  camera: PropTypes.object.isRequired,
  setShowInOutModal: PropTypes.func.isRequired,
};

export { InOutModal };
