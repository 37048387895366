import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { Callout, CALLOUT_TYPES } from 'common/callout';
import { TextInput } from 'common/textInput';
import { Checkbox } from 'common/checkbox';
import { ReportsController } from 'networking/controllers/reports-controller';
import { errorMessage } from 'helpers/error-helper';
import styles from './setupReports.module.scss';
import { SETUP_STEPS } from '../setupSteps';
import { goToPage, routeNaming } from 'routes';

const SetupReports = ({ setSetupStep }) => {
  const [dailyReport, setDailyReport] = useState(false);
  const [weeklyReport, setWeeklyReport] = useState(false);
  const [emails, setEmails] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadReportsConfig = async () => {
      const { globalReport, errorCode } = await ReportsController.getGlobalReports();
      if (!errorCode) {
        setDailyReport(globalReport.daily);
        setWeeklyReport(globalReport.weeklyReport);
        setEmails(globalReport.emails);
      }
      return {};
    };
    loadReportsConfig();
  }, []);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const { errorCode } = await ReportsController.updateGlobalReports(
      emails, dailyReport, weeklyReport,
    );
    if (errorCode) {
      setError(errorMessage(errorCode));
    } else {
      goToPage(routeNaming.DASHBOARD);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Reports
      </h1>
      <Callout
        text="You will receive reports regarding important statistics such as face mask usage, occupancy, and social distancing"
        type={CALLOUT_TYPES.WARNING}
        className={styles.callout}
      />
      <form className={styles.formContainer} onSubmit={handleOnSubmit}>
        <h2 className={styles.subtitle}>
          Receive reports
        </h2>
        <div className={styles.checkbox}>
          <Checkbox
            text="Daily"
            id="Daily"
            label="after"
            value={dailyReport}
            checked={dailyReport}
            onClick={(e) => setDailyReport(e.target.checked)}
            className={styles.checkbox}
          />
          <Checkbox
            text="Weekly"
            id="Weekly"
            label="after"
            value={weeklyReport}
            checked={weeklyReport}
            onClick={(e) => setWeeklyReport(e.target.checked)}
            className={styles.checkbox}
          />
        </div>
        <h2 className={styles.subtitle}>
          Send to
        </h2>
        <TextInput
          name="email"
          placeholder="hr@lanthorn.ai , alerts@lanthorn.ai"
          onChange={(e) => setEmails(e.target.value)}
          value={emails}
        />
        {error && (<p>{error}</p>)}
        <div className={styles.stepButtonsDiv}>
          <MainButton
            className={styles.stepButton}
            type="button"
            onClick={() => { setSetupStep(SETUP_STEPS.AREAS); }}
            color={BUTTON_COLORS.WHITE}
            text="PREV"
          />
          <MainButton
            className={styles.stepButton}
            type="submit"
            color={BUTTON_COLORS.SKY}
            text="FINISH"
          />
        </div>
      </form>
    </div>
  );
};

SetupReports.propTypes = {
  setSetupStep: PropTypes.func.isRequired,
};

export { SetupReports };
