import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'common/modal';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { ModalAlert } from 'common/modalAlert';
import { TextInput } from 'common/textInput';

import styles from './modalErrors.module.scss';

const MODAL_ERRORS = {
  FALSE: false,
  DECRYPT: 1,
  UNEXPECTED: 2,
  CANT_REACH_PROCESSOR: 3,
  ENCRYPT: 4,
  HAS_BEEN_CONFIGURED: 5,
  BAD_DATA: 6,
  BAD_PROCESSOR_CREDENTIALS: 7,
};

const DecryptDialogue = ({ decrypt, dismiss }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('\u200b');
  const submit = async (e) => {
    e.preventDefault();
    const res = await decrypt(password);
    if (res && res.errorMessage) setError(res.errorMessage);
  };
  return (
    <ModalAlert
      buttons={[<MainButton text="Accept" type="submit" form="decrypt-form" color={BUTTON_COLORS.SKY} />]}
      text={[
        'We need your password to decrypt',
        'your new configuration.',
      ]}
      dismiss={dismiss}
    >
      <form id="decrypt-form" className={styles.modalForm} onSubmit={submit}>
        <TextInput
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => { setPassword(e.target.value); setError('\u200b'); }}
          className={styles.passwordField}
          fat
        />
        <span className={styles.errorMessage}>{error}</span>
      </form>
    </ModalAlert>
  );
};

const EncryptDialogue = ({ encrypt, dismiss }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('\u200b');
  const submit = async (e) => {
    e.preventDefault();
    const res = await encrypt(password);
    if (res && res.errorMessage) setError(res.errorMessage);
  };
  return (
    <ModalAlert
      buttons={[<MainButton text="Accept" type="submit" form="encrypt-form" color={BUTTON_COLORS.SKY} />]}
      text={[
        'We need your account password to encrypt',
        'your new configuration.',
      ]}
      dismiss={dismiss}
    >
      <form id="encrypt-form" className={styles.modalForm} onSubmit={submit}>
        <TextInput
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => { setPassword(e.target.value); setError('\u200b'); }}
          required
          className={styles.passwordField}
          fat
        />
        <span className={styles.errorMessage}>{error}</span>
      </form>
    </ModalAlert>
  );
};

const CantReachProcessorDialogue = ({ goToSettings, retry, dismiss }) => (
  <ModalAlert
    text={[
      'Your processor seems to be unavailable.',
      'It may be down or outside of your local network.',
    ]}
    buttons={[
      (<MainButton
        text="Go to Setup"
        onClick={goToSettings}
        color={BUTTON_COLORS.GREY}
      />),
      (<MainButton
        text="Retry"
        onClick={retry}
        color={BUTTON_COLORS.SKY}
      />),
    ]}
    dismiss={dismiss}
  />
);

const UnexpectedErrorDialogue = ({ accept, dismiss }) => (
  <ModalAlert
    text={[
      'An unexpected error caused this operation to',
      'fail. You can refresh the page to try again.',
    ]}
    buttons={[(
      <MainButton
        text="Accept"
        onClick={accept}
        color={BUTTON_COLORS.SKY}
      />
    )]}
    dismiss={dismiss}
  />
);

const NewURLDialogue = ({ saveNewURL, cancel, dismiss }) => {
  const [password, setPassword] = useState('');
  const [newURL, setNewURL] = useState('');
  const [error, setError] = useState('\u200b');
  const submit = async (e) => {
    e.preventDefault();
    const { errorMessage } = await saveNewURL(newURL, password);
    if (errorMessage) setError(errorMessage);
  };
  return (
    <ModalAlert
      buttons={[
        <MainButton text="Go back and retry" onClick={cancel} color={BUTTON_COLORS.GREY} />,
        <MainButton text="Save" type="submit" form="reset-url-form" color={BUTTON_COLORS.SKY} />,
      ]}
      text={[
        'You can reset your processor\'s URL if it changed,',
        'we need your password to encrypt the new one.',
      ]}
      dismiss={dismiss}
    >
      <form id="reset-url-form" className={styles.modalForm} onSubmit={submit}>
        <TextInput
          value={newURL}
          placeholder="URL"
          onChange={(e) => { setNewURL(e.target.value); setError('\u200b'); }}
          required
          className={styles.urlField}
          fat
        />
        <TextInput
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => { setPassword(e.target.value); setError('\u200b'); }}
          required
          className={styles.passwordURLField}
          fat
        />
        <span className={styles.errorMessage}>{error}</span>
      </form>
    </ModalAlert>
  );
};

const HasBeenConfiguredDialogue = ({ goToDashboard, dismiss }) => (
  <ModalAlert
    text={[
      'Your processor is already configured.',
    ]}
    buttons={[
      (<MainButton
        text="Continue on Setup"
        onClick={dismiss}
        color={BUTTON_COLORS.GREY}
      />),
      (<MainButton
        text="Go to dashboard"
        onClick={goToDashboard}
        color={BUTTON_COLORS.SKY}
      />),
    ]}
    dismiss={dismiss}
  />
);

const DataErrorModal = ({ retry, dismiss }) => (
  <ModalAlert
    text={[
      'Something seems to be wrong with your processor\'s data.',
      'Check if it\'s up to date or try to set it up again.',
    ]}
    buttons={[
      (<MainButton
        text="Cancel"
        onClick={dismiss}
        color={BUTTON_COLORS.GREY}
      />),
      (<MainButton
        text="Retry"
        onClick={retry}
        color={BUTTON_COLORS.SKY}
      />),
    ]}
    dismiss={dismiss}
  />
);

const BadProcessorCredentialsModal = ({ dismiss, goToSettings }) => (
  <ModalAlert
    text={[
      'You don\'t have the necessary credentials to access your processor.',
      'You can provide them in the settings page.',
    ]}
    buttons={[
      (<MainButton
        text="Cancel"
        onClick={dismiss}
        color={BUTTON_COLORS.GREY}
      />),
      (<MainButton
        text="Go to Setup"
        onClick={goToSettings}
        color={BUTTON_COLORS.SKY}
      />),
    ]}
    dismiss={dismiss}
  />
);

const ModalErrors = ({
  modal, dismiss, decrypt, accept, goToSettings, retry, encrypt, goToDashboard,
}) => (
  modal && (
    <Modal dismiss={dismiss}>
      {modal === MODAL_ERRORS.DECRYPT
        && <DecryptDialogue decrypt={decrypt} />}
      {modal === MODAL_ERRORS.UNEXPECTED
        && <UnexpectedErrorDialogue accept={accept} />}
      {modal === MODAL_ERRORS.CANT_REACH_PROCESSOR
        && <CantReachProcessorDialogue goToSettings={goToSettings} retry={retry} />}
      {modal === MODAL_ERRORS.ENCRYPT
        && <EncryptDialogue encrypt={encrypt} />}
      {modal === MODAL_ERRORS.HAS_BEEN_CONFIGURED
        && <HasBeenConfiguredDialogue goToDashboard={goToDashboard} />}
      {modal === MODAL_ERRORS.BAD_DATA
        && <DataErrorModal retry={retry} />}
      {modal === MODAL_ERRORS.BAD_PROCESSOR_CREDENTIALS
        && <BadProcessorCredentialsModal goToSettings={goToSettings} />}
    </Modal>
  )
);

const dismissPropType = { dismiss: PropTypes.func };
const dismissDefaultValue = { dismiss: () => {} };

DecryptDialogue.propTypes = {
  decrypt: PropTypes.func.isRequired,
  ...dismissPropType,
};
DecryptDialogue.defaultValues = dismissDefaultValue;

EncryptDialogue.propTypes = {
  encrypt: PropTypes.func.isRequired,
  ...dismissPropType,
};
EncryptDialogue.defaultValues = dismissDefaultValue;

CantReachProcessorDialogue.propTypes = {
  goToSettings: PropTypes.func.isRequired,
  retry: PropTypes.func.isRequired,
  ...dismissPropType,
};
CantReachProcessorDialogue.defaultValues = dismissDefaultValue;

UnexpectedErrorDialogue.propTypes = {
  accept: PropTypes.func.isRequired,
  ...dismissPropType,
};
UnexpectedErrorDialogue.defaultValues = dismissDefaultValue;

NewURLDialogue.propTypes = {
  saveNewURL: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  ...dismissPropType,
};
NewURLDialogue.defaultValues = dismissDefaultValue;

HasBeenConfiguredDialogue.propTypes = {
  goToDashboard: PropTypes.func.isRequired,
  ...dismissPropType,
};
HasBeenConfiguredDialogue.defaultValues = dismissDefaultValue;

DataErrorModal.propTypes = {
  retry: PropTypes.func.isRequired,
  ...dismissPropType,
};
DataErrorModal.defaultValues = dismissDefaultValue;

BadProcessorCredentialsModal.propTypes = {
  ...dismissPropType,
  goToSettings: PropTypes.func.isRequired,
};
BadProcessorCredentialsModal.defaultValues = dismissDefaultValue;

ModalErrors.propTypes = {
  modal: PropTypes.oneOf(Object.values(MODAL_ERRORS)).isRequired,
  dismiss: PropTypes.func.isRequired,
  decrypt: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  goToSettings: PropTypes.func.isRequired,
  retry: PropTypes.func.isRequired,
  encrypt: PropTypes.func.isRequired,
  goToDashboard: PropTypes.func.isRequired,
};

export {
  MODAL_ERRORS, ModalErrors,
  DecryptDialogue,
  EncryptDialogue,
  CantReachProcessorDialogue,
  UnexpectedErrorDialogue,
  NewURLDialogue,
  HasBeenConfiguredDialogue,
  DataErrorModal,
  BadProcessorCredentialsModal,
};
