import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { Modal } from 'common/modal';
import { Loader } from 'common/loader';
import calibrateButton from 'assets/images/calibrate.png';
import inOutButton from 'assets/images/icons-door.svg';
import selectAreasButton from 'assets/images/icons-lasso-tool.svg';
import { TextInput } from 'common/textInput';
import { CameraController } from 'networking/controllers/camera-controller';
import { goToPage, routeNaming } from 'routes';
import { errorMessage } from 'helpers/error-helper';
import { SETUP_STEPS } from '../setupSteps';
import { AddButton, EditButton } from '../setupButtons';
import { CalibrationModal } from './calibrationModal';
import { AreaSelectModal } from './areaSelectModal';
import { InOutModal } from './inOutModal';
import styles from './setupCameras.module.scss';

const SetupCameras = ({ setSetupStep, isEditing, id }) => {
  const [cameras, setCameras] = useState([]);
  const [cameraName, setCameraName] = useState('');
  const [cameraPath, setCameraPath] = useState('');
  const [selectedCamera, setSelectedCamera] = useState('');
  const [loading, setLoading] = useState(true);
  const [showCalibrationModal, setShowCalibrationModal] = useState(false);
  const [showSelectAreasModal, setShowSelectAreasModal] = useState(false);
  const [showInOutModal, setShowInOutModal] = useState(false);
  const [error, setError] = useState('');
  const [removeId, setRemoveId] = useState('');
  const [cameraNameError, setCameraNameError] = useState('');

  const addCamera = (e) => {
    e.preventDefault();
    const array = [...cameras];
    const index = array.findIndex((camera) => camera.id === selectedCamera.id);
    if (index === -1) {
      array.push({
        cameraName,
        cameraPath,
        isChecked: false,
        newCamera: true,
        id: array.length.toString(),
      });
    } else {
      array[index].cameraName = cameraName;
      array[index].cameraPath = cameraPath;
      array[index].isChecked = false;
    }
    setCameras(array);
  };

  const loadCameras = useCallback(async () => {
    setLoading(true);
    const { cameras: processorCameras, errorCode } = await CameraController.getCameras();
    if (!errorCode) {
      setCameras(processorCameras);
      processorCameras.map((camera) => {
        if (camera.id === id) {
          setSelectedCamera(camera);
        }
        return {};
      });
    } else {
      setError(errorMessage(errorCode));
    }
    setLoading(false);
    return {};
  }, [id]);

  useEffect(() => {
    loadCameras();
  }, [loadCameras]);

  useEffect(() => {
    const deleteCamera = async () => {
      await CameraController.deleteCamera(removeId, false);
    };
    if (removeId) {
      deleteCamera();
      loadCameras();
    }
  }, [removeId, loadCameras]);

  useEffect(() => {
    if (selectedCamera) {
      setCameraName(selectedCamera.cameraName);
      setCameraPath(selectedCamera.cameraPath);
    }
  }, [selectedCamera]);

  const submitCameras = async (e) => {
    if (isEditing) addCamera(e);
    setLoading(true);
    const returnedCameras = [];
    const rebootProcessor = false;
    let cameraHasError = false;
    await Promise.all(cameras.map(async (camera) => {
      if (camera.newCamera) {
        const {
          obj: returnedCamera,
          errorCode,
        } = await CameraController.saveCamera(camera, rebootProcessor);
        if (!errorCode) {
          // eslint-disable-next-line no-param-reassign
          camera.newCamera = false;
          return returnedCameras.push(returnedCamera);
        }
        setSelectedCamera(returnedCamera);
        setError(errorMessage(errorCode));
        cameraHasError = true;
      } else {
        const {
          obj: returnedCamera,
          errorCode,
        } = await CameraController.editCamera(camera, rebootProcessor);
        if (!errorCode) {
          return returnedCameras.push(returnedCamera);
        }
        setSelectedCamera(returnedCamera);
        setError(errorMessage(errorCode));
        cameraHasError = true;
      }
      return {};
    }));
    setLoading(false);
    if (cameraHasError) return {};
    setCameras(returnedCameras);
    if (isEditing) {
      goToPage(routeNaming.DASHBOARD);
    } else {
      setSetupStep(SETUP_STEPS.AREAS);
    }
    return {};
  };

  const selectAreasModal = showSelectAreasModal
    ? (
      <Modal dismiss={() => setShowSelectAreasModal(false)}>
        <AreaSelectModal
          camera={selectedCamera}
          setShowSelectAreasModal={setShowSelectAreasModal}
        />
      </Modal>
    ) : null;

  const calibrationModal = showCalibrationModal
    ? (
      <Modal dismiss={() => setShowCalibrationModal(false)}>
        <CalibrationModal
          camera={selectedCamera}
          setShowCalibrationModal={setShowCalibrationModal}
        />
      </Modal>
    ) : null;

  const inOutModal = showInOutModal
    ? (
      <Modal dismiss={() => setShowInOutModal(false)}>
        <InOutModal
          camera={selectedCamera}
          setShowInOutModal={setShowInOutModal}
        />
      </Modal>
    ) : null;

  return (
    <>
      {inOutModal}
      {selectAreasModal}
      {calibrationModal}
      <div className={styles.container}>
        <h1 className={styles.title}>
          Connect your cameras
        </h1>
        <form className={styles.formContainer} onSubmit={addCamera}>
          <div className={styles.camerasContainer}>
            <div>
              <h2 className={styles.subtitle}>
                Camera Name
              </h2>
              <TextInput
                name="cameraName"
                placeholder="Type a name for your camera"
                onChange={(e) => setCameraName((e.target.validity.valid) ? e.target.value : cameraName && setCameraNameError("Valid characters: a-z, -, _ and '"))}
                value={cameraName}
                required
                pattern="[A-z _.0-9'-]+"
                error={cameraNameError}
              />
            </div>
            <div>
              <h2 className={styles.subtitle}>
                Camera Path
              </h2>
              <TextInput
                name="cameraPath"
                placeholder="Camera Path"
                onChange={(e) => { setCameraPath(e.target.value); setError(''); }}
                value={cameraPath}
                required
                error={error}
              />
            </div>
          </div>
          <div className={styles.addCameraButtonDiv}>
            {isEditing && (
              <>
                <button type="button" className={styles.inOutButton} onClick={() => setShowInOutModal(true)}>
                  IN/OUT&nbsp;BOUNDARY
                  <img src={inOutButton} alt="" />
                </button>
                <button type="button" className={styles.selectAreaButton} onClick={() => setShowSelectAreasModal(true)}>
                  SELECT&nbsp;AREA
                  <img src={selectAreasButton} alt="" />
                </button>
                <button type="button" className={styles.calibrateButton} onClick={() => setShowCalibrationModal(true)}>
                  CALIBRATE
                  <img src={calibrateButton} alt="" />
                </button>
              </>
            )}
            {
              selectedCamera
                ? <AddButton type="submit" noIcon> EDIT CAMERA </AddButton>
                : <AddButton type="submit"> ADD CAMERA </AddButton>
            }
          </div>
          <div className={styles.cameraListContainer}>
            <h2 className={styles.cameraList}>
              Camera List
            </h2>
            <div className={styles.cameraListButtonContainer}>
              {loading && (<Loader className={styles.loader} />)}
              {!loading && cameras.map((camera) => (
                <EditButton
                  text={camera.cameraName}
                  onClick={() => {
                    setError('');
                    setSelectedCamera(camera);
                  }}
                  setRemove={setRemoveId}
                  id={camera.id}
                  key={camera.cameraName}
                  isSelected={selectedCamera === camera}
                />
              ))}
            </div>
          </div>
        </form>
      </div>
      <div className={styles.stepButtonsDiv}>
        {isEditing ? (
          <>
            <MainButton
              className={styles.stepButton}
              type="button"
              onClick={() => { goToPage(routeNaming.DASHBOARD); }}
              color={BUTTON_COLORS.WHITE}
              text="CANCEL"
            />
            <MainButton
              className={styles.stepButton}
              type="submit"
              color={BUTTON_COLORS.SKY}
              text="FINISH"
              onClick={submitCameras}
              disabled={cameras.length === 0}
            />
          </>
        ) : (
          <>
            <MainButton
              className={styles.stepButton}
              type="button"
              onClick={() => { setSetupStep(SETUP_STEPS.CONNECT); }}
              color={BUTTON_COLORS.WHITE}
              text="PREV"
            />
            <MainButton
              className={styles.stepButton}
              type="submit"
              color={BUTTON_COLORS.SKY}
              text="NEXT"
              onClick={(e) => submitCameras(e)}
              disabled={cameras.length === 0}
            />
          </>
        )}
      </div>
    </>
  );
};

SetupCameras.propTypes = {
  setSetupStep: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  id: PropTypes.string,
};

SetupCameras.defaultProps = {
  id: '',
};

export { SetupCameras };
