import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { CameraController } from 'networking/controllers/camera-controller';

import { Loader } from 'common/loader';

import { PolygonCanvas, CANVAS_LINES, CANVAS_STATES } from 'common/polygonCanvas';
import { lanthornOrange } from 'assets/stylesheets/colors.scss';

import styles from './areaSelectModal.module.scss';

const STEPS = {
  STEP0: 0,
  STEP1: 1,
  STEP2: 2,
};

const canvasStateForStep = {
  [STEPS.STEP0]: CANVAS_STATES.OFF,
  [STEPS.STEP1]: CANVAS_STATES.ADDING,
  [STEPS.STEP2]: CANVAS_STATES.MOVING,
};

const AreaSelectModal = ({ camera, setShowSelectAreasModal }) => {
  const [imageURI, setImage] = useState();
  const [imgSize, setImgSize] = useState({ x: 300, y: 300 });
  const [finished, setFinished] = useState(false);
  const [step, setStep] = useState(STEPS.STEP0);
  const [loading, setLoading] = useState(true);
  const [squareCreated, setSquareCreated] = useState(false);
  const [existingPoints, setExistingPoints] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { image, errorCode: imageError } = await CameraController.getCalibrationImage(
        camera.id,
      );
      if (imageError) return;
      setImage(`data:image/jpeg;base64,${image}`);
      const { points, errorCode: pointsError } = await CameraController.getAreaOfInterestPoints(
        camera.id,
      );
      if (!pointsError) {
        setExistingPoints(points);
        setStep(STEPS.STEP2);
      }
      setLoading(false);
    };
    fetchData();
  }, [camera.id]);

  const imgElement = useRef(null);

  const onLoadImg = () => {
    setImgSize({ x: imgElement.current.naturalWidth, y: imgElement.current.naturalHeight });
  };

  const sendMatrix = async (points) => {
    const { errorCode: error } = await CameraController.setSelectedArea(camera.id, points);
    if (!error) return setShowSelectAreasModal(false);
    return setFinished(false);
  };

  const canvasConfig = {
    size: imgSize,
    minPoints: 3,
    maxPoints: 500,
    lineStyle: CANVAS_LINES.SEGMENTS,
    color: lanthornOrange,
    maskOutside: true,
  };

  return (
    <div className={styles.card}>
      {
        !loading
          ? (
            <PolygonCanvas
              state={canvasStateForStep[step]}
              returnCanMove={() => setSquareCreated(true)}
              returnPoints={finished && sendMatrix}
              startingPoints={existingPoints}
              config={canvasConfig}
            >
              <img alt="Camera background" src={imageURI} ref={imgElement} onLoad={onLoadImg} />
            </PolygonCanvas>
          ) : (
            <Loader className={styles.loader} />
          )
      }
      {step === STEPS.STEP0 && <Step0 setStep={setStep} />}
      {step === STEPS.STEP1 && <Step1 setStep={setStep} squareCreated={squareCreated} />}
      {step === STEPS.STEP2 && <Step2 setStep={setStep} setFinished={setFinished} />}
    </div>
  );
};

const Step0 = ({ setStep }) => {
  const setStepOnClick = () => setStep(STEPS.STEP1);
  return (
    <div className={styles.step0}>
      <p className={styles.text1}>Select an area</p>
      <p className={styles.text2}>
        You can select an area in the video feed to examine
        <br />
        and exclude everything else from the metrics.
      </p>
      <MainButton text="Start" onClick={setStepOnClick} color={BUTTON_COLORS.SKY} />
    </div>
  );
};

const Step1 = ({ setStep, squareCreated }) => {
  const setNextStep = () => setStep(STEPS.STEP2);
  const setPrevStep = () => setStep(STEPS.STEP0);
  return (
    <div className={styles.step0}>
      <p className={styles.text1}>
        Select any number of points on the image, clockwise.
      </p>
      <p className={styles.text2}>
        The lines may not cross one another.
        <br />
        There is no limit to the number of points you can select,
        <br />
        you can be as precise as you want.
      </p>
      <div className={styles.stepsButtonContainer}>
        <MainButton text="Prev" className={styles.prevButton} onClick={setPrevStep} color={BUTTON_COLORS.GREY} />
        <MainButton text="Next" onClick={setNextStep} disabled={!squareCreated} color={BUTTON_COLORS.SKY} />
      </div>
    </div>
  );
};

const Step2 = ({ setStep, setFinished }) => {
  const [sending, setSending] = useState(false);
  const setStepOnClick = () => setStep(STEPS.STEP1);
  const submitCanvasData = () => { setSending(true); setFinished(true); };
  return (
    <div>
      <p className={styles.text1}>
        You can adjust points by dragging them.
      </p>
      <div className={styles.stepsButtonContainer}>
        <MainButton text="Prev" className={styles.prevButton} onClick={setStepOnClick} color={BUTTON_COLORS.GREY} />
        <MainButton text="Finish" onClick={submitCanvasData} loading={sending} color={BUTTON_COLORS.SKY} />
      </div>
    </div>
  );
};

AreaSelectModal.propTypes = {
  camera: PropTypes.object.isRequired,
  setShowSelectAreasModal: PropTypes.func.isRequired,
};
Step0.propTypes = {
  setStep: PropTypes.func.isRequired,
};
Step1.propTypes = {
  setStep: PropTypes.func.isRequired,
  squareCreated: PropTypes.bool.isRequired,
};
Step2.propTypes = {
  setStep: PropTypes.func.isRequired,
  setFinished: PropTypes.func.isRequired,
};

export { AreaSelectModal, STEPS };
