import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Timeframe, TIMEFRAME_TYPES, timeframePropType } from 'models/timeframe';

import styles from './timeframePicker.module.scss';

const TimeframePicker = ({ timeframe, setTimeframe, className }) => {
  const containerClassName = `${styles.container} ${className}`;
  if (timeframe.type === TIMEFRAME_TYPES.HOURLY) {
    const changeHourlyTimeframe = (e) => {
      const date = e.target.value;
      if (dayjs(date).isAfter(dayjs(), 'day')) return;
      setTimeframe(new Timeframe({ ...timeframe, date }));
    };

    return (
      <div className={containerClassName}>
        <input
          type="date"
          value={timeframe.date}
          onChange={changeHourlyTimeframe}
          className={styles.dateInput}
          required
        />
      </div>
    );
  }

  if (timeframe.type === TIMEFRAME_TYPES.DAILY) {
    const changeDailyStartDate = (e) => {
      const startDate = e.target.value;
      if (dayjs(startDate).isAfter(dayjs(), 'day')) return;
      const endDate = dayjs(startDate).isAfter(timeframe.endDate)
        ? startDate : timeframe.endDate;
      setTimeframe(new Timeframe({ ...timeframe, startDate, endDate }));
    };

    const changeDailyEndDate = (e) => {
      const endDate = e.target.value;
      if (dayjs(endDate).isAfter(dayjs(), 'day')) return;
      const startDate = dayjs(endDate).isBefore(timeframe.startDate)
        ? endDate : timeframe.startDate;
      setTimeframe(new Timeframe({ ...timeframe, startDate, endDate }));
    };

    return (
      <div className={containerClassName}>
        <input
          type="date"
          value={timeframe.startDate}
          onChange={changeDailyStartDate}
          className={styles.dateInput}
          required
        />
        <div className={styles.divider} />
        <input
          type="date"
          value={timeframe.endDate}
          onChange={changeDailyEndDate}
          className={styles.dateInput}
          required
        />
      </div>
    );
  }

  const changeWeeklyTimeframe = (e) => {
    setTimeframe({ ...timeframe, weeksBack: parseInt(e.target.value, 10) });
  };
  return (
    <div className={containerClassName}>
      <select
        onChange={changeWeeklyTimeframe}
        className={styles.select}
        value={timeframe.weeksBack}
      >
        <option value={2}>Last 2 weeks</option>
        <option value={4}>Last 4 weeks</option>
        <option value={6}>Last 6 weeks</option>
        <option value={8}>Last 8 weeks</option>
        <option value={10}>Last 10 weeks</option>
        <option value={12}>Last 12 weeks</option>
        <option value={14}>Last 14 weeks</option>
        <option value={16}>Last 16 weeks</option>
      </select>
    </div>
  );
};

TimeframePicker.propTypes = {
  timeframe: timeframePropType.isRequired,
  setTimeframe: PropTypes.func.isRequired,
  className: PropTypes.string,
};

TimeframePicker.defaultProps = {
  className: '',
};

export { TimeframePicker };
