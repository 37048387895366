import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './cameraCount.module.scss';

const CameraCount = ({ camerasFromSelectedAreas, ids }) => (
  <div className={styles.card}>
    <h3 className={styles.title}>Camera count</h3>
    <div className={styles.box}>
      <span className={styles.cameraCount}>{camerasFromSelectedAreas.length}</span>
    </div>
    <span className={styles.text}>
      Cameras in this area
      {ids.length > 1 && 's'}
    </span>
  </div>

);

CameraCount.propTypes = {
  camerasFromSelectedAreas: PropTypes.array.isRequired,
  ids: PropTypes.array.isRequired,
};

export { CameraCount };
