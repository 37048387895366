import { ApiService } from 'networking/api-service';
import { COUNTRY_API } from 'networking/api-routes';
import { ERRORS } from 'helpers/error-helper';

class CountryController {
  static async getCountries() {
    try {
      const countryResponse = await ApiService.get(COUNTRY_API.ALL);
      if (!countryResponse.data) {
        throw new Error();
      }
      const countries = countryResponse.data.sort((a, b) => a.name.localeCompare(b.name));
      return { countries };
    } catch (error) {
      return { errorCode: ERRORS.OTHER.COUNTRY_API_ERROR };
    }
  }
}

export { CountryController };
