import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { CameraController } from 'networking/controllers/camera-controller';

import next from 'assets/images/next.svg';
import { Loader } from 'common/loader';

import styles from './cameraView.module.scss';

const CameraView = ({ camerasFromSelectedAreas }) => {
  const [cameraIndex, setCameraIndex] = useState(0);
  const [imageURI, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(camerasFromSelectedAreas[0]);
  const [error, setError] = useState('');

  useEffect(() => {
    const getImage = async () => {
      setLoading(true);
      const { image, errorCode } = await CameraController.getImage(selectedCamera.id);
      if (!errorCode) setImage(`data:image/jpeg;base64,${image}`);
      else {
        setError('Can\'t retrieve the camera screenshot');
      }
      setLoading(false);
    };
    getImage();
  }, [selectedCamera]);

  useEffect(() => {
    setSelectedCamera(camerasFromSelectedAreas[cameraIndex]);
  }, [cameraIndex, camerasFromSelectedAreas]);

  const nextCamera = () => {
    if (cameraIndex === camerasFromSelectedAreas.length - 1) {
      setCameraIndex(0);
    } else {
      setCameraIndex(cameraIndex + 1);
    }
  };

  const prevCamera = () => {
    if (cameraIndex === 0) {
      setCameraIndex(camerasFromSelectedAreas.length - 1);
    } else {
      setCameraIndex(cameraIndex - 1);
    }
  };

  return (
    <div className={styles.card}>
      <h3 className={styles.title}>Camera view</h3>
      <div>
        {
          loading
            ? <Loader className={styles.loader} />
            : <img src={imageURI} className={styles.cameraImage} alt="" />
        }
        {error && <span>{error}</span>}
      </div>
      <div className={styles.footer}>
        <button type="button" className={styles.leftChevron} onClick={() => prevCamera()}>
          <img src={next} alt="" />
        </button>
        <span className={styles.text}>{selectedCamera.cameraName}</span>
        <button type="button" className={styles.rightChevron} onClick={() => nextCamera()}>
          <img src={next} alt="" />
        </button>
      </div>
    </div>
  );
};

CameraView.propTypes = {
  camerasFromSelectedAreas: PropTypes.array.isRequired,
};

export { CameraView };
