import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import eyeIcon from 'assets/images/eye.png';

import { Modal } from 'common/modal';
import { ViewCameraModal } from '../viewCameraModal';
import styles from './cameraList.module.scss';

const CameraList = ({ camerasFromSelectedAreas }) => {
  const [cameras, setCameras] = useState(camerasFromSelectedAreas.slice(0, 6));
  const [tableIndex, setTableIndex] = useState(0);
  const [inViewCamera, setInViewCamera] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState();
  const rowsDisplayed = 6;

  const viewCamera = (camera) => {
    setSelectedCamera(camera);
    setInViewCamera(true);
  };

  const nextTable = () => {
    setTableIndex(tableIndex + rowsDisplayed);
  };

  const prevTable = () => {
    setTableIndex(tableIndex - rowsDisplayed);
  };

  useEffect((() => {
    setCameras(camerasFromSelectedAreas.slice(tableIndex, tableIndex + rowsDisplayed));
  }), [tableIndex, camerasFromSelectedAreas]);

  const viewCameraModal = inViewCamera && (
    <Modal dismiss={() => setInViewCamera(false)}>
      <ViewCameraModal camera={selectedCamera} />
    </Modal>
  );

  const camerasTable = (
    <table>
      <tbody>
        {
          cameras.map((camera) => (
            <tr key={camera.id}>
              <td className={styles.name}>
                {camera.cameraName}
                <button type="button" className={styles.button} onClick={() => viewCamera(camera)}>
                  WATCH
                  <img alt="view camera" src={eyeIcon} />
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );

  return (
    <div className={styles.card}>
      <h3 className={styles.title}>Camera list in this area</h3>
      {viewCameraModal}
      {camerasTable}
      <div className={styles.footer}>
        {tableIndex > 1 && (
          <button type="button" className={styles.prevButton} onClick={() => prevTable()}>&lt;- PREV</button>
        )}
        {camerasFromSelectedAreas.length > rowsDisplayed && cameras.length === rowsDisplayed && (
          <button type="button" className={styles.nextButton} onClick={() => nextTable()}>NEXT -&gt;</button>
        )}
      </div>
    </div>
  );
};

CameraList.propTypes = {
  camerasFromSelectedAreas: PropTypes.array.isRequired,
};

export { CameraList };
