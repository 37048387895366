import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'common/checkbox';
import { NaturalInput } from 'common/naturalInput';
import trashIcon from 'assets/images/trash.svg';
import { ReactComponent as WarningSign } from 'assets/images/warning-sign.svg';
import { OCCUPANCY_RULE_ERRORS } from 'models/occupancyRule';
import { occupancyRulesRed, occupancyRulesYellow, occupancyRulesOrange } from 'assets/stylesheets/colors.scss';
import styles from './occupancyRuleCard.module.scss';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const timerangeHours = [...new Array(24).keys()];
const timerangeMinutes = [0, 15, 30, 45];
const ruleFields = {
  OCCUPANCY: 'occupancy',
  FROM_H: 'from_h',
  FROM_M: 'from_m',
  TO_H: 'to_h',
  TO_M: 'to_m',
};

const errorColor = (error) => {
  if (error === OCCUPANCY_RULE_ERRORS.USELESS) return occupancyRulesYellow;
  if (error === OCCUPANCY_RULE_ERRORS.INVALID) return occupancyRulesOrange;
  return occupancyRulesRed;
};

const titleForError = (error) => {
  if (error === OCCUPANCY_RULE_ERRORS.USELESS) return 'Select at least one day for this rule to apply to.';
  if (error === OCCUPANCY_RULE_ERRORS.INVALID) return 'Rules must start at an earlier time than they end.\nHint:\nIf you want to set a rule from 11 pm to 5 am, split it into a rule from 11 pm to midnight on the day where the limit starts (i.e. Friday) and from midnight to 5 am on the day where the limit ends (i.e. Saturday).';
  return 'This rule conflicts with another one.';
};

const OccupancyRuleCard = ({
  rule, ruleIndex, setRule, deleteRule,
}) => {
  const toggleDayCheckbox = (event) => {
    const day = event.target.value;
    const newRule = { ...rule, error: null };
    newRule.days[day] = !newRule.days[day];
    setRule(newRule);
  };

  const setField = (field) => (value) => {
    const newRule = { ...rule, error: null };
    switch (field) {
      case ruleFields.OCCUPANCY: newRule.occupancy = value; break;
      case ruleFields.FROM_H: newRule.from.h = value; break;
      case ruleFields.FROM_M: newRule.from.m = value; break;
      case ruleFields.TO_H: newRule.to.h = value; break;
      case ruleFields.TO_M: newRule.to.m = value; break;
      default:
    }
    setRule(newRule);
  };

  return (
    <div
      className={styles.occupancyRuleContainer}
      style={rule.error ? { border: `solid 1px ${errorColor(rule.error)}` } : {}}
    >
      <div className={styles.occupancy}>
        Occupancy&nbsp;Threshold:&nbsp;
        <NaturalInput
          value={rule.occupancy}
          setValue={setField(ruleFields.OCCUPANCY)}
        />
      </div>
      <div className={styles.timerange}>
        <div className={styles.hours}>
          <div className={styles.hour}>
            <div className={styles.hourTitle}>
              From:&nbsp;
            </div>
            <div className={styles.hourFields}>
              <NaturalInput
                value={rule.from.h}
                setValue={setField(ruleFields.FROM_H)}
                steps={timerangeHours}
                digits={2}
              />
              &nbsp;:&nbsp;
              <NaturalInput
                value={rule.from.m}
                setValue={setField(ruleFields.FROM_M)}
                steps={timerangeMinutes}
                digits={2}
              />
            </div>
          </div>
          <div className={styles.hour}>
            <div className={styles.hourTitle}>
              To:&nbsp;
            </div>
            <div className={styles.hourFields}>
              <NaturalInput
                value={rule.to.h}
                setValue={setField(ruleFields.TO_H)}
                steps={timerangeHours}
                digits={2}
              />
              &nbsp;:&nbsp;
              <NaturalInput
                value={rule.to.m}
                setValue={setField(ruleFields.TO_M)}
                steps={timerangeMinutes}
                digits={2}
              />
            </div>
          </div>
        </div>
        <div className={styles.days}>
          <div className={styles.daysTitle}>
            Days:&nbsp;&nbsp;
          </div>
          <div className={styles.daysFields}>
            {
              days.map((day, i) => (
                <Checkbox
                  value={i}
                  label="bottom"
                  text={day[0]}
                  id={`rule${ruleIndex}-${days[i]}checkbox`}
                  key={`rule${ruleIndex}-${days[i]}checkbox`}
                  checked={rule.days[i]}
                  onClick={toggleDayCheckbox}
                  className={styles.checkboxContainer}
                />
              ))
            }
          </div>
        </div>
      </div>
      <button type="button" className={styles.deleteButton} onClick={deleteRule}>
        <img className={styles.cursor} src={trashIcon} alt="trash" />
      </button>
      {
        rule.error && (
          <div className={styles.warningSign} title={titleForError(rule.error)}>
            <WarningSign fill={errorColor(rule.error)} />
          </div>
        )
      }
    </div>
  );
};

OccupancyRuleCard.propTypes = {
  rule: PropTypes.object.isRequired,
  ruleIndex: PropTypes.number.isRequired,
  setRule: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
};

export { OccupancyRuleCard };
