import React, { useEffect, useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';

import { ReportsController } from 'networking/controllers/reports-controller';
import {
  historicGraphType, historicGraphData, historicGraphOptions,
} from 'helpers/historic-graph-helper';

import { Chart } from 'common/chart';
import { facemaskGreen, facemaskRed, facemaskGrey } from 'assets/stylesheets/colors.scss';

import { MetricCard } from '../metricCard';

import styles from './facemaskCard.module.scss';

const graphItems = [
  { label: 'With facemask', dataName: 'facemask', backgroundColor: facemaskGreen },
  { label: 'Unrecognized', dataName: 'unknown', backgroundColor: facemaskGrey },
  { label: 'Without facemask', dataName: 'noFacemask', backgroundColor: facemaskRed },
];

const liveOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
};

const generateLiveFacemaskData = (stats, shown) => ({
  datasets: [{
    data: [
      shown[0] && (stats?.facemask || 0),
      shown[2] && (stats?.unknown || 0),
      shown[1] && (stats?.noFacemask || 0),
    ],
    backgroundColor: graphItems.map((i) => i.backgroundColor),
  }],
  labels: graphItems.map((i) => i.label),
});

const FacemaskCard = ({ timeframe, showAreas, ids }) => {
  const [shownItems, setShownItems] = useState([true, true, true]);
  const [liveData, setLiveData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [historicData, setHistoricData] = useState({});
  const [processedHistoricData, setProcessedHistoricData] = useState({});
  const [historicChartType, setHistoricChartType] = useState('bar');
  const [loadingLive, setLoadingLive] = useState(true);
  const [loadingHistoric, setLoadingHistoric] = useState(true);

  useEffect(() => {
    const updateLiveData = async () => {
      const {
        data, lastUpdated: recievedLastUpdated, errorCode,
      } = await ReportsController.getLiveFacemaskStats(showAreas, ids);
      if (errorCode) return null;
      setLastUpdated(recievedLastUpdated);
      setLiveData(data);
      return setLoadingHistoric(false);
    };

    const updateHistoricData = async () => {
      const { data, errorCode } = await ReportsController.getHistoricFacemaskData(
        showAreas, ids, timeframe,
      );
      if (errorCode) return null;
      setHistoricChartType(historicGraphType(timeframe));
      setHistoricData(data);
      setShownItems((s) => [...s]);
      return setLoadingLive(false);
    };

    setLoadingHistoric(true);
    setLoadingLive(true);

    updateLiveData();
    updateHistoricData();
  }, [timeframe, showAreas, ids]);

  useEffect(() => setProcessedHistoricData(historicGraphData(
    timeframe,
    graphItems.map((item) => item.label),
    graphItems.map((item) => historicData[item.dataName]),
    graphItems.map((item) => item.backgroundColor),
    graphItems.map((item) => `${item.backgroundColor}80`),
  )), [shownItems, historicData, timeframe]);

  const handleButton = (metric) => {
    const changed = graphItems.findIndex((i) => i.label === metric);
    const newShownItems = [...shownItems];
    newShownItems[changed] = !newShownItems[changed];
    setShownItems(newShownItems);
  };

  const historicGraph = (
    <Chart
      type={historicChartType}
      data={processedHistoricData}
      options={historicGraphOptions()}
    />
  );

  return (
    <MetricCard
      title="Facemask"
      tooltip="Facemask detection is displayed in two ways. Real-time and historical.
        The real-time display shows the number of people with masks, without them, and the number of human detections whose faces were not visible.
        The historical daily review of facemasks indicates high-risk hours."
      graph={!loadingHistoric && historicGraph}
      lastUpdated={loadingLive ? '' : lastUpdated}
    >
      {!loadingLive && (
        <div className={styles.live}>
          <div className={styles.liveChart}>
            <Chart type="doughnut" data={generateLiveFacemaskData(liveData, shownItems)} options={liveOptions} />
          </div>
          <div className={styles.controls}>
            {
              graphItems.map((item, index) => (
                <button
                  type="button"
                  key={item.label}
                  className={styles.control}
                  onClick={() => handleButton(item.label)}
                >
                  <div
                    className={styles.color}
                    style={{
                      backgroundColor: shownItems[index] ? item.backgroundColor : 'white',
                      borderColor: item.backgroundColor,
                    }}
                  />
                  <div className={styles.label}>
                    {item.label.split(' ').map((line) => (
                      <Fragment key={`item-${item.label}-line-${line}`}>
                        {line}
                        <br />
                      </Fragment>
                    ))}
                  </div>
                </button>
              ))
            }
          </div>
        </div>
      )}
    </MetricCard>
  );
};

FacemaskCard.propTypes = {
  timeframe: PropTypes.object.isRequired,
  showAreas: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { FacemaskCard };
