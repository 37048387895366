import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import h337 from 'heatmap.js';
import * as dayjs from 'dayjs';
import { CameraController } from 'networking/controllers/camera-controller';
import { Modal } from 'common/modal';
import { ModalDates } from 'common/modalDates';
import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { Toggle } from 'common/toggle';
import styles from './heatmap.module.scss';

const initDates = () => ({
  from: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
  to: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
});

const Heatmap = ({ camera }) => {
  const [, updateHeatmap] = useState(undefined);
  const [imageURI, setImage] = useState('');
  const [dates, setDates] = useState(initDates());
  const [showModalDates, setShowModalDates] = useState(false);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getImage = async () => {
      const { image, errorCode: error } = await CameraController.getImage(camera.id);
      if (!error) setImage(`data:image/jpeg;base64,${image}`);
    };

    const getHeatmap = async () => {
      setLoading(true);
      const { from, to } = dates;
      const { heatmap, errorCode: error } = await CameraController.getHeatmap(
        camera.id, from, to,
      );
      if (error) return setLoading(false);
      const x = heatmap.length;
      const y = heatmap[0].length;
      const points = [];
      let max = 0;
      for (let i = 0; i < x; i += 1) {
        for (let j = 0; j < y; j += 1) {
          if (heatmap[i][j] > 0) {
            max = heatmap[i][j] > max ? heatmap[i][j] : max;
            points.push({
              x: (i * 540) / x,
              y: (j * 405) / y,
              value: heatmap[i][j],
            });
          }
        }
      }
      const initHeatmap = h337.create({
        container: document.querySelector('.heatmapjs'),
        gradient: {
          '.1': 'blue',
          '.45': 'purple',
          '.75': '#FF4500',
          '.9': 'orange',
          '.99': '#FFDD00',
        },
        maxOpacity: 0.7,
      });
      updateHeatmap(initHeatmap.setData({
        max,
        data: points,
      }));
      setLoading(false);
    };

    if (camera) {
      getImage();
      getHeatmap();
    }
  }, [dates, camera]);

  const applyDates = (newDates) => {
    setShowModalDates(false);
    setDates(newDates);
  };

  return (
    <div>
      <div className={styles.heatmapContainer}>
        <div
          style={
            showHeatmap ? { height: '100%', width: '100%' } : { opacity: '0', visibility: 'hidden' }
          }
          className="heatmapjs"
        >
          <img alt="Camera background" className={styles.canvasBackgroundImage} src={imageURI} />
        </div>
        {!showHeatmap
        && <img alt="Camera background" className={styles.backgroundImage} src={imageURI} />}
      </div>
      <div className={styles.toggleDiv}>
        <h2 className={styles.toggleText}>
          Show heatmap
        </h2>
        <Toggle value={showHeatmap} setValue={setShowHeatmap} />
      </div>
      <div className={styles.dateContainer}>
        <div className={styles.dateText}>
          From:&nbsp;
          <b>{dayjs(dates.from).format('YYYY.MM.DD')}</b>
          &nbsp;To:&nbsp;
          <b>{dayjs(dates.to).format('YYYY.MM.DD')}</b>
        </div>
        <MainButton
          text="Change heatmap dates"
          onClick={() => setShowModalDates(true)}
          loading={loading}
          className={styles.dateButton}
          color={BUTTON_COLORS.SKY}
        />
      </div>
      {showModalDates
      && (
        <Modal dismiss={() => setShowModalDates(false)}>
          <ModalDates dates={dates} applyFn={applyDates} />
        </Modal>
      )}
    </div>
  );
};

Heatmap.propTypes = {
  camera: PropTypes.object.isRequired,
};

export { Heatmap };
