class GlobalReportsSerializer {
  static deSerialize(data) {
    return {
      emails: data.emails,
      time: data.time,
      daily: data.daily,
      weekly: data.weekly,
    };
  }

  static serialize(emails, daily, weekly) {
    return {
      emails,
      daily,
      weekly,
    };
  }
}

export { GlobalReportsSerializer };
