const wc = window.crypto;

const stringToUint8Array = (string) => new Uint8Array([...string].map((c) => c.charCodeAt()));
const uint8ArrayToString = (array) => String.fromCharCode(...array);

const deriveAES256Key = async (password, salt, iterations, keyUsages) => {
  const utf8Password = stringToUint8Array(password);
  const importedPassword = await wc.subtle.importKey(
    'raw', utf8Password, 'PBKDF2', false, ['deriveKey'],
  );

  const derivedKey = await wc.subtle.deriveKey(
    {
      name: 'PBKDF2', salt, iterations, hash: { name: 'SHA-256' },
    },
    importedPassword,
    { name: 'AES-GCM', length: 256 },
    false,
    keyUsages,
  );

  return derivedKey;
};

const encrypt = async (password, data) => {
  const utf8Data = stringToUint8Array(data);

  const salt = wc.getRandomValues(new Uint8Array(32));
  const iv = wc.getRandomValues(new Uint8Array(12));

  const iterations = 250000;
  const encodedIterations = stringToUint8Array(`${iterations}`.padStart(20, '0'));
  const derivedKey = await deriveAES256Key(password, salt, iterations, ['encrypt']);

  const encryptedByteData = await wc.subtle.encrypt(
    { name: 'AES-GCM', iv }, derivedKey, utf8Data,
  );
  const encryptedUTF8Data = new Uint8Array(encryptedByteData);
  const encryptedUTF8Output = new Uint8Array([
    ...encodedIterations, ...salt, ...iv, ...encryptedUTF8Data,
  ]);

  return uint8ArrayToString(encryptedUTF8Output);
};

const decrypt = async (password, data) => {
  const utf8Data = stringToUint8Array(data);

  const iterations = parseInt(uint8ArrayToString(utf8Data.slice(0, 20)), 10);
  const salt = utf8Data.slice(20, 52);
  const iv = utf8Data.slice(52, 64);
  const isolatedData = utf8Data.slice(64);

  const derivedKey = await deriveAES256Key(password, salt, iterations, ['decrypt']);

  const decryptedByteData = await wc.subtle.decrypt(
    { name: 'AES-GCM', iv }, derivedKey, isolatedData,
  );
  const decryptedUTF8Data = new Uint8Array(decryptedByteData);

  return uint8ArrayToString(decryptedUTF8Data);
};

export { encrypt, decrypt };
