import React from 'react';
import { SessionController } from 'networking/controllers/session-controller';

import { TextInput } from 'common/textInput';
import { LoginCard } from 'common/loginCard';
import { routeNaming, goToPage } from 'routes';
import { ParamsHelper } from 'helpers/params-helper';
import { PasswordHelper } from 'helpers/password-helper';
import { MainButton } from 'common/buttons';
import { errorMessage } from 'helpers/error-helper';

import styles from './passwordRecovery.module.scss';

class PasswordRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      error: false,
      message: '\u200b',
      score: 0,
      loading: false,
    };
    this.passwordChange = this.passwordChange.bind(this);
    this.passwordRecovery = this.passwordRecovery.bind(this);
    this.resetPasswordToken = ParamsHelper.parseQueryParams(
      document.location.search,
    ).reset_password_token;
    if (!this.resetPasswordToken || this.resetPasswordToken === '') {
      goToPage(routeNaming.LOGIN);
    }
  }

  async passwordRecovery(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const { password, score } = this.state;
    if (score < 30) {
      return this.setState({
        error: true,
        message: 'Password score is not high enough.',
        loading: false,
      });
    }
    const { errorCode: error } = await SessionController.passwordRecovery(
      password, this.resetPasswordToken,
    );
    if (error) {
      return this.setState({
        error,
        message: errorMessage(error),
        loading: false,
      });
    }
    return goToPage(routeNaming.LOGIN);
  }

  passwordChange(event) {
    if (PasswordHelper.nonControlASCIICheck(event.target.value)) {
      this.setState({
        password: event.target.value,
        error: false,
        score: PasswordHelper.passwordScore(event.target.value),
      });
    } else {
      this.setState({
        error: true,
        message: 'Unauthorized character.',
      });
    }
  }

  render() {
    const {
      password, error, message, score, loading,
    } = this.state;

    const scoreMessageComponent = (
      <p
        style={{
          color: PasswordHelper.getPasswordScoreAndStyle(score).color,
          visibility: score > 0 ? '' : 'hidden',
        }}
        className={styles.scoreContainer}
      >
        {PasswordHelper.getPasswordScoreAndStyle(score).value}
      </p>
    );

    const errorMessageComponent = (
      <p
        className={styles.errorMessage}
        style={{ visibility: error ? '' : 'hidden' }}
      >
        {message}
      </p>
    );

    return (
      <LoginCard>
        <h1 className={styles.title}>Password recovery</h1>
        <div className={styles.subTitle}>
          Enter your new password
        </div>
        <form className={styles.form} onSubmit={this.passwordRecovery}>
          <TextInput
            password
            placeholder="Password"
            onChange={this.passwordChange}
            value={password}
            minLength={8}
            style={{ marginTop: '54px' }}
            fat
            required
          />
          {scoreMessageComponent}
          {errorMessageComponent}
          <MainButton
            type="submit"
            id="submit"
            text="Continue"
            loading={loading}
            style={{ marginTop: '10px', marginBottom: '88px' }}
            wide
          />
        </form>
      </LoginCard>
    );
  }
}

export { PasswordRecovery };
