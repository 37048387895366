import React from 'react';
import { loadProcessorURL } from 'helpers/cookie-helper';
import { withCloudConfig } from 'hocs/with-config';
import { ReactComponent as ExternalLinkIcon } from 'assets/images/external-link.svg';
import styles from './footer.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    <a href={`${loadProcessorURL()}/docs`} target="_blank" rel="noopener noreferrer" className={styles.link}>
      Go to API Documentation
      <ExternalLinkIcon stroke="#8c9cb4" fill="#8c9cb4" height="12px" width="20px" />
    </a>
  </div>
);

const WrappedFooter = withCloudConfig(Footer);

export { WrappedFooter as Footer };
