import { ProcessorService } from 'networking/processor-service';
import { PROCESSOR_ROUTES } from 'networking/processor-routes';
import { ProcessorController } from 'networking/controllers/processor-controller';
import { CameraSerializer } from 'networking/serializers/camera-serializer';
import { Camera } from 'models/camera';
import { ERRORS } from 'helpers/error-helper';
import { MatrixSerializer } from 'networking/serializers/matrix-serializer';
import { CameraAreaSerializer } from 'networking/serializers/camera-area-serializer';
import { InOutBoundarySerializer } from 'networking/serializers/in-out-boundary-serializer';

class CameraController {
  static async getCameras() {
    try {
      const response = await ProcessorService.get(PROCESSOR_ROUTES.CAMERAS);
      const cameras = response.data.cameras.map(
        (camera) => new Camera(CameraSerializer.deSerialize(camera)),
      );
      return { cameras, errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async saveCamera(camera, rebootProcessor) {
    const serializedCamera = CameraSerializer.serialize(camera);
    try {
      const response = await ProcessorService
        .post(`${PROCESSOR_ROUTES.CAMERAS}?rebootProcessor=${rebootProcessor}`, serializedCamera);
      const deSerializedCamera = CameraSerializer.deSerialize(response.data);
      return { obj: new Camera(deSerializedCamera), errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async editCamera(camera, rebootProcessor) {
    const serializedCamera = CameraSerializer.serialize(camera);
    try {
      const response = await ProcessorService
        .put(`${PROCESSOR_ROUTES.CAMERAS_CAMID(serializedCamera.id)}?rebootProcessor=${rebootProcessor}`, serializedCamera);
      const deSerializedCamera = CameraSerializer.deSerialize(response.data);
      return { obj: new Camera(deSerializedCamera), errorCode: false };
    } catch (error) {
      if (error.body) {
        try {
          return {
            errorMessage: error.message,
            camera: new Camera(CameraSerializer.deSerialize(error.body)),
          };
        } catch (serializerError) {
          return { errorMessage: ERRORS.LOCAL.BAD_DATA };
        }
      }
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async deleteCamera(cameraId, rebootProcessor) {
    try {
      return await ProcessorService
        .delete(`${PROCESSOR_ROUTES.CAMERAS_CAMID(cameraId)}?rebootProcessor=${rebootProcessor}`);
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getImage(cameraId) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.CAMERA_IMAGE(cameraId));
      return { errorCode: false, message: 'Snapshot recieved.', image: response.data.image };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async enableVideoFeed(cameraId) {
    try {
      await ProcessorService
        .put(PROCESSOR_ROUTES.ENABLE_VIDEO_FEED(cameraId));
      return { errorCode: false };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async isVideoFeedEnabled(cameraId) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.VIDEO_FEED_ENABLED(cameraId));
      return { errorCode: false, enabled: response.data.enabled };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getCalibrationImage(cameraId) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.CALIBRATION_IMAGE(cameraId));
      return { errorCode: false, image: response.data.image };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getAreaOfInterestPoints(cameraId) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.CAMERA_AREA(cameraId));
      return { errorCode: false, points: CameraAreaSerializer.deSerialize(response.data) };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getInOutPoints(cameraId) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.IN_OUT_BOUNDARY(cameraId));
      return {
        errorCode: false,
        points: InOutBoundarySerializer.deSerialize(response.data.in_out_boundaries),
      };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async setCalibrationMatrix(cameraId, points) {
    const serializedMatrix = MatrixSerializer.serialize(points);
    try {
      return await ProcessorService
        .post(PROCESSOR_ROUTES.CALIBRATION_MATRIX(cameraId), serializedMatrix);
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async setSelectedArea(cameraId, points) {
    const serializedCameraArea = CameraAreaSerializer.serialize(points);
    try {
      return await ProcessorService
        .put(PROCESSOR_ROUTES.CAMERA_AREA(cameraId), serializedCameraArea);
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async setInOutPoints(cameraId, points) {
    const serializedPoints = InOutBoundarySerializer.serialize(points, cameraId);
    try {
      return await ProcessorService
        .put(PROCESSOR_ROUTES.IN_OUT_BOUNDARY(cameraId), serializedPoints);
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }

  static async getHeatmap(cameraId, from, to) {
    try {
      const response = await ProcessorService
        .get(PROCESSOR_ROUTES.HEATMAP_DATA(cameraId), { from_date: from, to_date: to });
      return { errorCode: false, message: 'Data recieved.', heatmap: response.data.heatmap };
    } catch (error) {
      return ProcessorController.handleProcessorError(error);
    }
  }
}

export { CameraController };
