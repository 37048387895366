import React from 'react';

import { MainButton, BUTTON_COLORS } from 'common/buttons';
import { LoginCard } from 'common/loginCard';
import { goToPage, routeNaming } from 'routes';

import styles from './passwordRecoverySent.module.scss';

const PasswordRecoverySent = () => (
  <LoginCard>
    <div className={styles.cardTitle}>
      Thank you!
    </div>
    <div className={styles.subTitle}>
      We&apos;ve sent password reset instructions to your email address.
      If no email is received within ten minutes,
      check that the submitted address is correct.
    </div>
    <MainButton
      text="Continue"
      onClick={() => goToPage(routeNaming.LOGIN)}
      className={styles.button}
      color={BUTTON_COLORS.SKY}
      wide
    />
  </LoginCard>
);

export { PasswordRecoverySent };
