import dayjs from 'dayjs';
import { TIMEFRAME_TYPES } from 'models/timeframe';
import { twilightBlue } from 'assets/stylesheets/colors.scss';

const sharedTicksProperties = {
  fontFamily: 'Work Sans',
  fontStyle: '500',
  fontSize: 10,
  fontColor: twilightBlue,
};

const historicGraphType = (timeframe) => {
  if (timeframe.type === TIMEFRAME_TYPES.HOURLY) return 'line';
  if (timeframe.type === TIMEFRAME_TYPES.WEEKLY) return 'bar';
  if (dayjs(timeframe.endDate).diff(timeframe.startDate, 'days') > 30) return 'line';
  return 'bar';
};

const labelsFromTimeframe = (timeframe) => {
  if (timeframe.type === TIMEFRAME_TYPES.HOURLY) {
    return Array.from(Array(24)).map((_, i) => {
      const stringLabel = i < 13 ? `${i}` : `${i - 12}`;
      const primaryLabel = stringLabel.length > 1 ? stringLabel : `0${stringLabel}`;
      let secondaryLabel;
      switch (i) {
        case 0: secondaryLabel = 'AM'; break;
        case 12: secondaryLabel = 'PM'; break;
        default: secondaryLabel = '';
      }
      return [primaryLabel, secondaryLabel];
    });
  }
  if (timeframe.type === TIMEFRAME_TYPES.DAILY) {
    const length = dayjs(timeframe.endDate).diff(timeframe.startDate, 'days') + 1;
    return Array.from(Array(length)).map((_, i) => {
      const day = dayjs(timeframe.startDate).add(i, 'days');
      return [day.format('dddd'), day.format('MM.DD')];
    });
  }
  const thisMonday = dayjs().day() === 0
    ? dayjs().subtract(6, 'days')
    : dayjs().subtract(dayjs().day(), 'days').add(1, 'day');
  return Array.from(Array(timeframe.weeksBack)).map((_, i) => {
    const weekStart = thisMonday.subtract(i, 'weeks');
    const weekEnd = weekStart.add(6, 'days');
    return ([
      `Week ${timeframe.weeksBack - i}`,
      `${weekStart.format('MM.DD')} - ${weekEnd.format('MM.DD')}`,
    ]);
  }).reverse();
};

const historicGraphData = (timeframe, names, data, borderColors, backgroundColors) => ({
  labels: labelsFromTimeframe(timeframe),
  datasets: data.map((_, i) => ({
    label: names[i],
    data: data[i],
    borderColor: borderColors[i],
    backgroundColor: !backgroundColors || historicGraphType(timeframe) === 'bar'
      ? borderColors[i]
      : backgroundColors[i],
    maxBarThickness: 50,
    fill: i === 0 ? 'start' : '-1',
    pointBackgroundColor: '#FFFFFF00',
    pointBorderColor: '#FFFFFF00',
  })),
});

const historicGraphOptions = () => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      stacked: true,
      ticks: { ...sharedTicksProperties },
    }],
    yAxes: [{
      stacked: true,
      ticks: { beginAtZero: true, ...sharedTicksProperties },
      gridLines: { display: false },
    }],
  },
  legend: {
    labels: {
      ...sharedTicksProperties,
      fontSize: 12,
      fontWeight: '400',
    },
    position: 'bottom',
  },
});

export { historicGraphType, historicGraphData, historicGraphOptions };
