const {
  min, max, PI, sqrt, atan2, hypot, abs, floor,
} = Math;

const CANVAS_LINES = {
  NONE: 'none',
  SEGMENTS: 'segments',
  INFINITE: 'infinite',
};

const lineWidth = 2;
const pointDiameter = 5;
const pointClickDiameter = pointDiameter + 5;
const lineClickDistance = 5;
const canvasMargin = 0;
const selectedSize = 5;
const selectedPointDiameter = pointDiameter + selectedSize;
const selectedLineWidth = lineWidth + selectedSize * 2;

const arrowTriangleSide = 15;
const arrowStemLength = 10;
const arrowToLineSpace = 8;

const calculateSlope = (p1, p2) => (p1.y - p2.y) / (p1.x - p2.x);

// Draw a dot on a point
const drawPoint = (context, point, diameter) => {
  context.beginPath();
  context.arc(point.x, point.y, diameter || pointDiameter, 0, 2 * PI, false);
  context.fill();
};

const drawSegment = (context, p1, p2) => {
  context.beginPath();
  context.moveTo(p1.x, p1.y);
  context.lineTo(p2.x, p2.y);
  context.stroke();
};

// Draw the infinite line that intersects two points using y = mx + b
const drawLine = (context, p1, p2, size) => {
  let edgeP1;
  let edgeP2;
  const m = calculateSlope(p1, p2);
  if (Number.isFinite(m)) {
    // Non-vertical line
    const b = p1.y - (m * p1.x);
    edgeP1 = { x: 0, y: b };
    edgeP2 = { x: size.x, y: m * size.x + b };
  } else {
    // Vertical line
    edgeP1 = { x: p1.x, y: 0 };
    edgeP2 = { x: p1.x, y: size.y };
  }
  drawSegment(context, edgeP1, edgeP2);
};

const drawInArrow = (context, p1, p2) => {
  context.save();

  // Position canvas in arrow base and direction
  context.translate((p1.x + p2.x) / 2, (p1.y + p2.y) / 2);
  context.rotate(atan2(p2.y - p1.y, p2.x - p1.x) + PI / 2);

  // Arrow stem
  const s1 = { x: arrowToLineSpace, y: 0 };
  const s2 = { x: arrowToLineSpace + arrowStemLength + 0.5, y: 0 };
  context.beginPath();
  context.moveTo(s1.x, s1.y);
  context.lineTo(s2.x, s2.y);
  context.stroke();

  // Arrow triangle
  const t1 = { x: arrowToLineSpace + arrowStemLength, y: arrowTriangleSide / 2 };
  const t2 = { x: arrowToLineSpace + arrowStemLength, y: -arrowTriangleSide / 2 };
  const t3 = { x: arrowToLineSpace + arrowStemLength + arrowTriangleSide * sqrt(3 / 4), y: 0 };
  context.beginPath();
  context.moveTo(t1.x, t1.y);
  context.lineTo(t2.x, t2.y);
  context.lineTo(t3.x, t3.y);
  context.closePath();
  context.fill();

  context.restore();
};

const drawInArrowShadow = (context, p1, p2) => {
  context.save();

  // Position canvas in arrow base and direction
  context.translate((p1.x + p2.x) / 2, (p1.y + p2.y) / 2);
  context.rotate(atan2(p2.y - p1.y, p2.x - p1.x) + PI / 2);

  // Arrow stem
  const s1 = { x: 0, y: 0 };
  const s2 = { x: arrowToLineSpace + arrowStemLength + 0.5, y: 0 };
  context.beginPath();
  context.moveTo(s1.x, s1.y);
  context.lineTo(s2.x, s2.y);
  context.stroke();

  // Arrow triangle
  const center = {
    x: arrowToLineSpace + arrowStemLength + arrowTriangleSide * sqrt(3 / 4) * (0.35), y: 0,
  };
  drawPoint(context, center, arrowTriangleSide * (3 / 4));

  context.restore();
};

const next = (index, points) => (index < points.length - 1 ? index + 1 : 0);
const previous = (index, points) => (index > 0 ? index - 1 : points.length - 1);

const maskOutsidePolygon = (context, canvasSize, points) => {
  const backupFillStyle = context.fillStyle;
  const backupGlobalAlpha = context.globalAlpha;
  context.fillStyle = 'black';
  context.globalAlpha = 0.5;

  const heights = points.map((p) => p.y);
  const minHeight = min(...heights);
  const potentialMinPoint = heights.findIndex((p) => p === minHeight);
  const minPoint = potentialMinPoint;
  const maxHeight = max(...heights);
  const potentialMaxPoint = heights.findIndex((p) => p === maxHeight);
  const maxPoint = potentialMaxPoint;

  // Right mask
  context.beginPath();
  context.moveTo(points[maxPoint].x, canvasSize.y + 20);
  context.lineTo(canvasSize.x + 20, canvasSize.y + 20);
  context.lineTo(canvasSize.x + 20, -20);
  context.lineTo(points[minPoint].x, -20);
  context.lineTo(points[minPoint].x, points[minPoint].y);
  let linePoint = next(minPoint, points);
  while (linePoint !== maxPoint) {
    context.lineTo(points[linePoint].x, points[linePoint].y);
    linePoint = next(linePoint, points);
  }
  context.lineTo(points[maxPoint].x, points[maxPoint].y);
  context.closePath();
  context.fill();

  // Left mask
  context.beginPath();
  context.moveTo(points[maxPoint].x, canvasSize.y + 20);
  context.lineTo(-20, canvasSize.y + 20);
  context.lineTo(-20, -20);
  context.lineTo(points[minPoint].x, -20);
  context.lineTo(points[minPoint].x, points[minPoint].y);
  linePoint = previous(minPoint, points);
  while (linePoint !== maxPoint) {
    context.lineTo(points[linePoint].x, points[linePoint].y);
    linePoint = previous(linePoint, points);
  }
  context.lineTo(points[maxPoint].x, points[maxPoint].y);
  context.closePath();
  context.fill();

  context.fillStyle = backupFillStyle;
  context.globalAlpha = backupGlobalAlpha;
};

const getMousePosition = (canvas, event) => {
  const rect = canvas.getBoundingClientRect();
  const res = {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
  return res;
};

const closeToPoint = (p1, p2) => hypot(p1.x - p2.x, p1.y - p2.y) < pointClickDiameter;
const findClosePoint = (p, points) => points.findIndex((point) => closeToPoint(p, point));

const distanceToLine = (p1, p2, x) => (
  abs((p2.x - p1.x) * (p1.y - x.y) - (p1.x - x.x) * (p2.y - p1.y)) / hypot(p1.x - p2.x, p1.y - p2.y)
);
const closeToLine = (p1, p2, x) => {
  if (x.x < min(p1.x, p2.x) - lineClickDistance
   || x.y < min(p1.y, p2.y) - lineClickDistance
   || x.x > max(p1.x, p2.x) + lineClickDistance
   || x.y > max(p1.y, p2.y) + lineClickDistance) return false;
  return distanceToLine(p1, p2, x) < lineClickDistance;
};
const findCloseLine = (p, points) => {
  for (let line = 0; line < floor(points.length / 2); line += 1) {
    const isClose = closeToLine(points[2 * line], points[(2 * line) + 1], p);
    if (isClose) return line;
  }
  return -1;
};

const offset = (p1, p2) => ({ x: p1.x - p2.x, y: p1.y - p2.y });

const addPointsInPlace = (p1, p2) => {
  // eslint-disable-next-line no-param-reassign
  p1.x += p2.x;
  // eslint-disable-next-line no-param-reassign
  p1.y += p2.y;
};

const clampPointToSizeInPlace = (p, size) => {
  // eslint-disable-next-line no-param-reassign
  p.x = min(size.x + canvasMargin, max(canvasMargin, p.x));
  // eslint-disable-next-line no-param-reassign
  p.y = min(size.y + canvasMargin, max(canvasMargin, p.y));
};

const clampPointToSize = (p, size) => ({
  x: min(size.x + canvasMargin, max(canvasMargin, p.x)),
  y: min(size.y + canvasMargin, max(canvasMargin, p.y)),
});

const checkClamp = (p, size) => p.x < canvasMargin
                             || p.y < canvasMargin
                             || p.x > size.x + canvasMargin
                             || p.y > size.y + canvasMargin;

export {
  CANVAS_LINES,
  lineWidth,
  selectedLineWidth,
  selectedPointDiameter,
  canvasMargin,
  drawPoint,
  drawSegment,
  drawLine,
  drawInArrow,
  drawInArrowShadow,
  maskOutsidePolygon,
  getMousePosition,
  findClosePoint,
  findCloseLine,
  offset,
  addPointsInPlace,
  clampPointToSizeInPlace,
  clampPointToSize,
  checkClamp,
};
