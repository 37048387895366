import React from 'react';
import PropTypes from 'prop-types';
import { SessionController } from 'networking/controllers/session-controller';
import { routeNaming, goToPage, AppLink } from 'routes';
import { errorMessage } from 'helpers/error-helper';

import lanthornLogo from 'assets/images/lanthornLogo.svg';
import lanthornLogoSmall from 'assets/images/lanthornLogoSmall.svg';
import profileIcon from 'assets/images/profile.svg';
import logoutImg from 'assets/images/logout.svg';
import setupIcon from 'assets/images/icons-settings-gear.svg';

import styles from './navBar.module.scss';

const NavBar = ({ demoMode }) => {
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const logout = async () => {
    const { errorCode } = await SessionController.expire();
    if (!errorCode) return goToPage(routeNaming.LOGIN);
    setError(errorCode);
    return setMessage(errorMessage(errorCode));
  };

  const backToLogin = () => goToPage(routeNaming.LOGIN);

  const errorDiv = error ? (<p className={styles.errorMessage}>{message}</p>) : null;

  const profileButton = (
    <AppLink className={styles.profileButton} routeName={routeNaming.PROFILE}>
      <img src={profileIcon} alt="profile" className={styles.rightItemsImg} />
    </AppLink>
  );

  const logoutButton = (
    <button type="button" className={styles.logout} onClick={logout}>
      <img src={logoutImg} alt="logout" className={styles.rightItemsImg} />
    </button>
  );

  const backButton = (
    <button type="button" className={styles.logout} onClick={backToLogin}>
      <img src={logoutImg} alt="logout" className={styles.rightItemsImg} />
    </button>
  );

  const setupButton = (
    <AppLink className={styles.profileButton} routeName={routeNaming.SETUP}>
      <img src={setupIcon} alt="setup" className={styles.rightItemsImg} />
    </AppLink>
  );

  return (
    <>
      <div className={styles.navBar}>
        <AppLink routeName={routeNaming.DASHBOARD} className={styles.logoSection}>
          <img src={lanthornLogo} alt="" className={styles.logoImage} />
          <img src={lanthornLogoSmall} alt="" className={styles.smallLogoImage} />
        </AppLink>
        <div className={styles.rightItems}>
          {errorDiv}
          {demoMode ? null : setupButton}
          {demoMode ? null : profileButton}
          {demoMode ? backButton : logoutButton}
        </div>
      </div>
      {demoMode ? <div className={styles.demoWarning}>DEMO&nbsp;MODE</div> : null}
    </>
  );
};

NavBar.propTypes = {
  demoMode: PropTypes.bool.isRequired,
};

export { NavBar };
