import React from 'react';
import PropTypes from 'prop-types';

import styles from './stepButton.module.scss';

const StepButton = (props) => {
  const {
    type, text, className, icon, status, disabled, ...native
  } = props;
  return (
    <button
      className={[styles.button, className].join(' ')}
      // eslint-disable-next-line react/button-has-type
      type={type}
      status={status}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...native}
    >
      {icon.length > 0
      && (<img className={styles.icon} alt="" src={icon} />)}
      {text}
    </button>
  );
};

StepButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['submit', 'button', 'reset']).isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
};

StepButton.defaultProps = {
  className: '',
  icon: '',
  status: '',
  disabled: false,
};

export { StepButton };
