const greaterThan = (time1, time2) => (
  time1.h > time2.h || (time1.h === time2.h && time1.m > time2.m)
);

const isUsefulRule = (rule) => rule.days.reduce((acc, next) => acc || next, false);

const isZeroZero = (time) => time.h === 0 && time.m === 0;

const isValidTime = (time) => Number.isInteger(time.h) && time.h >= 0 && time.h < 24
                           && Number.isInteger(time.m) && time.h >= 0 && time.h < 60;

const isValidRule = (rule) => (
  rule.occupancy >= 0
    && isValidTime(rule.from)
    && isValidTime(rule.to)
    && (isZeroZero(rule.to) || greaterThan(rule.to, rule.from))
);

const overlapsWith = (rule1, rule2) => {
  if (
    !rule1.days.reduce((acc, _, i) => acc || (rule1.days[i] && rule2.days[i]), false)
  ) {
    // Rules apply to different days
    return false;
  }
  if (isZeroZero(rule1.to)) return isZeroZero(rule2.to) || greaterThan(rule2.to, rule1.from);
  if (isZeroZero(rule2.to)) return isZeroZero(rule1.to) || greaterThan(rule1.to, rule2.from);

  return greaterThan(rule2.to, rule1.from) || greaterThan(rule1.to, rule2.from);
};

const OCCUPANCY_RULE_ERRORS = {
  USELESS: 'useless',
  INVALID: 'invalid',
  CONFLICTING: 'conflicting',
};

const checkRuleGroupForErrors = (rules) => {
  const errors = rules.map(() => null);
  let error = null;

  // Check for rules with no days selected
  rules.forEach((rule, i) => {
    if (!isUsefulRule(rule)) {
      error = true;
      errors[i] = OCCUPANCY_RULE_ERRORS.USELESS;
    }
  });

  // Check for rules with invalid timeranges selected
  rules.forEach((rule, i) => {
    if (!errors[i] && !isValidRule(rule)) {
      error = true;
      errors[i] = OCCUPANCY_RULE_ERRORS.INVALID;
    }
  });

  // Check for conflicts between rules
  rules.forEach((rule1, i1) => {
    if (errors[i1]) return;
    rules.slice(i1 + 1).forEach((rule2, i2) => {
      if (errors[i1 + 1 + i2]) return;
      if (overlapsWith(rule1, rule2)) {
        error = true;
        errors[i1] = OCCUPANCY_RULE_ERRORS.CONFLICTING;
        errors[i1 + 1 + i2] = OCCUPANCY_RULE_ERRORS.CONFLICTING;
      }
    });
  });

  return error && errors;
};

class OccupancyRule {
  constructor() {
    this.occupancy = 0;
    this.from = { h: 0, m: 0 };
    this.to = { h: 0, m: 0 };
    this.days = [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ];
    this.error = null;
  }
}

export {
  OccupancyRule,
  isUsefulRule, isValidRule, overlapsWith,
  OCCUPANCY_RULE_ERRORS, checkRuleGroupForErrors,
};
