import React from 'react';
import { AppRedirect } from 'routes/app-redirect';
import { routeNaming } from 'routes/routes';
import { NotFound } from 'pages/unauthorized/notFound';
import { Login } from 'pages/unauthorized/login';
import { PasswordRecovery } from 'pages/unauthorized/passwordRecovery';
import { ForgotPassword } from 'pages/unauthorized/forgotPassword';
import { PasswordRecoverySent } from 'pages/unauthorized/passwordRecoverySent';
import { Register } from 'pages/unauthorized/register';
import { RegisterEmailSent } from 'pages/unauthorized/registerEmailSent';
import { ActivateAccount } from 'pages/unauthorized/activateAccount';
import { EmailActivated } from 'pages/unauthorized/emailActivated';
import { Setup } from 'pages/setup';
import { SlackSetup } from 'pages/slackSetup';
import { Profile } from 'pages/profile';
import { Home, DemoHome } from 'pages/home';

const routeConfig = [
  {
    name: routeNaming.ROOT,
    component: () => (<AppRedirect routeName={routeNaming.DASHBOARD} />),
  },
  {
    name: routeNaming.LOGIN,
    component: Login,
  },
  {
    name: routeNaming.REGISTER,
    component: Register,
  },
  {
    name: routeNaming.REGISTER_EMAIL_SENT,
    component: RegisterEmailSent,
  },
  {
    name: routeNaming.ACTIVATE_ACCOUNT,
    component: ActivateAccount,
  },
  {
    name: routeNaming.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    name: routeNaming.PASSWORD_RECOVERY_SENT,
    component: PasswordRecoverySent,
  },
  {
    name: routeNaming.SETUP,
    component: Setup,
  },
  {
    name: routeNaming.SLACK_SETUP,
    component: SlackSetup,
  },
  {
    name: routeNaming.PASSWORD_RECOVERY,
    component: PasswordRecovery,
  },
  {
    name: routeNaming.EDIT_CAMERA,
    component: Setup,
  },
  {
    name: routeNaming.EDIT_CAMERAS,
    component: Setup,
  },
  {
    name: routeNaming.EDIT_AREA,
    component: Setup,
  },
  {
    name: routeNaming.EDIT_AREAS,
    component: Setup,
  },
  {
    name: routeNaming.DASHBOARD,
    component: Home,
  },
  {
    name: routeNaming.EMAIL_ACTIVATION,
    component: EmailActivated,
  },
  {
    name: routeNaming.PROFILE,
    component: Profile,
  },
  {
    name: routeNaming.DEMO_HOME,
    component: DemoHome,
  },
  {
    name: routeNaming.CATCH_ALL,
    component: NotFound,
  },
];

export { routeConfig };
