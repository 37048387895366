import { constants } from 'config/constants';

const { METRICS } = constants;

class ProcessorInfo {
  constructor(params) {
    this.version = params.version;
    this.device = params.device;
    this.has_been_configured = params.hasBeenConfigured;
    // TODO: Deserialize metrics in processor response
    this.metrics = {
      [METRICS.OCCUPANCY]: params.metrics[METRICS.OCCUPANCY],
      [METRICS.IN_OUT]: params.metrics[METRICS.IN_OUT],
      [METRICS.FACEMASK]: params.metrics[METRICS.FACEMASK],
      [METRICS.SOCIAL_DST]: params.metrics[METRICS.SOCIAL_DST],
    };
  }
}

export { ProcessorInfo };
