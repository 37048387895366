import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { ReportsController } from 'networking/controllers/reports-controller';
import {
  historicGraphType, historicGraphOptions, historicGraphData,
} from 'helpers/historic-graph-helper';

import { Chart } from 'common/chart';
import { inOrange, outBlue, balanceGrey } from 'assets/stylesheets/colors.scss';

import { MetricCard } from '../metricCard';

import styles from './inOutCard.module.scss';

const getGraphItems = (areas, live) => ([
  areas && live && {
    label: 'Occupancy', dataName: 'latestOccupancy', backgroundColor: balanceGrey,
  },
  areas && !live && {
    label: 'Max Occupancy', dataName: 'maxOccupancy', backgroundColor: 'black',
  },
  areas && !live && {
    label: 'Average Occupancy', dataName: 'avgOccupancy', backgroundColor: balanceGrey,
  },
  { label: 'In', dataName: 'in', backgroundColor: inOrange },
  { label: 'Out', dataName: 'out', backgroundColor: outBlue },
]).filter(Boolean);

const getCustomHistoricGraphOptions = (graphItems) => {
  const res = historicGraphOptions();
  res.scales.yAxes[0].stacked = false;
  res.scales.yAxes[0].ticks.callback = (t) => (Math.abs(t));
  res.scales.yAxes[0].gridLines = {
    lineWidth: 0,
    zeroLineWidth: 1,
  };
  res.tooltips = {
    callbacks: {
      label: (item) => {
        const index = item.datasetIndex;
        const value = index !== graphItems.length - 1 ? Math.abs(item.yLabel) : item.yLabel;
        return `${graphItems[index].label}: ${value}`;
      },
    },
  };
  return res;
};

const InOutCard = ({ timeframe, showAreas, ids }) => {
  const [liveData, setLiveData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [historicData, setHistoricData] = useState({});
  const [processedHistoricData, setProcessedHistoricData] = useState({});
  const [historicChartType, setHistoricChartType] = useState('bar');
  const [graphItems, setGraphItems] = useState([]);
  const [liveGraphItems, setLiveGraphItems] = useState([]);
  const [customHistoricGraphOptions, setCustomHistoricGraphOptions] = useState({});
  const [loadingLive, setLoadingLive] = useState(true);
  const [loadingHistoric, setLoadingHistoric] = useState(true);

  useEffect(() => {
    const newGraphItems = getGraphItems(showAreas, false, timeframe);
    const newLiveGraphItems = getGraphItems(showAreas, true, timeframe);
    setGraphItems(newGraphItems);
    setLiveGraphItems(newLiveGraphItems);
    setCustomHistoricGraphOptions(getCustomHistoricGraphOptions(newGraphItems));
  }, [showAreas, timeframe]);

  useEffect(() => {
    const updateLiveData = async () => {
      const {
        lastUpdated: recievedLastUpdated, data, errorCode,
      } = await ReportsController.getLiveInOutStats(showAreas, ids);
      if (errorCode) return null;
      setLastUpdated(recievedLastUpdated);
      setLiveData(data);
      return setLoadingHistoric(false);
    };

    const updateHistoricData = async () => {
      const { data, errorCode } = await ReportsController.getHistoricInOutData(
        showAreas, ids, timeframe,
      );
      if (errorCode) return null;
      setHistoricChartType(historicGraphType(timeframe));
      setHistoricData(data);
      return setLoadingLive(false);
    };

    setLoadingHistoric(true);
    setLoadingLive(true);

    updateLiveData();
    updateHistoricData();
  }, [timeframe, ids, showAreas]);

  useEffect(() => {
    const data = historicGraphData(
      timeframe,
      graphItems.map((item) => item.label),
      graphItems.map((i) => historicData[i.dataName]),
      graphItems.map((item) => item.backgroundColor),
      graphItems.map((item) => `${item.backgroundColor}50`),
    );

    data.datasets = data.datasets.map((dataset, i) => ({
      ...dataset,
      type: i > 1 ? 'bar' : 'line',
      fill: i > 1 ? 'origin' : 'none',
      pointBackgroundColor: i > 1 ? '#FFFFFF00' : dataset.borderColor,
      pointBorderColor: i > 1 ? '#FFFFFF00' : dataset.borderColor,
    }));

    setProcessedHistoricData(data);
  }, [graphItems, historicData, timeframe]);

  const historicGraph = (
    <Chart
      type={historicChartType}
      data={processedHistoricData}
      options={customHistoricGraphOptions}
    />
  );

  return (
    <MetricCard
      title="In/Out"
      tooltip="In/Out detection is displayed in two ways. Real-time and historical.
      The real-time display shows the number of people that came in and went out through the designated boundaries in the last hour, as well as the balance of people who crossed them.
      The historical graph displays these metrics to show changes in occupancy through the entrance in the selected area or camera over time."
      graph={!loadingHistoric && historicGraph}
      lastUpdated={loadingLive ? '' : lastUpdated}
    >
      {
        !loadingLive && (
          <div className={styles.liveData}>
            {liveData.latestOccupancy !== undefined && liveGraphItems.find((item) => item.dataName === 'latestOccupancy') && (
              <div className={styles.featuredMetric}>
                Current Occupancy:
                <div className={styles.featuredMetricNumber}>
                  {liveData[liveGraphItems.find((item) => item.dataName === 'latestOccupancy').dataName]}
                </div>
              </div>
            )}
            {liveData.latestOccupancy !== undefined ? (
              <div className={styles.numbers}>
                <div className={styles.detections}>
                  <div>Detections:</div>
                  <span>{liveData.detections}</span>
                </div>
                <div className={styles.infringements}>
                  {
                    liveGraphItems.map((item, i) => (
                      <div className={styles.row} key={item.label}>
                        <div className={styles.indicator}>
                          <div
                            style={{
                              backgroundColor: liveGraphItems[i].backgroundColor,
                              borderColor: liveGraphItems[i].backgroundColor,
                            }}
                          />
                          {item.label}
                        </div>
                        <span>{liveData[item.dataName]}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            ) : (<p>No real time data</p>)}
          </div>
        )
      }
    </MetricCard>
  );
};

InOutCard.propTypes = {
  timeframe: PropTypes.object.isRequired,
  showAreas: PropTypes.bool.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { InOutCard };
