const ERRORS = {
  LOCAL: {
    NO_CONFIG: 2001,
    ENCRYPTION: 3001,
    DECRYPTION: 3002,
    INVALID_URL: 8005,
    BAD_DATA: 5001,
    UNEXPECTED: 9001,
  },
  API: {
    CANT_REACH: 1001,
    NO_CONFIG: 2002,
    UNEXPECTED: 9002,
    UNAUTHORIZED: 4001,
    UNREGISTERED: 8001,
    UNACTIVATED: 8002,
    PASSWORD: 8003,
    REGISTERED: 8004,
  },
  PROCESSOR: {
    CANT_REACH: 1002,
    NO_URL: 1003,
    NO_TOKEN: 1004,
    NO_CONFIG: 2003,
    UNAUTHORIZED: 8006,
    BAD_REQUEST: 8007,
    CUSTOM_MESSAGE: 2004,
    UNEXPECTED: 9003,
  },
  OTHER: {
    COUNTRY_API_ERROR: 9004,
  },
};
let customMessage;

const setCustomMessage = (message) => {
  customMessage = message;
};

const errorMessages = {
  1001: 'Can\'t reach Lanthorn\'s servers.',
  1002: 'Can\'t reach your local processor.',
  1003: 'Missing processor URL.',
  1004: 'Missing processor token.',
  2001: 'Your configuration is not currently stored in your browser.',
  2002: 'You haven\'t set up your account yet.',
  2003: 'This configuration item was not found in your processor.',
  2004: customMessage,
  3001: 'Something went wrong while encrypting your configuration.',
  3002: 'Something went wrong while decrypting your configuration.',
  4001: 'Authorization error.',
  5001: 'We couldn\'t decode your data.',
  8001: 'Unregistered email',
  8002: 'Unactivated user.',
  8003: 'Incorrect Password.',
  8004: 'This email is already assigned to a user.',
  8005: 'Invalid URL.',
  8006: 'Wrong processor credentials.',
  8007: 'Wrong request format.',
  9001: 'Something went wrong with the page.',
  9002: 'Something went wrong with our servers.',
  9003: 'Something went wrong with your processor.',
  9004: 'We couldn\'t load the countries list.',
};

const errorMessage = (code) => errorMessages[code] || 'Unknown error.';

export { ERRORS, errorMessage, setCustomMessage };
