import axios from 'axios';
import { loadProcessorURL, loadProcessorToken } from 'helpers/cookie-helper';
import { errorMessage, ERRORS } from 'helpers/error-helper';

import { ApiError } from 'models/api-error';
import { UNAUTHORIZED_ROUTES } from 'networking/processor-routes';
import { ApiErrorSerializer } from 'networking/serializers/api-error-serializer';

const METHODS = {
  delete: 'delete',
  get: 'get',
  patch: 'patch',
  post: 'post',
  put: 'put',
};

class ProcessorServiceClass {
  constructor() {
    this._axios = axios.create({ baseURL: loadProcessorURL() });
    this._axios.defaults.timeout = 5000;
    this._addedHeaders = {};
    this._token = loadProcessorToken();
  }

  async _sendRequest(method, url, config = {}) {
    if (!this._axios.defaults.baseURL) {
      throw new ApiError({
        status: null,
        code: ERRORS.PROCESSOR.NO_URL,
        message: errorMessage(ERRORS.PROCESSOR.NO_URL),
      });
    }
    if (!UNAUTHORIZED_ROUTES.includes(url) && typeof this._token !== 'string') {
      throw new ApiError({
        status: null,
        code: ERRORS.PROCESSOR.NO_TOKEN,
        message: errorMessage(ERRORS.PROCESSOR.NO_TOKEN),
      });
    }
    try {
      const updatedConfig = { ...config };
      updatedConfig.headers = {
        ...this._addedHeaders,
        ...(config.headers || {}),
        ...(
          UNAUTHORIZED_ROUTES.includes(url) && this._token
            ? {} : { Authorization: `Bearer ${this._token}` }
        ),
      };
      if (method === METHODS.get || method === METHODS.delete) {
        return await this._axios[method](url, updatedConfig);
      }
      const body = updatedConfig.body || {};
      delete updatedConfig.body;
      return await this._axios[method](url, body, updatedConfig);
    } catch (error) {
      if (error.response && error.response.data) {
        throw new ApiError({
          ...ApiErrorSerializer.deSerialize(error.response.data),
          status: error.response.status,
        });
      }
      throw new ApiError({
        status: null,
        code: null,
        message: error.message,
      });
    }
  }

  setHeaders(newHeaders) {
    Object.assign(this._addedHeaders, newHeaders);
  }

  updateBaseURL() {
    this._axios = axios.create({ baseURL: loadProcessorURL() });
  }

  updateToken() {
    this._token = loadProcessorToken();
  }

  get(url, params = {}, config = {}) {
    return this._sendRequest(METHODS.get, url, { ...config, params });
  }

  post(url, body = {}, config = {}) {
    return this._sendRequest(METHODS.post, url, { ...config, body });
  }

  patch(url, body = {}, config = {}) {
    return this._sendRequest(METHODS.patch, url, { ...config, body });
  }

  put(url, body = {}, config = {}) {
    return this._sendRequest(METHODS.put, url, { ...config, body });
  }

  delete(url, params = {}, config = {}) {
    return this._sendRequest(METHODS.delete, url, { ...config, params });
  }
}

const ProcessorService = new ProcessorServiceClass();

export { ProcessorService };
