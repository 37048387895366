class CameraAreaSerializer {
  static serialize(points) {
    return { contour_roi: points.map((p) => [p.x, p.y]) };
  }

  static deSerialize(points) {
    return points.map((p) => ({ x: p[0], y: p[1] }));
  }
}

export { CameraAreaSerializer };
