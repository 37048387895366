/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';
import { ConfigProvider } from './configProvider';
import { DemoConfigProvider } from './demoConfigProvider';

// Only load the processor URL and token to cookies
const withCloudConfig = (Component) => (props) => (
  <ConfigProvider fullLoad={false} render={(config) => <Component {...props} config={config} />} />
);

// Same as before but load cameras and areas to pass as props
const withProcessorConfig = (Component) => (props) => (
  <ConfigProvider fullLoad render={(config) => <Component {...props} config={config} />} />
);

// Mimics withProcessorConfig, but loads processor URL from environment variables
// and doesn't use authorization
const withDemoConfig = (Component) => (props) => (
  <DemoConfigProvider render={(config) => <Component {...props} config={config} />} />
);

const withProcessorConfigPropTypes = {
  config: PropTypes.shape({
    processorInfo: PropTypes.object.isRequired,
    cameras: PropTypes.arrayOf(PropTypes.object).isRequired,
    areas: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export {
  withCloudConfig, withProcessorConfig, withDemoConfig, withProcessorConfigPropTypes,
};
