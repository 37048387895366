import React, { useState } from 'react';

import { withAuthentication } from 'hocs/with-authentication';
import { withProcessorConfig, withDemoConfig, withProcessorConfigPropTypes } from 'hocs/with-config';
import { LAYOUT_TYPES, withLayout } from 'hocs/with-layout';

import { Footer } from 'common/footer';
import { Accordion } from 'common/accordion';
import { ExportDataModal } from 'common/exportDataModal';

import areaIconSelected from 'assets/images/setup-icons/icons-areas-enabled.png';
import areaIconUnselected from 'assets/images/setup-icons/icons-areas-disabled.png';
import cameraIconSelected from 'assets/images/setup-icons/icons-cameras-enabled.png';
import cameraIconUnselected from 'assets/images/setup-icons/icons-cameras-disabled.png';
import reportsIcon from 'assets/images/icons-reports.svg';

import { Metrics } from './metrics';
import { AreaCard, CameraCard } from './sidebar';

import styles from './home.module.scss';

const Home = ({ config: { processorInfo, cameras, areas } }) => {
  const [showAreas, setShowAreas] = useState(true);
  const [selectedAreas, setSelectedAreas] = useState([areas[0].id]);
  const [selectedCameras, setSelectedCameras] = useState([cameras[0].id]);
  const [download, setDownload] = useState(false);

  const handleCheckbox = (setArray) => (id) => () => setArray([id]);

  const setShowCameras = () => setShowAreas(false);

  const areaCards = showAreas && areas.map((area) => (
    <AreaCard
      key={`area-${area.id}`}
      area={area}
      cameras={cameras}
      selected={selectedAreas.includes(area.id)}
      onClick={handleCheckbox(setSelectedAreas)}
      selectCamera={setSelectedCameras}
      setShowAreas={setShowAreas}
    />
  ));

  const cameraCards = !showAreas && cameras.map((camera) => (
    <CameraCard
      key={`camera-${camera.id}`}
      camera={camera}
      selected={selectedCameras.includes(camera.id)}
      onClick={handleCheckbox(setSelectedCameras)}
    />
  ));

  const handleDownloadButton = () => setDownload(true);

  const sidebar = (
    <div className={styles.sidebar}>
      <Accordion
        title="Areas"
        selectedIcon={areaIconSelected}
        unselectedIcon={areaIconUnselected}
        showContent={showAreas}
        setShowContent={setShowAreas}
      >
        {areaCards}
      </Accordion>
      <Accordion
        title="Cameras"
        selectedIcon={cameraIconSelected}
        unselectedIcon={cameraIconUnselected}
        showContent={!showAreas}
        setShowContent={setShowCameras}
      >
        {cameraCards}
      </Accordion>
      <button type="button" className={styles.exportDataButton} onClick={handleDownloadButton}>
        <img src={reportsIcon} alt="" />
        Export RAW Data
      </button>
    </div>
  );

  const ids = showAreas ? selectedAreas : selectedCameras;

  return (
    <>
      {
        download && (
          <ExportDataModal
            dismiss={() => setDownload(false)}
            areas={areas}
            cameras={cameras}
            processorMetrics={processorInfo.metrics}
          />
        )
      }
      <div className={styles.content}>
        {sidebar}
        {ids.length > 0 ? (
          <Metrics
            showAreas={showAreas}
            ids={showAreas ? selectedAreas : selectedCameras}
            processorMetrics={processorInfo.metrics}
            cameras={cameras}
            areas={areas}
          />
        ) : (
          <span className={styles.noIds}>
            Select a
            {showAreas ? 'n area ' : ' camera '}
            to show metrics.
          </span>
        )}

      </div>
      <Footer />
    </>
  );
};

Home.propTypes = { ...withProcessorConfigPropTypes };

const WrappedHome = withAuthentication(withLayout(
  LAYOUT_TYPES.MAIN, withProcessorConfig(Home),
));

const DemoHome = withLayout(
  LAYOUT_TYPES.DEMO, withDemoConfig(Home),
);

export { WrappedHome as Home, DemoHome };
