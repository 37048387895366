import { constants } from 'config/constants';

const { METRICS } = constants;
class ProcessorInfoSerializer {
  static deSerialize(processorInfo) {
    return {
      version: processorInfo.version,
      device: processorInfo.device,
      hasBeenConfigured: processorInfo.has_been_configured,
      metrics: processorInfo.metrics
        ? {
          [METRICS.OCCUPANCY]: processorInfo.metrics.occupancy,
          [METRICS.IN_OUT]: processorInfo.metrics.in_out,
          [METRICS.FACEMASK]: processorInfo.metrics.facemask,
          [METRICS.SOCIAL_DST]: processorInfo.metrics.social_distancing,
        } : {
          [METRICS.OCCUPANCY]: true,
          [METRICS.IN_OUT]: true,
          [METRICS.FACEMASK]: true,
          [METRICS.SOCIAL_DST]: true,
        },
    };
  }

  static serialize(version, device, hasBeenConfigured) {
    return {
      version,
      device,
      has_been_configured: hasBeenConfigured,
    };
  }
}

export { ProcessorInfoSerializer };
