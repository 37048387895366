import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import {
  storeToken, loadToken, deleteToken,
  storeRefreshToken, loadRefreshToken, deleteRefreshToken,
  deleteProcessorURL,
  deleteProcessorToken,
} from 'helpers/cookie-helper';
import { logger } from 'helpers/logger';
import { ERRORS } from 'helpers/error-helper';

class SessionController {
  static async login(email, password) {
    try {
      const response = await ApiService.post(API_ROUTES.SESSIONS,
        `grant_type=password&email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`);
      storeToken(response.data.access_token);
      storeRefreshToken(response.data.refresh_token);
    } catch (error) {
      return this.handleAPIError(error, (err) => {
        if (err.status === 400) return { errorCode: ERRORS.API.UNACTIVATED };
        if (err.status === 401) return { errorCode: ERRORS.API.UNAUTHORIZED };
        if (err.status === 404) return { errorCode: ERRORS.API.UNREGISTERED };
        return {};
      });
    }
    return { errorCode: false };
  }

  static async forgotPassword(email) {
    try {
      await ApiService.get(API_ROUTES.PASSWORDS, { email });
      return { errorCode: false, message: 'Forgot password email sent.' };
    } catch (error) {
      return this.handleAPIError(error);
    }
  }

  static async passwordRecovery(password, token) {
    try {
      await ApiService.post(
        API_ROUTES.PASSWORDS, { password, reset_password_token: token },
      );
      return { errorCode: false };
    } catch (error) {
      return this.handleAPIError(error);
    }
  }

  static async expire() {
    deleteToken();
    deleteRefreshToken();
    deleteProcessorURL();
    deleteProcessorToken();
    window.localStorage.clear();
    try {
      await ApiService.delete(API_ROUTES.SESSIONS, {},
        { headers: { Authorization: `Bearer ${loadToken()}` } });
    } catch (error) {
      logger.error(error);
      if (!error.status) return { errorCode: ERRORS.API.CANT_REACH };
      if (error.status !== 401) return { errorCode: ERRORS.API.UNEXPECTED };
    }
    return { errorCode: false };
  }

  static async auth() {
    try {
      await ApiService.get(API_ROUTES.SESSIONS, {},
        { headers: { Authorization: `Bearer ${loadToken()}` } });
      return { errorCode: false };
    } catch (error) {
      return this.handleAPIError(error);
    }
  }

  static async sendRefreshToken() {
    try {
      const refreshToken = loadRefreshToken();
      const response = await ApiService.put(
        API_ROUTES.SESSIONS,
        { refresh_token: refreshToken },
      );
      storeToken(response.data.access_token);
      return { errorCode: false };
    } catch (error) {
      return this.handleAPIError(error, (err) => (
        err.status === 401 ? { errorCode: ERRORS.API.UNAUTHORIZED } : {}
      ));
    }
  }

  static async handleAPIError(error, customErrorHandler = () => ({})) {
    logger.error(error);
    if (error instanceof TypeError) return { errorCode: ERRORS.LOCAL.BAD_DATA };
    if (!error.status) return { errorCode: ERRORS.API.CANT_REACH };
    const { errorCode: customError } = customErrorHandler(error);
    if (customError) return { errorCode: customError };
    if (error.status === 401) return this.sendRefreshToken();
    return { errorCode: ERRORS.API.UNEXPECTED };
  }
}

export { SessionController };
